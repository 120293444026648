import { Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import { amber, grey } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { FolderType } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StoryCard from "./StoryCard"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ px: "10rem" }} />,
}

export default function UserStoryGrid({ dataList, isLoading }) {
    const { currentUserStoryData, setCurrentUserStoryData, openEditStoryDialog, setOpenEditStoryDialog, userStoryList, setUserStoryList, selectedUserStoryList, setSelectedUserStoryList, globalLoader, setGlobalLoader, currentFolderData, isUserStoryLoading, setIsUserStoryLoading, openLinkTagDrawer, setOpenLinkTagDrawer } = useApplicationContext();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);

    let storiesList = [
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },

        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Create a new project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            name: "Upload contract",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 8,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    const handleOpenUserStory = (item) => {
        setCurrentUserStoryData(item);
        setOpenEditStoryDialog(true);
    }

    const handleCloseUserStory = () => {
        setOpenEditStoryDialog(false);
        setCurrentUserStoryData(null);
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUserStoryList(dataList);
        } else {
            setSelectedUserStoryList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        e.stopPropagation();

        if (e.target.checked) {
            setSelectedUserStoryList([...selectedUserStoryList, item]);
        } else {
            setSelectedUserStoryList(selectedUserStoryList?.filter((story) => story?.customId !== item?.customId));
        }
    }


    const handleOpenLinkTag = (data) => {
        setCurrentUserStoryData(data)
        setOpenLinkTagDrawer(true)
    }

    return (
        <Box className=" grow flex flex-col w-full flex-1">
            {
                false ?
                    <Box className="w-full h-full flex justify-center items-center">
                        <CircularProgress />
                    </Box>
                    :
                    <Box className="w-full h-fit flex  flex-wrap gap-6 pl-0  py-0 ">
                        {
                            dataList?.length > 0 && dataList?.map((storyData, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={` overflow-hidden rounded-lg border hover:shadow-2xl  backdrop-blur-md transition-all ease-in-out duration-300 h-36 
                                        
                                        min-w-[220px]   ${isMobile ? "w-full" : isTablet ? "w-[45%]" : isLaptop ? "w-[29.5%]" : isDesktop ? "w-[22%]" : "w-[23%]"} `}
                                        // sx={{ border: `2px solid transparent`, "&:hover": { border: `2px solid ${theme.palette.primary.main}` } }}

                                        bgcolor={theme.palette.card.background}
                                        // onClick={() => handleOpenProject(project)}
                                        sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}`, } }}
                                    >
                                        <StoryCard
                                            item={storyData}
                                            handleClick={() => handleOpenUserStory(storyData)}
                                            handleChangeCheckbox={handleChangeCheckbox}
                                            handleOpenLinkTag={handleOpenLinkTag}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Box>

            }


            {openUserStoryDrawer && currentUserStoryData && <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseUserStory} />}
        </Box>
    )
}



