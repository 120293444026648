import { AccountCircleOutlined, Add, AddOutlined, ArrowBack, ArrowBackOutlined, ArrowLeft, ArrowLeftOutlined, ArrowRightAlt, AutoAwesome, AutoAwesomeOutlined, AutoModeOutlined, Delete, DeleteOutline, Description, DownloadOutlined, Edit, EditOutlined, FilterAltOffOutlined, FilterAltOutlined, FolderOutlined, FolderSpecialOutlined, Home, HomeOutlined, KeyboardDoubleArrowLeft, MenuOpen, MoreHoriz, UploadOutlined } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chip, CircularProgress, Divider, Fab, Icon, IconButton, Skeleton, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { useApplicationContext } from '../../../context/ApplicationContext';
import { useEffect, useState } from 'react';
import { blue, grey, purple, red } from '@mui/material/colors';
import { font } from '../../../styles/font';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ChatWindow from '../ChatWindow';
import ProjectViewContainer from './RequirementMainContainer';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import Treeview from '../../common/Treeview';
import { LightTooltip } from '../../styled/CustomComponent';
import SidebarToggleButton from '../../common/SidebarToggleButton';
import ChatWindowToggleButton from '../../common/ChatWindowToggleButton';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import RequirementMainContainer from './RequirementMainContainer';
import CreateModuleDialog from '../../dialog/CreateModuleDialog';
import { HttpStatusCode } from 'axios';
import { FolderType, ModulePath, Severity } from '../../../constant/ApplicationVariables';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { addModule_API, deleteModule_API, updateModule_API } from '../../../api/controller/ModuleController';
import { getFolderStructureList_API } from '../../../api/controller/RequirementController';
import EditModuleDialog from '../../dialog/EditModuleDialog';
import DeleteModuleDialog from '../../dialog/DeleteModuleDialog';

export default function ActiveProjectContainer() {
    const {
        toggleChatWindow, setToggleChatWindow,
        toggleFolderSidebar, setToggleFolderSidebar,
        folderStructureList, setFolderStructureList,
        activeProject, setActiveProject,
        handleSnackbar,
        setGlobalLoader,
        currentFolderData, setCurrentFolderData,
        isUpdateCurrentFolderData, setIsUpdateCurrentFolderData,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        openCreateStoryDialog, setOpenCreateStoryDialog,
        openEditStoryDialog,
        openCreateUseCaseDialog, setOpenCreateUseCaseDialog,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        isGridViewEnabled, setIsGridViewEnabled,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;

    const [loading, setLoading] = useState(false);
    const [openCreateModuleDialog, setOpenCreateModuleDialog] = useState(false);
    const [openEditModuleDialog, setOpenEditModuleDialog] = useState(false);
    const [openDeleteModuleDialog, setOpenDeleteModuleDialog] = useState(false);

    console.log(folderStructureList, "folderStructureList")

    const folderList = [
        {
            id: "1",
            name: "Trunk2tale",
            type: "project",
            moduleCount: 2,
            children: [
                {
                    id: "m1",
                    name: "Settings",
                    type: "module",
                    userStoryCount: 5,

                },
                {
                    id: "m2",
                    name: "Profile",
                    type: "module",
                    userStoryCount: 5,

                },
            ],
        },
    ];

    const handleToggleSidebar = () => {
        setToggleFolderSidebar(!toggleFolderSidebar);
    }

    const handleOpenCreateDialog = () => {
        if (pathName === ModulePath.requirements) {
            setOpenCreateStoryDialog(true);
        }
        if (pathName === ModulePath.useCase) {
            setOpenCreateUseCaseDialog(true);
        }
    }

    const handleGetFolderStructureList = async (projectData) => {
        try {
            setLoading(true);
            let projectName = projectData?.name;
            let query = `name=${projectName}`

            const response = await getFolderStructureList_API(query);
            // setIsUpdateFolderStructure(false);
            console.log(response, "projectreponse handleGetFolderStructureList layout")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setIsUpdateFolderStructure(false);
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }

    const handleGetFolderStructureList1 = async (project) => {
        try {
            // setGlobalLoader(true);

            let query = `name=${project?.name}`

            const response = await getFolderStructureList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                // setFolderStructureList(response.data);
                setFolderStructureList([{ ...response.data }]);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, "projectreponse errorr")
        }
    }

    const handleCreateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                ...moduleData,
                "projectName": currentFolderData?.data?.name,
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.data);
                setIsUpdateFolderStructure(true);
                setOpenCreateModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "currentName": currentFolderData?.data?.name,
                "newName": moduleData?.name,
                "description": moduleData?.description
            }

            const response = await updateModule_API(body);
            console.log(response, "response handleUpdateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setOpenEditModuleDialog(false);
                setIsUpdateCurrentFolderData(true);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteModule = async () => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "name": currentFolderData?.data?.name,
            }

            const response = await deleteModule_API(body);
            console.log(response, "response handleDeleteModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setOpenDeleteModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    // useEffect(() => {
    //     if (activeProject && folderStructureList?.length === 0) {
    //         handleGetFolderStructureList(activeProject);
    //     }
    // }, [activeProject]);


    // useEffect(() => {
    //     if (isUpdateFolderStructure && activeProject) {
    //         handleGetFolderStructureList(activeProject);
    //     }
    // }, [isUpdateFolderStructure, activeProject]);


    return (
        <Box className=" flex gap-0 relative h-full w-full box-border  overflow-hidden ">

            {/* folder sidebar */}
            {false && <Box
                className={`flex flex-col h-full w-0 relative border-r border-gray-300 `}
                sx={{
                    minWidth: toggleFolderSidebar ? "20vw" : 0,
                    maxWidth: toggleFolderSidebar ? "20vw" : 0,
                    transition: "width 0.5s ease-in-out",
                }}
                bgcolor={theme.palette.primary.layout_col}
            >
                <Box className="w-full min-h-[8%] max-h-[8%]  flex items-center justify-between py-2 border-b sticky top-0 z-10 backdrop-blur-md">
                    {/* <IconButton >
                        <FolderOutlined />
                    </IconButton> */}

                    {toggleFolderSidebar && <Box className="w-full flex items-center justify-between px-4 overflow-x-auto">
                        <LightTooltip title="Create Module" >
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type !== FolderType.project}
                                selected={openCreateModuleDialog}
                                onChange={() => setOpenCreateModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <AddOutlined />
                            </ToggleButton>
                        </LightTooltip>

                        <LightTooltip title="Edit Module">
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type === FolderType.project}
                                selected={openEditModuleDialog}
                                onChange={() => setOpenEditModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <EditOutlined />
                            </ToggleButton>
                        </LightTooltip>

                        <LightTooltip title="Delete Module">
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type === FolderType.project}
                                selected={openDeleteModuleDialog}
                                onChange={() => setOpenDeleteModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <DeleteOutline />
                            </ToggleButton>
                        </LightTooltip>

                        {/* <LightTooltip title="Hide Sidebar">
                            <ToggleButton
                                value="check"
                                size="small"
                                color="secondary"
                                selected={toggleFolderSidebar}
                                onChange={handleToggleSidebar}
                            >
                                <MenuOpen />
                            </ToggleButton>
                        </LightTooltip> */}

                    </Box>
                    }
                    {/* <SidebarToggleButton /> */}
                </Box>

                <Box className=" flex w-full h-full pt-2 shadow-sm">
                    {
                        (loading) ?
                            <Box className=" flex flex-col items-center  gap-2 w-full h-full px-4 ">
                                {/* <CircularProgress />
                                <Typography>Loading...</Typography> */}


                                {
                                    Array.from({ length: 15 }).map((item, index) => <Skeleton key={index} variant="rounded" animation="pulse" width={"100%"} height={30} />)
                                }
                            </Box>
                            :
                            (
                                <Box className=" flex w-full h-full ">
                                    <Treeview dataList={folderStructureList} />
                                </Box>
                            )
                    }
                </Box>

            </Box>}

            {/* main container */}
            <Box
                className="flex flex-col gap-4 h-full relative overflow-hidden z-20 "
                bgcolor={theme.palette.primary.layout_col}
                sx={{
                    // width: (toggleFolderSidebar && toggleChatWindow) ? "50vw" : (toggleFolderSidebar && !toggleChatWindow) ? "80vw" : "70vw",
                    width : "100%",
                    transition: "width 0.5s ease-in-out",
                    // maxHeight: isGridViewEnabled ? "96.5%" : "unset",
                    boxSizing: "border-box"
                }}
            >


                {/* <Box className="w-full flex border-b" >
                    <Header />
                </Box> */}

                <Box className={`flex flex-col h-full w-full  `}>
                    <RequirementMainContainer />
                </Box>

                {/* userstory */}
                {pathName === ModulePath.requirements && (!openEditStoryDialog) &&
                    <Box className="absolute bottom-5 right-2 z-10">
                        <LightTooltip title={`Add User Story`}>
                            <Fab
                                color="primary"
                                disabled={currentFolderData?.data?.type !== FolderType.module || openEditStoryDialog}
                                startIcon={<NoteAddOutlinedIcon />}
                                onClick={handleOpenCreateDialog}
                                style={{}}>
                                <Add />
                            </Fab>
                        </LightTooltip>
                    </Box>}

                {pathName === ModulePath.useCase && (!openEditUseCaseDialog) &&
                    <Box className="absolute bottom-5 right-2 z-10">
                        <LightTooltip title={`Add Use Case`}>
                            <Fab
                                color="primary"
                                // disabled={currentFolderData?.data?.type !== FolderType.module || openEditUseCaseDialog}
                                startIcon={<NoteAddOutlinedIcon />}
                                onClick={handleOpenCreateDialog}
                                style={{}}>
                                <Add />
                            </Fab>
                        </LightTooltip>
                    </Box>
                }


            </Box>


            {/* chat window */}
            {false && (toggleChatWindow && <Box className={` flex overflow-hidden h-full border-l border-gray-300 shadow-md `} bgcolor={theme.palette.primary.layout_col}
                sx={{
                    minWidth: toggleChatWindow ? "30vw" : 0,
                    maxWidth: toggleChatWindow ? "30vw" : 0,
                    transition: "width 0.5s ease-in-out",
                }}
            >
                <ChatWindow />
            </Box>)}


            {/* {openStoryPreview && currentUserStory && <StoryPreview />} */}

            {/* {openCreateModuleDialog && <CreateModuleDialog open={openCreateModuleDialog} handleClose={() => setOpenCreateModuleDialog(false)}
                handleConfirm={handleCreateModule} />}

            {openEditModuleDialog && <EditModuleDialog open={openEditModuleDialog} handleClose={() => setOpenEditModuleDialog(false)}
                handleConfirm={handleUpdateModule} />}

            {openDeleteModuleDialog && <DeleteModuleDialog open={openDeleteModuleDialog} handleClose={() => setOpenDeleteModuleDialog(false)}
                handleConfirm={handleDeleteModule} data={currentFolderData?.data} />} */}



        </Box>
    )
}



function Header({ currentTab, setCurrentTab }) {
    const { activeProject, setActiveProject, toggleChatWindow, setToggleChatWindow, toggleFolderSidebar, setToggleFolderSidebar, isGridViewEnabled, setIsGridViewEnabled, openCreateStoryDialog, setOpenCreateStoryDialog, openEditStoryDialog, isShowStoryFilter, setIsShowStoryFilter, openDeleteStoryDialog, setOpenDeleteStoryDialog, selectedUserStoryList, setSelectedUserStoryList, currentFolderData, setFolderStructureList, setUserStoryList } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();

    // const breadcrumbs = [
    //     <Button variant="outlined" key="1">
    //         {activeProject?.name}
    //     </Button>,
    //     <Button variant="outlined" key="2">
    //         Module
    //     </Button>,
    //     <Button variant="outlined" key="3">
    //         UserStory
    //     </Button>,
    // ];

    const handleHomeClick = () => {
        navigate(ModulePath.projects);
        setActiveProject(null);
        localStorage.removeItem("activeProject");
        setFolderStructureList([]);
        setUserStoryList([]);
    }

    const handleDeleteClick = () => {
        setOpenDeleteStoryDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    return (
        <Box className="w-full h-full flex gap-1  " >

            <Box className="flex items-center gap-4 flex-1 ">

                {!openCreateStoryDialog && <Box className="flex items-center gap-2  rounded-lg overflow-hidden ">
                    <ToggleButtonGroup
                        size="small"
                        value={isGridViewEnabled}
                        exclusive
                        onChange={(e, newValue) => setIsGridViewEnabled(newValue)}
                        aria-label="text alignment"
                        sx={{ boxSizing: "border-box" }}
                    >
                        <LightTooltip title={`Grid View`}>
                            <ToggleButton value={true} aria-label="centered">
                                <GridViewIcon />
                            </ToggleButton>
                        </LightTooltip>
                        <LightTooltip title={`List View`}>
                            <ToggleButton value={false} aria-label="centered">
                                <ListIcon />
                            </ToggleButton>
                        </LightTooltip>
                    </ToggleButtonGroup>
                </Box>}

                {!openCreateStoryDialog && <Box className="flex items-center gap-4  ml-auto">

                    <LightTooltip title={`Delete`}>
                        <IconButton disabled={currentFolderData?.data?.type !== FolderType.module || selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                            <DeleteOutline />
                        </IconButton>
                    </LightTooltip>

                    <LightTooltip title={`Upload`}>
                        <IconButton  >
                            <FileUploadOutlinedIcon />
                        </IconButton>
                    </LightTooltip>

                    <LightTooltip title={`Download`}>
                        <IconButton  >
                            <FileDownloadOutlinedIcon />
                        </IconButton>
                    </LightTooltip>

                    <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
                        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
                            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
                        </IconButton>
                    </LightTooltip>
                </Box>}




            </Box>

        </Box>
    )
}