import { Box, useTheme } from '@mui/material'
import { ApplicationPages } from '../../constant/ApplicationJson';
import { grey } from '@mui/material/colors';
import { LightTooltip } from '../styled/CustomComponent';
import { useNavigate, useLocation } from 'react-router-dom';

function ApplicationNavigation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathName = useLocation()?.pathname?.split("/")[1];


  const handleClickPage = (item) => {
    return navigate(`${item?.path}`);
  }
 
  console.log(pathName, "location")

  return (
    <Box className="w-fit flex flex-col items-center  border rounded-lg overflow-hidden divide-y" >
      {
        ApplicationPages?.map((item, index) => {
          console.log(item?.path , pathName, "item?.path === pathName")
          return (
            <LightTooltip title={item?.label} key={index} placement="right" arrow>
              <Box
                onClick={() => handleClickPage(item)}
                width={"40px"}
                height={"40px"}
                className="   transition-colors flex items-center justify-center  cursor-pointer box-border "
                sx={{
                  color: item?.path?.split("/")[1] === pathName ? theme.palette.primary.contrastText : grey[800],
                  background: item?.path?.split("/")[1] === pathName ? theme.palette.primary.main : grey[50],
                  "&:hover": { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText },
                  "transition": "all 0.3s ease-in-out"
                }}
              >
                {item?.icon}
              </Box>
            </LightTooltip>
          )
        })
      }
    </Box>
  )
}

export default ApplicationNavigation