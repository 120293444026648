import { FolderOutlined, FolderSpecialOutlined, FolderSpecialTwoTone, FolderTwoTone, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Tree } from 'react-arborist'
import { font } from '../../styles/font';
import { useApplicationContext } from '../../context/ApplicationContext';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LightTooltip } from '../styled/CustomComponent';
import { FolderType, ModulePath } from '../../constant/ApplicationVariables';
import { useLocation } from 'react-router-dom';

export default function Treeview({ dataList }) {
    console.log(dataList, "dataList");

    return (
        <Box className="flex flex-1 px-4 w-full " onContextMenu={(e) => e.preventDefault()}>
            <AutoSizer>
                {({ height, width }) => {
                    console.log(height, width, "heightwidth")
                    return (
                        <Tree
                            initialData={dataList}
                            openByDefault={true}
                            width={width}
                            height={height}
                            indent={24}
                            rowHeight={38}
                        // overscanCount={1}
                        // paddingTop={30}
                        // paddingBottom={10}
                        // padding={25 /* sets both */}
                        >
                            {Node}
                        </Tree>

                    )
                }}
            </AutoSizer>
        </Box>
    )
}


function Node({ node, style, dragHandle }) {
    const {
        currentFolderData, setCurrentFolderData,
        isUpdateCurrentFolderData, setIsUpdateCurrentFolderData,
        activeProject,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        currentFolderAnchorEl, setCurrentFolderAnchorEl,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        currentUserStoryData,
        currentUseCaseData
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;

    const handleToggleFolderMenu = (event) => {

        if (currentFolderAnchorEl) {
            setCurrentFolderAnchorEl(null);
        } else {
            setCurrentFolderAnchorEl(event.currentTarget);
        }
    }


    const handleFolderClick = (folder) => {
        // folder.toggle();
        setCurrentFolderData(folder);

        console.log(folder, "node1data");


        // when the folder changes close the usecase and story dialog
        if (currentFolderData?.id !== folder?.id) {
            setOpenEditStoryDialog(false);
            setOpenEditUseCaseDialog(false);
        }
    }

    const handleIconClick = (folder) => {
        folder.toggle();
        setCurrentFolderData(folder);

        console.log(folder, "node2data");
    }

    const handleRightClick = (event, folder) => {
        event.preventDefault();
        console.log(folder, "folder rightclick");
        setCurrentFolderData(folder);

        handleToggleFolderMenu(event);

        // when the folder changes close the usecase and story dialog
        setOpenEditStoryDialog(false);
        setOpenEditUseCaseDialog(false);

    }


    // When active Project changes, then update the current folder
    useEffect(() => {
        if (activeProject) {
            if (!currentFolderData && node?.level === 0 && node?.data?.type === FolderType.project) {
                setCurrentFolderData(node)
            }
            if (currentFolderData && ((node?.level === 0 && node?.data?.type === FolderType.project && node?.data?.name?.toLowerCase()) !== activeProject?.name?.toLowerCase())) {
                setCurrentFolderData(node)
            }


            // login is incorrect
            // if (node?.level === 0 && node?.data?.type === FolderType.project && (!currentFolderData || currentFolderData?.data?.name?.toLowerCase() !== activeProject?.name?.toLowerCase())) {
            //     setCurrentFolderData(node);
            // }
        }
    }, [activeProject]);


    useEffect(() => {
        if (!isUpdateFolderStructure) {
            if (currentFolderData) {
                setCurrentFolderData(currentFolderData)
                return;
            }
            if (node?.data?.type === FolderType.project && !currentFolderData) {
                setCurrentFolderData(node)
            }
        }
    }, [isUpdateFolderStructure, setCurrentFolderData])

    // To update the current folder  data
    // useEffect(() => {
    //     if (isUpdateCurrentFolderData && currentFolderData && node?.id === currentFolderData?.id) {
    //         setCurrentFolderData(node);
    //         // setIsUpdateCurrentFolderData(false);
    //     }
    // }, [isUpdateCurrentFolderData, currentFolderData?.id, setCurrentFolderData, setIsUpdateCurrentFolderData])



    // To select the current folder data based on current story / usecase module
    useEffect(() => {
        if (openEditStoryDialog && currentUserStoryData && node?.data?.name === currentUserStoryData?.moduleName) {
            setCurrentFolderData(node)
        }

        if (openEditUseCaseDialog && currentUseCaseData && node?.data?.name === currentUseCaseData?.moduleName) {
            setCurrentFolderData(node)
        }

    }, [openEditStoryDialog, openEditUseCaseDialog]);

    console.log(node, "node1");

    console.log(currentFolderData, "currentFolderData")

    console.log(currentFolderAnchorEl, "folderAnchorEl");

    return (
        <Box
            className={`  flex items-center gap-1 py-1 px-2 cursor-pointer overflow-hidden`}
            borderRadius={theme.shape.borderRadius}
            // style={style}
            // ref={dragHandle}
            // onClick={() => { handleFolderClick(node) }}
            sx={{
                // color: (currentFolderData?.id === node?.id) && theme.palette.secondary.contrastText,
                // background: (currentFolderData?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                background: (currentFolderData?.id === node?.id) && theme.palette.highlight.main,
                // color: (currentFolderData?.id === node?.id) && theme.palette.secondary.contrastText,
                color: (currentFolderData?.id === node?.id) ? theme.palette.text.primary : theme.palette.text.secondary,
                pl: (node.level * 4)
            }}

        >
            <Box className="" onClick={() => handleIconClick(node)}>
                {!node.isLeaf &&
                    (node.isOpen ?
                        <KeyboardArrowDown /> : <KeyboardArrowRight />
                    )
                }
            </Box>

            <Box className=" flex-1 flex items-center gap-1  overflow-hidden" onClick={() => handleFolderClick(node)} sx={{ pl: 0 }} onContextMenu={(e) => handleRightClick(e, node)}>
                {node.data.type === FolderType.project && <FolderSpecialOutlined sx={{}} />}
                {node.data.type === FolderType.module && <FolderOutlined sx={{}} />}

                {/* <Box className="flex-1 flex items-center gap-1  box-border overflow-hidden border-2 border-gray-400 "> */}


                <LightTooltip
                    title={<Box className="flex flex-col gap-1">
                        <Typography fontSize={font.size.fs_5} className="flex-1 ">Title: {node.data.name}</Typography>
                        <Typography fontSize={font.size.fs_5} className="">Description: {node?.data?.description}</Typography>
                        {pathName === ModulePath.requirements &&
                            <Typography fontSize={font.size.fs_5} className="">User Story: {node?.data?.userStoryCount}</Typography>}
                        {pathName === ModulePath.useCase &&
                            <Typography fontSize={font.size.fs_5} className="">Use Case: {node?.data?.useCaseCount}</Typography>}
                    </Box>}
                    placement="right"
                >
                    <Box className="flex-1 flex items-center  gap-1  box-border  overflow-hidden">
                        <Typography fontSize={font.size.fs_5} className=" flex-1 overflow-hidden text-ellipsis whitespace-nowrap box-border " sx={{ color: "inherit" }}>{node.data.name}</Typography>
                        <Typography fontSize={font.size.fs_5} className="w-fit" sx={{ color: "inherit" }}>{`[${pathName === ModulePath.requirements ? node?.data?.userStoryCount : node?.data?.useCaseCount}]`}</Typography>
                    </Box>
                </LightTooltip>
                {/* </Box> */}
            </Box>


        </Box>
    );
}