import { Typography } from '@mui/material'
import { font } from '../../styles/font'
import { STATUS_COLOR } from '../../constant/ApplicationJson'

export default function StatusBadge({ status }) {
    return (
        <>
            <Typography
                fontSize={font.size.fs_6}
                className="line-clamp-1 select-none uppercase w-fit "
                sx={{ background: STATUS_COLOR[status?.toLowerCase()]?.backgroundColor, color: STATUS_COLOR[status?.toLowerCase()]?.color, padding: "0rem 0.5rem", borderRadius: "0.2rem" }}
            >
                {status}
            </Typography>
        </>
    )
}
