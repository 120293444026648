import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Avatar, Chip, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { ArrowRightAlt, Close, Edit } from '@mui/icons-material';
import { font } from '../../styles/font';
import { useApplicationContext } from '../../context/ApplicationContext';
import { blue } from '@mui/material/colors';

export default function ViewUserStoryDrawer({ open, handleClose }) {
    const { currentUserStoryData, setCurrentUserStoryData, } = useApplicationContext();
    const theme = useTheme();

    const toggleDrawer = () => {
        handleClose();
    };

    const DrawerList = (
        <Box sx={{ width: 420 }} role="presentation" className="flex flex-col h-full">

 
            <Box className="flex flex-col  px-4 py-2 gap-1 border-b-2 sticky top-0" bgcolor={theme.palette.common.white}>
                <Box className="flex items-center justify-between gap-1">
                    <Typography fontSize={font.size.fs_4} className="flex-1 font-bold">#{currentUserStoryData?.id}</Typography>

                    <Avatar sx={{ width: "2rem", height: "2rem", background: blue[500], mr: 1 }}>
                        U
                    </Avatar>

                    <IconButton onClick={() => { }} sx={{ color: blue[500] }}><Edit /></IconButton>
                    <IconButton onClick={() => toggleDrawer()}><Close /></IconButton>
                </Box>

                <Box className="flex items-center  gap-2">
                    <span className="w-1 h-full rounded-full" style={{background : theme.palette.primary.main}}></span>
                    <Typography fontSize={font.size.fs_4} className="opacity-80">{currentUserStoryData?.name}</Typography>
                </Box>
            </Box>

            <Box className="flex-1 flex flex-col gap-4 px-4 py-0 divide-y">


                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Description:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.description}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4}>Acceptance Criteria:</Typography>
                    <Box className={`flex flex-col px-0  gap-1 `}>
                        {currentUserStoryData?.acceptanceCriteria?.map((criteria, index) => (
                            <Box key={index} className="flex items-center gap-1 ">
                                <ArrowRightAlt />
                                <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.description}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Assigned To:</Typography>
                    <Chip label={"User"} className="w-fit" />
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Define Type:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.type}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Category:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.category}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">MOSCOW:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.moscow}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Review Status:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.reviewStatus}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Verification Owner:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.verificationOwner}</Typography>
                </Box>

                <Box className="flex flex-col gap-2 py-2">
                    <Typography fontSize={font.size.fs_4} className="">Verification Method:</Typography>
                    <Typography fontSize={font.size.fs_5} className="opacity-80">{currentUserStoryData?.verificationMethod}</Typography>
                </Box>

            </Box>

            {/* <Box className="flex flex-col gap-4 px-4 py-4 border-t-2">
                <Button variant="contained">Create</Button>
                <Button variant="outlined" color="error" onClick={() => toggleDrawer()}>Discard</Button>
            </Box> */}

        </Box>
    );

    return (
        <Box>
            <Drawer open={open} anchor="right">
                {DrawerList}
            </Drawer>
        </Box>
    );
}