import { Box, Button, Dialog, TextField, Typography } from "@mui/material"
import { font } from "../../styles/font"
import { useEffect, useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";


export default function DeleteModuleDialog({ open, handleClose, handleConfirm,  loading, setLoading }) {
  const { currentFolderData } = useApplicationContext();

  const handleContinue = () => {
    handleConfirm();
  }


  return (
    <Dialog open={open} onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
    >
      <Box className="flex flex-col gap-4 px-4 py-2">
        <Box className="flex items-center justify-between gap-4">
          <Typography fontSize={font.size.fs_4}>Are you sure</Typography>

          

        </Box>

        <Box className="flex items-center gap-1">
          <Typography fontSize={font.size.fs_4}>{`You want to delete:`}</Typography>
          <Typography fontSize={font.size.fs_4} color="secondary">{`${currentFolderData?.data?.name}`}</Typography>
        </Box>

        <Box className="flex items-center justify-end gap-4">
          <Button variant="outlined" disabled={loading} onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" disabled={loading} onClick={handleContinue}>Confirm</Button>
        </Box>

      </Box>
    </Dialog>
  )
}


