import { AddTwoTone, Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, blue, grey } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import AddUserFieldsDrawer from "../drawer/AddUserFieldsDrawer"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function UseCaseList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,

        currentFolderData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
    } = useApplicationContext();
    const theme = useTheme();

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);
    const [openAddUserFields, setOpenAddUserFields] = useState(false);

    let storiesList = [
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "User can create a new user profile with required information.",
                "User should not be able to create a new user profile if the required information is not provided.",
                "User should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },

        {
            name: "Create a new user profile",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Create a new project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            name: "Upload contract",
            description: "User can create a new user profile with required information.",
            type: "story",
            id: 8,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    const handleOpen = (item) => {
        setCurrentUseCaseData(item);
        setOpenEditUseCaseDialog(true);
    }

    const handleCloseData = () => {
        setOpenEditUseCaseDialog(false);
        setCurrentUseCaseData(null);
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUseCaseList(dataList);
        } else {
            setSelectedUseCaseList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUseCaseList([...selectedUseCaseList, item]);
        } else {
            setSelectedUseCaseList(selectedUseCaseList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    const handleOpenLinkTag = (data) => {
        setCurrentUseCaseData(data)
        setOpenLinkTagDrawer(true)
    }

    const handleOpenUserFieldsDrawer = (data) => {
        // setCurrentUserStoryData(data);
        setOpenAddUserFields(true)
    }

    const handleCloseUserFieldsDrawer = () => {
        setOpenAddUserFields(false);
        setCurrentUserStoryData(null);
    }

    const handleToggleAIWindow = (e, data) => {
        e && e.stopPropagation()

        if (isItemChatWindow && currentUseCaseData?.customId === data?.customId) {
            setIsItemChatWindow(false)
            setCurrentUseCaseData(null);
        } else {
            setCurrentUseCaseData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
        }
    }

    const columns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isActiveProjectContainerLoading} checked={!isActiveProjectContainerLoading && useCaseList?.length > 0 && selectedUseCaseList?.length === useCaseList?.length} indeterminate={selectedUseCaseList?.length > 0 && selectedUseCaseList?.length < useCaseList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px"
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px"
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px"
        },
        {
            data: "Custom ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px"
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px"
        },
        {
            data: "Steps",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px"
        },
        {
            data: "Created At",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px"
        },

    ];

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, storyData) => (

                    <TableRow onClick={() => handleOpen(storyData)} sx={{ background: storyData?.customId === currentUseCaseData?.customId && grey[200], "&:hover": { background: grey[100], } }}>

                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, storyData)}>
                                    <IconButton sx={{ background: theme.palette.common.white }}>
                                        {isItemChatWindow && storyData?.customId === currentUseCaseData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                                    </IconButton>
                                </TableCell> */}

                        <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", }} onClick={(e) => e && e.stopPropagation()}>
                            <Checkbox
                                size="small"
                                // disabled={currentFolderData?.data?.type !== FolderType.module}
                                checked={selectedUseCaseList?.includes(storyData)}
                                onChange={(e) => handleChangeCheckbox(e, storyData)}
                            />
                        </TableCell>
                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer" }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Link: ${Entity_Label.userStory} / ${Entity_Label.testCase}`} >
                                <IconButton size="small" onClick={() => handleOpenLinkTag(storyData)} sx={{ color: storyData?.userStories?.length > 0 ? amber[500] : theme.palette.primary.light, transform: "rotate(90deg)" }}>
                                    <Attachment />
                                </IconButton>
                            </LightTooltip>
                        </TableCell>
                        <LightTooltip title={`Use Case`}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> Use Case</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.customId}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1 font-bold underline cursor-pointer"> #{storyData?.customId}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.title}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-2"> {storyData?.title}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.useCaseSteps?.join(", ")} >
                            <TableCell padding="normal" align="left" sx={{ minWidth: "480px", maxWidth: "480px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.useCaseSteps?.join(", ")}</Typography>
                            </TableCell>
                        </LightTooltip>

                        <LightTooltip title={dayjs(storyData?.createdAt).format("DD MMM YYYY hh:mm A")}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {dayjs(storyData?.createdAt).format("DD MMM YYYY hh:mm A")}</Typography>
                            </TableCell>
                        </LightTooltip>

                    </TableRow>

                )}
            />

            {/* <AddUserFieldsDrawer
                open={true}
                handleClose={handleCloseUserFieldsDrawer}
                handleContinue={() => { }}
            /> */}


            {/* {openUserStoryDrawer && currentUserStoryData && 
            <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseData} />} */}
        </Box>
    )
}



