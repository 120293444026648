import { Box, Button, Dialog, TextField, Typography } from "@mui/material"
import { font } from "../../styles/font"
import { useEffect, useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";


export default function EditModuleDialog({ open, handleClose, handleConfirm,  loading, setLoading  }) {
  const { currentFolderData } = useApplicationContext();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
    }
  };
 
  const handleContinue = () => {
    handleConfirm({ name, description });
  }

  useEffect(() => {
    setName(currentFolderData?.data?.name);
    setDescription(currentFolderData?.data?.description);
  }, [currentFolderData]);

  return (
    <Dialog open={open} onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
    >
      <Box className="flex flex-col gap-4 px-4 py-2">
        <Box className="flex items-center justify-between gap-4">
          <Typography fontSize={font.size.fs_4}>Edit Module</Typography>

        </Box>

        <Box className="flex flex-col gap-4">
          <TextField name="name" required label="Name" fullWidth value={name} onChange={handleChange} />
          <TextField name="description" required label="Description" fullWidth multiline minRows={4} maxRows={4} value={description} onChange={handleChange} />
        </Box>

        <Box className="flex items-center justify-end gap-4">
          <Button variant="outlined" disabled={loading} onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error"  disabled={!name || !description || loading} onClick={handleContinue}>Confirm</Button>
        </Box>

      </Box>
    </Dialog>
  )
}


