import { Add, ArrowBack, AutoAwesomeTwoTone, Close, Create, Delete, DragHandle, ExpandMore, Save } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { blue, grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { addStory_API, generateUserStoryCustomId_API, getStoryList_API, getStoryStatusList_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { FolderType, Severity } from "../../constant/ApplicationVariables";
import styled from "@emotion/styled";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import ReactQuill from "react-quill";

const StyledSelect = styled(Select)(({ theme }) => ({
    height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
    },
}));

const QuillContainer = styled("div")(({ theme }) => ({
    ".ql-container": {
        borderRadius: " 0 0 0.6rem 0.6rem",
        border: `1px solid ${grey[600]}`,
    },
    ".ql-toolbar": {
        borderRadius: "  0.6rem 0.6rem 0 0 "
    },
    ".ql-editor": {
        height: "200px"
    },
}));



export default function CreateStoryComponent({ open, closeDialog, }) {
    const {
        activeProject,
        handleSnackbar,
        currentFolderData,
        setUserStoryList,
        setIsUpdateFolderStructure,
        isUpdateProjectList, setIsUpdateProjectList
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [accetanceCriteriaText, setAccetanceCriteriaText] = useState("");
    const [userStoryData, setUserStoryData] = useState({
        "customId": "",
        "title": "",
        "description": "",
        "priority": "High",
        "moduleName": "",
        "projectName": activeProject?.name,
        "assignedTo": "",
        "acceptanceCriteria": [],
        "status": ""
    });
    const [statusList, setStatusList] = useState([]);
    const [editorValue, setEditorValue] = useState("");

    const handleChange = (event) => {
        setUserStoryData({ ...userStoryData, [event.target.name]: event.target.value });
    }

    const handleAddAcceptanceCriteria = () => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: [...userStoryData?.acceptanceCriteria, accetanceCriteriaText] });
        setAccetanceCriteriaText("");
    }

    const handleRemoveAcceptanceCriteria = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userStoryData?.customId) flag = false;
        if (!userStoryData?.title) flag = false;
        if (!userStoryData?.description) flag = false;

        return flag;
    }

    const handleContinueClick = () => {
        handleCreateStory(userStoryData);
    }

    const handleCurrentListItem = (stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = userStoryData?.acceptanceCriteria
        list[stepIndex] = stepText;
        setUserStoryData({ ...userStoryData, acceptanceCriteria: list });
    }

    const handleRemoveListItem = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }

    const handleDragStart = (index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = [...userStoryData?.acceptanceCriteria];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserStoryData({ ...userStoryData, acceptanceCriteria: newSteps });
    };

    const handleAddRowBelow = (index) => {
        let steps = userStoryData?.acceptanceCriteria ? [...userStoryData?.acceptanceCriteria] : [];

        if (steps?.length > 0 && steps[index] === "") {
            return;
        }
        if (steps?.length > 0) {
            steps.splice(index + 1, 0, "");
        } else {
            steps.push("");
        }
        
        setUserStoryData({ ...userStoryData, acceptanceCriteria: steps });  

    }

    const handleGetStatusList = async () => {
        try {
            // setGlobalLoader(true);

            const response = await getStoryStatusList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setStatusList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleGenerateCustomId = async () => {
        try {
            setLoading(true);

            let body = {
                "projectName": activeProject?.name,
                "moduleName": currentFolderData?.data?.type === FolderType.module ? currentFolderData?.data?.name : null
            }

            const response = await generateUserStoryCustomId_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                setUserStoryData({ ...userStoryData, customId: response.data });
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {
            setLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleCreateStory = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
                "priority": "High",
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name,
            }

            const response = await addStory_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setIsUpdateFolderStructure(true);
                setIsUpdateProjectList(true);
                closeDialog();
            }

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        handleGetStatusList();
        setTimeout(() => {
            handleGenerateCustomId();
        }, 1000)
    }, []);

    console.log(userStoryData, "userstorydata");


    if (true) {
        return (
            <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 "
                // bgcolor={theme.palette.primary.layout_col}
                borderRadius={theme.shape.borderRadius}
                bgcolor={theme.palette.common.white}
            >

                {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                    <CircularProgress />
                </Box>}


                <Box className="flex flex-col px-4 py-4 gap-8 w-full h-full relative rounded-lg  " >

                    {/* top */}
                    <Box className="w-full h-[7%] flex items-center justify-between gap-8 ">
                        {/* left */}
                        <Box className="flex-1 flex items-center gap-2 w-full ">
                            <ToggleButton
                                size="small"
                                value="check"
                                onClick={closeDialog}
                            >
                                <ArrowBack />
                            </ToggleButton>

                            <SectionContainer >
                                <HeadingComponent heading="Custom ID" hideColon />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="customId"
                                    // label="Custom ID"
                                    required
                                    fullWidth

                                    // focused={userStoryData?.customId}
                                    // autoFocus
                                    value={userStoryData?.customId}
                                    onChange={handleChange}

                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LightTooltip title="Generate Custom ID">
                                                    <IconButton disabled={userStoryData?.customId} onClick={handleGenerateCustomId}>
                                                        <AutoAwesomeTwoTone color={userStoryData?.customId ? theme.palette.primary.light : theme.palette.primary.main} />
                                                    </IconButton>
                                                </LightTooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </SectionContainer>
                        </Box>

                        {/* right */}
                        <Box className="w-[32%] flex items-center justify-end gap-2">
                            {/* <IconButton
                                disabled={!handleCheckValues()}
                                onClick={() => handleContinueClick()}
                            >
                                <Save />
                            </IconButton> */}

                            <ToggleButton
                                size="small"
                                value="check"
                                disabled={!handleCheckValues()}
                                onClick={() => handleContinueClick()}
                            >
                                <Save />
                            </ToggleButton>


                            {/* <IconButton
                                onClick={closeDialog}
                            >
                                <Close />
                            </IconButton> */}
                        </Box>
                    </Box>

                    {/* bottom */}
                    <Box className="w-full h-[calc(93%-2rem)] flex-1 flex gap-8 ">
                        {/* left */}
                        <Box className="flex-1 flex flex-col gap-4  overflow-y-auto box-border">

                            <SectionContainer >
                                <HeadingComponent heading="Title" hideColon />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="title"
                                    required
                                    placeholder="Title"
                                    // autoFocus
                                    fullWidth
                                    multiline
                                    minRows={1}
                                    maxRows={2}
                                    // focused={userStoryData?.title}
                                    value={userStoryData?.title}
                                    onChange={handleChange}


                                />
                            </SectionContainer>

                            {/* <TextField
                                size="small"
                                variant="outlined"
                                name="description"
                                // autoFocus
                                required
                                label="Description"
                                fullWidth
                                multiline
                                minRows={6}
                                maxRows={6}
                                // focused={userStoryData?.description}
                                value={userStoryData?.description}
                                onChange={handleChange}
                            /> */}

{/* description */}
                            <SectionContainer >
                                <HeadingComponent heading="Description" hideColon />
                                <QuillContainer>
                                    <ReactQuill
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                // [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                // ['link', 'image', 'video'],
                                                // ['blockquote', 'code-block'],
                                                // [{ 'align': [] }],
                                                // [{ 'color': [] }, { 'background': [] }],
                                                // ['clean'],
                                                // [{ 'label': 'Add', 'icon': Add, 'onClick': handleAddAcceptanceCriteria }],
                                            ],
                                        }}
                                        formats={[
                                            'header', 'bold', 'italic', 'underline', 'strike',
                                            'list', 'bullet', 'indent', 'link', 'image', 'video',
                                            'blockquote', 'code-block', 'align', 'color', 'background', "label", "icon"
                                        ]}
                                        value={userStoryData?.description}
                                        onChange={(value) => setUserStoryData({ ...userStoryData, description: value })}
                                        placeholder="Description"
                                    />

                                    {/* <Box className="flex items-center justify-end gap-2 py-1 border-b border-gray-300">
                                    <AutoAwesomeTwoTone />
                                </Box> */}
                                </QuillContainer>
                            </SectionContainer>



{/* acceptance criteria */}
                            <SectionContainer >
                                <HeadingComponent heading="Acceptance Criteria" hideColon />
                                <Box className={`flex flex-col gap-2 ${userStoryData?.acceptanceCriteria?.length > 0 ? "min-h-48 max-h-48" : ""} overflow-y-auto`}>

                                    {userStoryData?.acceptanceCriteria?.length > 0 ?
                                        userStoryData?.acceptanceCriteria?.map((currentStep, index) => (
                                            <Box
                                                key={index}
                                                className="flex items-center gap-1  relative"
                                            >
                                                <LightTooltip title="Add Step below" >
                                                    <IconButton
                                                        onClick={() => handleAddRowBelow(index)}
                                                        sx={{ position: "relative", top: "6px" }}
                                                    >
                                                        <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                    </IconButton>
                                                </LightTooltip>

                                                <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>

                                                <Box
                                                    className="flex items-center gap-0 hover:cursor-grab border rounded-lg w-full"
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(index, e)}
                                                    onDragOver={(e) => handleDragOver(e)}
                                                    onDrop={(e) => handleDrop(index, e)}
                                                >


                                                    <IconButton className="cursor-grab">
                                                        <DragHandle />
                                                    </IconButton>

                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        placeholder="Criteria"
                                                        value={currentStep}
                                                        onChange={(e) => handleCurrentListItem(index, e.target.value)}
                                                        sx={{
                                                            px: 0,
                                                            "& .MuiOutlinedInput-root": {
                                                                fontSize: font.size.fs_4,
                                                                "& fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                    // borderColor: theme.palette.common.black
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    <IconButton color="error" onClick={() => handleRemoveListItem(index)}><Delete /></IconButton>
                                                </Box>
                                            </Box>
                                        ))

                                        :
                                        <Box className="flex  items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                            <Typography>Add</Typography>
                                            <IconButton onClick={() => handleAddRowBelow(0)}>
                                                <Add />
                                            </IconButton>
                                        </Box>

                                    }
                                </Box>
                            </SectionContainer>
                        </Box>

                        {/* right */}
                        <Box className="w-[32%] h-full flex flex-col gap-8 overflow-y-auto">

                            <Box className={`flex flex-col gap-6 `}>

                                <KeyValuePairComponent
                                    heading={"Status"}
                                    value={
                                        <StyledSelect
                                            size="small"
                                            variant="outlined"
                                            name="status"
                                            fullWidth

                                            value={userStoryData?.status || ''}
                                            onChange={handleChange}
                                        >
                                            {
                                                statusList?.map((status, index) => (
                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                ))
                                            }
                                        </StyledSelect>
                                    }
                                />

                                <KeyValuePairComponent
                                    heading={"Assigned To"}
                                    value={
                                        <StyledSelect
                                            size="small"
                                            variant="outlined"
                                            name="status"
                                            fullWidth
                                        // disabled={!userStoryData?.assignedTo}
                                        // value={userStoryData?.assignedTo || ''}
                                        // onChange={handleChange}
                                        >
                                            {
                                                ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                ))
                                            }
                                        </StyledSelect>
                                    }
                                />

                                <KeyValuePairComponent
                                    heading={"Priority"}
                                    value={
                                        <StyledSelect
                                            size="small"
                                            variant="outlined"
                                            name="priority"
                                            fullWidth
                                        // disabled={!userStoryData?.status}
                                        // value={userStoryData?.status || ''}
                                        // onChange={handleChange}
                                        >
                                            {
                                                ["HIGH", "MEDIUM", "LOW",]?.map((status, index) => (
                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                ))
                                            }
                                        </StyledSelect>
                                    }
                                />

                                <KeyValuePairComponent heading={"Category"} value={<KeyValueValueComponent value={"Authentication"} />} />

                                <KeyValuePairComponent
                                    heading={"MOSCOW"}
                                    value={<StyledSelect
                                        size="small"
                                        variant="outlined"
                                        name="priority"
                                        fullWidth
                                    // disabled={!userStoryData?.status}
                                    // value={userStoryData?.status || ''}
                                    // onChange={handleChange}
                                    >
                                        {
                                            ["Must have", "Should have", "Could have", "Won't have"]?.map((status, index) => (
                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                            ))
                                        }
                                    </StyledSelect>} />

                                <KeyValuePairComponent
                                    heading={"Story Type"}
                                    value={<StyledSelect
                                        size="small"
                                        variant="outlined"
                                        name="storyType"
                                        fullWidth
                                    // disabled={!userStoryData?.status}
                                    // value={userStoryData?.status || ''}
                                    // onChange={handleChange}
                                    >
                                        {
                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                            ))
                                        }
                                    </StyledSelect>} />

                                <KeyValuePairComponent heading={"User Fields"} value={<KeyValueValueComponent value={"Username, Password"} />} />

                                <KeyValuePairComponent heading={"User Points"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent
                                    heading={"Planned in Release"}
                                    value={<StyledSelect
                                        size="small"
                                        variant="outlined"
                                        name="storyType"
                                        fullWidth
                                    // disabled={!userStoryData?.status}
                                    // value={userStoryData?.status || ''}
                                    // onChange={handleChange}
                                    >
                                        {
                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                            ))
                                        }
                                    </StyledSelect>} />

                                <KeyValuePairComponent heading={"Planned in Sprint"} value={<StyledSelect
                                    size="small"
                                    variant="outlined"
                                    name="storyType"
                                    fullWidth
                                // disabled={!userStoryData?.status}
                                // value={userStoryData?.status || ''}
                                // onChange={handleChange}
                                >
                                    {
                                        ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>} />

                                <KeyValuePairComponent heading={"Confidenciality"} value={<StyledSelect
                                    size="small"
                                    variant="outlined"
                                    name="storyType"
                                    fullWidth
                                // disabled={!userStoryData?.status}
                                // value={userStoryData?.status || ''}
                                // onChange={handleChange}
                                >
                                    {
                                        ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>} />

                                <KeyValuePairComponent heading={"Under SOX Category"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Environment"} value={<StyledSelect
                                    size="small"
                                    variant="outlined"
                                    name="storyType"
                                    fullWidth
                                // disabled={!userStoryData?.status}
                                // value={userStoryData?.status || ''}
                                // onChange={handleChange}
                                >
                                    {
                                        ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>} />

                                <KeyValuePairComponent heading={"Verification Owner"} value={<StyledSelect
                                    size="small"
                                    variant="outlined"
                                    name="storyType"
                                    fullWidth
                                // disabled={!userStoryData?.status}
                                // value={userStoryData?.status || ''}
                                // onChange={handleChange}
                                >
                                    {
                                        ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>} />

                                <KeyValuePairComponent heading={"Verification Methods"} value={<StyledSelect
                                    size="small"
                                    variant="outlined"
                                    name="storyType"
                                    fullWidth
                                // disabled={!userStoryData?.status}
                                // value={userStoryData?.status || ''}
                                // onChange={handleChange}
                                >
                                    {
                                        ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </StyledSelect>} />

                                <KeyValuePairComponent heading={"Dependency"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Bussiness Value"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"User Persona"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Testing Criteria"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Bussiness Unit"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Vendor"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"External Points"} value={<KeyValueValueComponent value={"Value"} />} />

                                <KeyValuePairComponent heading={"Sub Products"} value={<KeyValueValueComponent value={"Value"} />} />

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box className="flex flex-col px-4 pt-4 w-full h-full z-20 absolute left-0 top-0 rounded-lg"
            // bgcolor={theme.palette.primary.layout_col}
            bgcolor={theme.palette.common.white}
        >
            <Box className="flex items-center  pb-4 gap-4">
                <Typography fontSize={font.size.fs_3} className="flex-1">Create </Typography>

                <Button variant="contained" color="inherit" size="small" onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" disabled={!handleCheckValues()}  >Save</Button>
            </Box>


            <Box className="flex  gap-4 py-4 flex-1 overflow-y-auto">
                <Box className="flex flex-col flex-1 gap-4 pb-4">
                    <TextField size="small" name="customId" required label="Custom ID" fullWidth value={userStoryData?.customId} onChange={handleChange} />
                    <TextField size="small" name="title" required label="Title" fullWidth value={userStoryData?.title} onChange={handleChange} />
                    <TextField size="small" name="description" required label="Description" fullWidth multiline minRows={6} maxRows={6} value={userStoryData?.description} onChange={handleChange} />
                    <Box className="flex flex-col gap-2">
                        <Box className="flex items-center gap-4">
                            <TextField size="small" label="Enter acceptance criteria" fullWidth value={accetanceCriteriaText} onChange={(e) => setAccetanceCriteriaText(e.target.value)} />
                            <Button size="small" variant="outlined" disabled={!accetanceCriteriaText} startIcon={<Add />} onClick={handleAddAcceptanceCriteria} >Add</Button>
                        </Box>

                        <Box className="flex flex-col gap-2">
                            {userStoryData?.acceptanceCriteria?.map((criteria, index) => (
                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" bgcolor={theme.palette.common.white}>
                                    <Typography fontSize={font.size.fs_5} className="flex-1">{criteria}</Typography>
                                    <IconButton size="small" onClick={() => handleRemoveAcceptanceCriteria(index)}><Close /></IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {/* <Box className="flex justify-end gap-2">
                    <Button size="small" variant="outlined" startIcon={<Close />} onClick={closeDialog} sx={{ borderRadius: "28px" }}>Cancel</Button>
                    <Button size="small" variant="contained" startIcon={<Add />} onClick={handleContinue} sx={{ borderRadius: "28px" }}>Continue</Button>
                </Box> */}



                </Box>

                <Box className="w-1/2 flex flex-col gap-4">
                    <Box className="w-full flex gap-4">
                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="Category" fullWidth />
                            <TextField size="small" type="email" name="assignedTo" label="Assigned To" fullWidth value={userStoryData?.assignedTo} onChange={handleChange} />
                            <TextField size="small" label="Story Points" fullWidth />
                            <TextField size="small" label="External Points" fullWidth />
                        </Box>

                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="MOSCOW" fullWidth />
                            {/* <TextField size="small" label=" Status" fullWidth disabled /> */}
                            <FormControl sx={{}} size="small">
                                <InputLabel id="demo-select-small-label">Status</InputLabel>
                                <Select size="small" name="status" label="Status" fullWidth value={userStoryData?.status} onChange={handleChange}>
                                    {
                                        statusList?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <TextField size="small" label="Verification Owner" fullWidth />
                            <TextField size="small" label="Sub Product" fullWidth />
                        </Box>
                    </Box>

                    <Box className="w-full flex flex-col px-3 py-2 gap-2 border border-gray-400 rounded-lg">
                        <Typography className="opacity-80">Verification Method</Typography>

                        <Box className="flex px-2 gap-2 ">
                            <FormControlLabel control={<Checkbox />} label="Inspection" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox />} label="Demo" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox />} label="Analysis" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Testing" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                        </Box>
                    </Box>

                </Box>
            </Box>

            {/* <Box className="border-t border-gray-300 flex items-center justify-center gap-4 py-8">
                <Button variant="contained" color="inherit" size="small" startIcon={<Close />} onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={() => handleContinue(userStoryData)} >Continue</Button>
            </Box> */}

        </Box>
    )
}

function KeyValuePairComponent({ heading, value }) {
    return (
        <Box className="w-full flex flex-col gap-1">
            <Box className="flex ">
                <KeyValueHeadingComponent heading={heading} />
            </Box>
            <Box className="flex items-center flex-1 ">
                {value}
            </Box>
        </Box>
    )
}


function KeyValueHeadingComponent({ heading }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600}>{heading}</Typography>
    )
}

function KeyValueValueComponent({ value }) {
    return (
        <Typography fontSize={font.size.fs_4}>{value}</Typography>
    )
}

function HeadingComponent(props) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className={`${props.className} opacity-100`} {...props}>{props.heading} {props.hideColon ? "" : ":"}</Typography>
    )
}

function SectionContainer({ children, ...props }) {
    return (
        <Box className={`w-full flex flex-col gap-1 px-1 border border-transparent cursor-pointer ${props.onClick && "hover:border hover:border-gray-200"}  rounded-lg ${props.className} `} {...props}>
            {children}
        </Box>
    );
}