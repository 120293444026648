import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Close, Description, FilterAltOffOutlined, FilterAltOutlined } from '@mui/icons-material';
import { font } from '../../styles/font';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API, updateProject_API } from '../../api/controller/ProjectController';
import { HttpStatusCode } from 'axios';
import { Severity, StoryStatus } from '../../constant/ApplicationVariables';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import TagIcon from '@mui/icons-material/Tag';
import StatusBadge from '../common/StatusBadge';

export default function FilterUserStoryDrawer({ }) {
    const { setGlobalLoader, setProjectList, handleSnackbar, currentUserStoryData, userStoryList, setUserStoryList, filteredUserStoryList, setFilteredUserStoryList, isUserStoryLoading, setIsUserStoryLoading } = useApplicationContext();
    const { activeProject, setActiveProject, } = useApplicationContext();
    const errorHandler = useErrorHandler();

    const [projectData, setProjectData] = useState(activeProject);
    const [activeProjectCategory, setActiveProjectCategory] = useState(null);
    const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [open, setOpen] = useState(false);
    const [activeFilterData, setActiveFilterData] = useState({
        status: "All",
        createdAt: "All",
        createdBy: "All",
        assignedTo: "All",
        priority: "All",
        moduleName: "All",
        projectName: "All",
    });



    const [isLoading, setIsLoading] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    const toggleDrawer = () => {
        handleClose();
    };

    const handleClose = () => { setOpen(false); };
    const handleContinue = () => { }


    const handleChange = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }

    const checkIsFilterApplied = () => {
        return activeFilterData && Object.keys(activeFilterData).some((key) => activeFilterData[key] !== "All");
    }

    const handleClearFilter = () => {
        setActiveFilterData({
            status: "All",
            createdAt: "All",
            createdBy: "All",
            assignedTo: "All",
            priority: "All",
            moduleName: "All",
            projectName: "All",
        })
    }

    const handleUpdate = () => {
        handleContinue(projectData);
    }

    const handleGetCategoryList = async () => {
        try {
            // setGlobalLoader(true);

            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            // setTimeout(() => {       
            //     setGlobalLoader(false);
            // }, 1000);

            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetsubCategoryList = async () => {
        try {
            // setGlobalLoader(true);

            let query = `parentName=${projectData?.categoryName}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                setProjectData({ ...projectData, "subCategoryName": response.data[0]?.name });

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            // setTimeout(() => {       
            //     setGlobalLoader(false);
            // }, 1000);

            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    useEffect(() => {
        // handleGetCategoryList();
    }, []);

    useEffect(() => {
        if (projectData?.categoryName) {
            // handleGetsubCategoryList();
        }
    }, [projectData?.categoryName]);

    // useEffect(() => {
    //     setActiveProjectCategory(categoryList[0]);
    // }, []);


    useEffect(() => {
        let filteredList = userStoryList;

        setIsUserStoryLoading(true);

        if (activeFilterData?.status) {
            filteredList = filteredList?.filter((item) => item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
        }

        setFilteredUserStoryList(filteredList);

        setTimeout(() => {
            setIsUserStoryLoading(false);
        }, 200);

        console.log(filteredList, "filteredList")

    }, [activeFilterData])

    console.log(projectData, "activeProject");
    console.log(filteredUserStoryList, "filteredUserStoryList")

    const DrawerList = (
        <Box sx={{ width: 420 }} role="presentation" className="flex flex-col h-full">
            <Box className="flex items-center justify-between px-4 py-2 border-b-2">
                <Typography fontSize={font.size.fs_4}>Filter</Typography>
                <IconButton onClick={() => toggleDrawer()}><Close /></IconButton>
            </Box>

            <Box className="flex-1 flex flex-col gap-6 px-4 py-4 ">

                <KeyValueComponent label={"Story Stage"} >
                    <Select
                        fullWidth
                        size="small"
                        className=""
                        variant="outlined"
                        name="status"
                        // disabled={!projectData?.categoryName}
                        value={activeFilterData && activeFilterData?.status}
                        displayEmpty
                        // renderValue={() => projectData?.categoryName}
                        onChange={handleChange}
                    >
                        {
                            ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center py-2">
                                        <StatusBadge status={item?.toLowerCase()} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </KeyValueComponent>

                <KeyValueComponent label={"Created By"} >
                    <Select
                        fullWidth
                        size="small"
                        className=""
                        variant="outlined"
                        name="status"
                        // disabled={!projectData?.categoryName}
                        value={activeFilterData && activeFilterData?.createdBy}
                        displayEmpty
                        // renderValue={() => projectData?.categoryName}
                        onChange={handleChange}
                    >
                        {
                            ["All"]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center py-2">
                                        <StatusBadge status={item?.toLowerCase()} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </KeyValueComponent>

                <KeyValueComponent label={"Created At"} >
                    <Select
                        fullWidth
                        size="small"
                        className=""
                        variant="outlined"
                        name="status"
                        // disabled={!projectData?.categoryName}
                        value={activeFilterData && activeFilterData?.createdAt}
                        displayEmpty
                        // renderValue={() => projectData?.categoryName}
                        onChange={handleChange}
                    >
                        {
                            ["All"]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center py-2">
                                        <StatusBadge status={item?.toLowerCase()} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </KeyValueComponent>





            </Box>

            <Box className="flex flex-col gap-4 px-4 py-4 border-t-2">
                <Button variant="contained" disabled={!activeFilterData} color="error" startIcon={<FilterAltOffOutlined />} onClick={handleClearFilter}>Clear Filter</Button>
                {/* <Button variant="outlined" color="error" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={() => toggleDrawer()}>Discard</Button> */}
            </Box>

        </Box>
    );

    return (
        <Box>
            <IconButton  variant={checkIsFilterApplied() ? "contained" : "outlined"} startIcon={<FilterAltOutlined />} onClick={() => setOpen(true)}>
                <FilterAltOutlined />
            </IconButton>
            <Drawer open={open} anchor="right">
                {DrawerList}
            </Drawer>
        </Box>
    );
}

function KeyValueComponent({ label, children }) {
    return (
        <Box className="flex items-center justify-between gap-4">
            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">{label}:</Typography>
            <Box className="w-[60%]">
                {children}
            </Box>
        </Box>
    )
}