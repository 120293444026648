import React from 'react'
import { useApplicationContext } from '../../context/ApplicationContext';
import { Box, IconButton } from '@mui/material';
import { ArrowLeft, Light, MenuOpen } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { LightTooltip } from '../styled/CustomComponent';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

function SidebarToggleButton(props) {
    const { toggleFolderSidebar, setToggleFolderSidebar } = useApplicationContext();
    const theme = useTheme();

    const handleToggleSidebar = () => {
        setToggleFolderSidebar(!toggleFolderSidebar);
    }

    return (
        <Box
            className={`  h-full w-10 flex items-center justify-center cursor-pointer overflow-hidden`}
            borderRadius={toggleFolderSidebar ? `50px 0 0 50px` : `0 50px 50px 0`}
            bgcolor={theme.palette.secondary.main}
            // border={`2px solid ${theme.palette.secondary.main}`}
            {...props}
        // sx={{ "&:hover": { background: theme.palette.primary.dark } }}
        >
            <LightTooltip title={`${toggleFolderSidebar ? "Hide Sidebar" : "Show Sidebar"}`}>
                <IconButton sx={{ background: !toggleFolderSidebar && theme.palette.secondary.main, }} onClick={handleToggleSidebar}>
                    <MenuOpen sx={{ color: theme.palette.secondary.contrastText, transform: toggleFolderSidebar ? "rotate(0deg)" : "rotate(180deg)", }} />
                </IconButton>
            </LightTooltip>
        </Box>
    )
}

export default SidebarToggleButton
