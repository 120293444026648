import parse from 'html-react-parser';
import { Typography, List, ListItem, Box } from '@mui/material';
import { font } from '../styles/font';

export const convertEditorDataToReact = (editorData) => {
    const parseStyles = (styleString) => {
        if (typeof styleString !== 'string') return {};
        return styleString.split(';')
            .reduce((styles, style) => {
                const [property, value] = style.split(':').map(s => s.trim());
                if (!property) return styles;
                const camelCase = property.replace(/-([a-z])/g, g => g[1].toUpperCase());
                return { ...styles, [camelCase]: value };
            }, {});
    };

    const options = {
        decodeEntities: true,
        transform: (node) => {
            if (node.$$typeof === Symbol.for('react.element')) {
                const commonProps = {
                    key: Math.random(),
                    style: node.props.style ? parseStyles(node.props.style) : {}
                };

                switch (node.type) {
                    case 'li':
                        return <List {...commonProps} sx={{fontSize : font.size.fs_4}}>{node.props.children}</List>;

                    case 'ul':
                        return (
                            <List {...commonProps}>
                                {node.props.children.map((child, index) => (
                                    <Box key={index} className="flex items-center gap-1 pl-4">
                                        <Box className="w-2 h-2 bg-black rounded-full" />
                                        <Typography fontSize={font.size.fs_4}>{child.props.children}</Typography>
                                    </Box>
                                ))}
                            </List>
                        );

                    case 'ol':
                        return (
                            <List {...commonProps}>
                                {node.props.children.map((child, index) => (
                                    <Box key={index} className="flex items-center gap-1 pl-4">
                                        <Typography fontSize={font.size.fs_4}>{index + 1}.</Typography>
                                        <Typography fontSize={font.size.fs_4}>{child.props.children}</Typography>
                                    </Box>
                                ))}
                            </List>
                        );

                    // case 'h1':
                    // case 'h2':
                    // case 'h3':
                    // case 'h4':
                    // case 'h5':
                    // case 'h6':
                    //     return (
                    //         <Typography variant={node.type} {...commonProps}>
                    //             {node.props.children}
                    //         </Typography>
                    //     );

                    case 'h1':
                        return (
                            <Typography variant={'h4'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'h2':
                        return (
                            <Typography variant={'h5'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'h3':
                        return (
                            <Typography variant={'h6'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'h4':
                        return (
                            <Typography fontSize={font.size.fs_4} fontWeight={'bold'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );
                    case 'h5':
                        return (
                            <Typography fontSize={font.size.fs_5} fontWeight={'bold'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );
                    case 'h6':
                        return (
                            <Typography fontSize={font.size.fs_6} fontWeight={'bold'} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'p':
                        return (
                            <Typography fontSize={font.size.fs_5} {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );


                    case 'b':
                        return (
                            <Typography fontSize={font.size.fs_5} fontWeight="bold" {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'i':
                        return (
                            <Typography fontSize={font.size.fs_5} fontStyle="italic" {...commonProps}>
                                {node.props.children}
                            </Typography>
                        );

                    case 'u':
                        return (
                            <Typography fontSize={font.size.fs_5} style={{ textDecoration: 'underline', ...commonProps.style }}>
                                {node.props.children}
                            </Typography>
                        );

                    case 's':
                        return (
                            <Typography fontSize={font.size.fs_5} style={{ textDecoration: 'line-through', ...commonProps.style }}>
                                {node.props.children}
                            </Typography>
                        );

                    default:
                        return node;
                }
            }
            return node;
        },
    };
    return parse(editorData, options);
};