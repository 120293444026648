import { Attachment, AutoAwesome, AutoAwesomeOutlined, Label, LabelOutlined, Link } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, grey } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import { convertEditorDataToReact, parseReactFromHtmlString } from "../../utils/StringOperations"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function UserStoryList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,
        openEditStoryDialog, setOpenEditStoryDialog,
        userStoryList, setUserStoryList,
        selectedUserStoryList, setSelectedUserStoryList,
        globalLoader, setGlobalLoader,
        currentFolderData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow
    } = useApplicationContext();
    const theme = useTheme();

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);

    // to open the user story preview
    const handleOpenUserStory = (item) => {
        setCurrentUserStoryData(item);
        setOpenEditStoryDialog(true);
    }

    // to close the user story preview
    const handleCloseUserStory = () => {
        setOpenEditStoryDialog(false);
        setCurrentUserStoryData(null);
    }

    // to handle the table header checkbox
    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUserStoryList(dataList);
        } else {
            setSelectedUserStoryList([]);
        }
    }

    // to handle the list checkbox
    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUserStoryList([...selectedUserStoryList, item]);
        } else {
            setSelectedUserStoryList(selectedUserStoryList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    // to open the link tag drawer
    const handleOpenLinkTag = (data) => {
        setCurrentUserStoryData(data)
        setOpenLinkTagDrawer(true)
    }

    // To toggle the Ai window for particular list item
    const handleToggleAIWindow = (e, data) => {
        e && e.stopPropagation()

        if (isItemChatWindow && currentUserStoryData?.customId === data?.customId) {
            setIsItemChatWindow(false)
            setCurrentUserStoryData(null);
        } else {
            setCurrentUserStoryData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
        }
    }

    const columns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isUserStoryLoading || currentFolderData?.data?.type !== FolderType.module} checked={!globalLoader && userStoryList?.length > 0 && selectedUserStoryList?.length === userStoryList?.length} indeterminate={!globalLoader && selectedUserStoryList?.length > 0 && selectedUserStoryList?.length < userStoryList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px"
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px"
        },
        {
            data: "Label",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px"
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px"
        },
        {
            data: "Custom ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px"
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px"
        },
        {
            data: "Description",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "260px"
        },
        {
            data: "Acceptance Criteria",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "260px"
        },
        {
            data: " Story Stage",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "120px"
        },
        // {
        //     data: "Category",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        {
            data: "Moscow",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "80px"
        },
        {
            data: "Assigend To",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "120px"
        },
        // {
        //     data: "Sub Product",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        // {
        //     data: "ReferenceId",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        {
            data: "External Party",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "120px"
        },
        // {
        //     data: "Coverage Status",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "120px"
        // },
        // {
        //     data: "Type",
        //     padding: "normal",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "80px"
        // },
        {
            data: "Created By",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "100px"
        },
        {
            data: "Created At",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "180px"
        },
        {
            data: "Verification Owner",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "140px"
        },
        {
            data: "Verification Method",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px"
        },
    ];

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, storyData) => (
                    <TableRow onClick={() => handleOpenUserStory(storyData)} sx={{ background: storyData?.customId === currentUserStoryData?.customId && grey[200], "&:hover": { background: grey[100], } }}>



                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, storyData)}>
                            <IconButton sx={{ background: theme.palette.common.white }}>
                                {isItemChatWindow && storyData?.customId === currentUserStoryData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                            </IconButton>
                        </TableCell> */}
                        <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", }} onClick={(e) => e && e.stopPropagation()}>
                            <Checkbox size="small" disabled={currentFolderData?.data?.type !== FolderType.module} checked={selectedUserStoryList?.includes(storyData)} onChange={(e) => handleChangeCheckbox(e, storyData)} />
                        </TableCell>
                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer" }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Link: ${Entity_Label.useCase} / ${Entity_Label.testCase}`} >
                                <IconButton size="small" onClick={() => handleOpenLinkTag(storyData)} sx={{ color: storyData?.useCases?.length > 0 ? amber[500] : theme.palette.primary.light, }}>
                                    <Link />
                                </IconButton>
                            </LightTooltip>
                        </TableCell>

                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer" }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Label`} >
                                <LabelOutlined />
                            </LightTooltip>
                        </TableCell>

                        <LightTooltip title={`User Story`}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> User Story</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.customId}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1 font-bold underline cursor-pointer"> #{storyData?.customId}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.title}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-2"> {storyData?.title}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={<Typography fontSize={font.size.fs_5} className="w-full max-h-44 overflow-y-auto" > {convertEditorDataToReact(storyData?.description)}</Typography>}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-2" dangerouslySetInnerHTML={{ __html: storyData?.description }} />
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.acceptanceCriteria?.join(", ")} >
                            <TableCell padding="normal" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.acceptanceCriteria?.join(", ")}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.status}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography className="flex items-center justify-center" >
                                    <StatusBadge status={storyData?.status?.toLowerCase()} />
                                </Typography>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={storyData?.category}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.category}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        <LightTooltip title={storyData?.moscow}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.moscow}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.assignedTo}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.assignedTo}</Typography>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={storyData?.subProduct}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }} >
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.subProduct}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.referenceId}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.referenceId}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        <LightTooltip title={storyData?.externalParty}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }} >
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.externalParty}</Typography>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={storyData?.coverageStatus}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.coverageStatus}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.type}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.type}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        <LightTooltip title={storyData?.createdBy}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.createdBy}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={dayjs(storyData?.createdAt).format("DD MMM YYYY HH:mm A")}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "180px", maxWidth: "180px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {dayjs(storyData?.createdAt).format("DD MMM YYYY HH:mm A")}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.verificationOwner}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "140px", maxWidth: "140px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.verificationOwner}</Typography>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.verificationMethod}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.verificationMethod}</Typography>
                            </TableCell>
                        </LightTooltip>

                    </TableRow>
                )}
            />


            {openUserStoryDrawer && currentUserStoryData && <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseUserStory} />}
        </Box>
    )
}



