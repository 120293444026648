import { AccountCircleOutlined, ArrowRightAlt, Attachment, AutoAwesome, Delete, Description, Edit, FolderOutlined, Label, LabelOutlined, Link, MoreHoriz } from "@mui/icons-material"
import { Box, Checkbox, Chip, IconButton, Menu, MenuItem, Popover, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { amber, blue, brown, green, grey, red, yellow } from "@mui/material/colors";
import { useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";
import { FolderType } from "../../constant/ApplicationVariables";
import { LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";

    static Pending = "Pending";
    static Inprogress = "Inprogress";
    static Completed = "Completed";
}

const ITEM_HEIGHT = 48;


export default function StoryCard({ item, handleClick, handleChangeCheckbox, handleOpenLinkTag }) {
    const { isGridViewEnabled, setIsGridViewEnabled, currentFolderData, selectedUserStoryList } = useApplicationContext();
    const theme = useTheme();

    const options = [
        {
            label: "Duplicate Story",
            icon: <FolderOutlined sx={{ color: theme.palette.primary.main }} />,
            onClick: () => console.log("Duplicate Story"),
        },
        {
            label: "Edit Story",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Delete Story",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    const [moreAnchorEl, setMoreAchorEl] = useState(null);

    function GetStoryStatusColor(story_status) {
        // console.log(story_status, "story_status")
        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return brown[500];
            case STORY_STATUS.APPROVED?.toLowerCase():
                return green[500];
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return blue[500];
            case STORY_STATUS.CLOSED?.toLowerCase():
                return red[500];

            // current status
            case STORY_STATUS.Pending?.toLowerCase():
                return brown[500];
            case STORY_STATUS.Inprogress?.toLowerCase():
                return blue[500];
            case STORY_STATUS.Completed?.toLowerCase():
                return green[500];
            default:
                return grey[500];
        }
    }

    const handleOpenMore = (event) => {
        setMoreAchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setMoreAchorEl(null);
    };

    return (
        <Box
            className=" flex flex-col py-0 w-full h-full"
            sx={{
                // borderBottom: `4px solid ${GetStoryStatusColor(item?.status?.toLowerCase())}`,
                // borderStyle: "inset",
                // "@media (max-width: 600px)": {
                //     width: "100%",
                // },
            }}
        // onClick={handleClick}
        >

            {/* upper container */}
            <Box className="flex flex-col gap-2 px-2 py-1 flex-1 cursor-pointer" onClick={handleClick}>
                <Typography fontSize={font.size.fs_4} className=" flex items-center gap-1 line-clamp-1">
                    <Description color="primary" sx={{ fontSize: font.size.fs_4 }} />
                    {item?.customId}
                </Typography>
                <Typography fontSize={font.size.fs_4} className=" line-clamp-2 ">{item?.title}</Typography>
            </Box>


            {/* bottom container */}
            <Box className="flex items-center justify-between px-2 border-t" >

                <Chip
                    variant="outlined"
                    size="small"
                    label={item?.status}
                    sx={{
                        // background: GetStoryStatusColor(item?.status?.toLowerCase()),
                        color: GetStoryStatusColor(item?.status?.toLowerCase())
                    }}
                />

                <Box className="flex items-center gap-0" >
                    {/* <IconButton color="primary" disabled>
                        <AutoAwesome />
                    </IconButton> */}

                    <LabelOutlined />
                    <LightTooltip title="Link">
                        <IconButton onClick={() => handleOpenLinkTag(item)}>
                            <Link sx={{ color: item?.useCases?.length > 0 ? amber[500] : theme.palette.primary.light, }} />
                        </IconButton>
                    </LightTooltip>
                    <Checkbox
                        size="small"
                        disabled={currentFolderData?.data?.type !== FolderType.module}
                        checked={selectedUserStoryList?.includes(item)}
                        onChange={(e) => handleChangeCheckbox(e, item)}
                    />
                </Box>

            </Box>

            <Popover
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleCloseMore}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleCloseMore} className="flex items-center gap-2">
                        {option?.icon}
                        {option?.label}
                    </MenuItem>
                ))}
            </Popover>
        </Box>
    )
}


