import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { font } from '../../styles/font'
import { useApplicationContext } from '../../context/ApplicationContext'
import { FolderType, ModulePath, Severity } from '../../constant/ApplicationVariables'
import { useLocation } from 'react-router-dom'
import { deleteStory_API, getStoryList_API } from '../../api/controller/StoryController'
import { HttpStatusCode } from 'axios'
import { deleteUseCase_API, getUseCaseList_API } from '../../api/controller/UseCaseController'
import useErrorHandler from '../../api/errorHandler/useErrorHandler'

export default function DeleteProjectItemDialog({ open, handleClose, handleContinue }) {
    const {
        selectedUserStoryList, setSelectedUserStoryList,
        selectedUseCaseList, setSelectedUseCaseList,
        handleSnackbar,
        currentFolderData,
        activeProject,
        setUserStoryList,
        setUseCaseList,
        isUpdateProjectList, setIsUpdateProjectList,
        setIsUpdateFolderStructure
    } = useApplicationContext();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(false);


    const handleDelete = () => {
        if (pathName === ModulePath.requirements) {
            selectedUserStoryList?.forEach(story => {
                handleDeleteStory(story);
            });
            handleGetUserStoryList();
        }

        if (pathName === ModulePath.useCase) {
            selectedUseCaseList?.forEach(useCase => {
                handleDeleteUseCase(useCase);
            });
            handleGetUseCaseList();
        }

        setIsUpdateProjectList(true);
        setIsUpdateFolderStructure(true);
        handleSnackbar("Delete Successfully", Severity.success);
        handleClose();
    }

    const handleGetUserStoryList = async () => {
        try {
            setLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteStory = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name
            }

            const response = await deleteStory_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteUseCase = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await deleteUseCase_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }
    const handleGetUseCaseList = async () => {
        try {
            setLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
        >
            <Box className="flex flex-col gap-4 px-4 py-2">
                <Box className="flex items-center justify-between gap-4">
                    <Typography fontSize={font.size.fs_4}>Are you sure</Typography>
                </Box>

                <Box className="flex flex-col gap-2">
                    <Typography fontSize={font.size.fs_5}>You want to delete: </Typography>

                    <Box className="flex flex-col gap-0  max-h-48 overflow-y-auto">
                        {pathName === ModulePath.requirements &&
                            selectedUserStoryList?.map((item, index) => {
                                return (
                                    <Box key={index} className={`flex items-center gap-2 ${index === selectedUserStoryList?.length - 1 ? "" : "border-b"}`}>
                                        <Typography fontSize={font.size.fs_5} className="w-[2%] truncate">{index + 1}.</Typography>
                                        <Typography fontSize={font.size.fs_5} fontWeight={600} className="w-[20%] truncate">#{item?.customId}</Typography>
                                        <Typography fontSize={font.size.fs_5} className="flex-1 truncate">{item?.title}</Typography>
                                    </Box>

                                )
                            })
                        }

                        {pathName === ModulePath.useCase &&
                            selectedUseCaseList?.map((item, index) => {
                                return (
                                    <Box key={index} className={`flex items-center gap-2 ${index === selectedUseCaseList?.length - 1 ? "" : "border-b"}`}>
                                        <Typography fontSize={font.size.fs_5} className="w-[2%] truncate">{index + 1}.</Typography>
                                        <Typography fontSize={font.size.fs_5} fontWeight={600} className="w-[20%] truncate">#{item?.customId}</Typography>
                                        <Typography fontSize={font.size.fs_5} className="flex-1 truncate">{item?.title}</Typography>
                                    </Box>

                                )
                            })
                        }
                    </Box>
                </Box>

                <Box className="flex items-center justify-end gap-4">
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="error" disabled={loading} onClick={() => handleDelete()} >Confirm</Button>
                </Box>

            </Box>
        </Dialog>
    )
}