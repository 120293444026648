import { Alert, Avatar, Box, Button, CircularProgress, Dialog, Drawer, Fab, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Snackbar, Typography, useTheme } from '@mui/material'
import ApplicationHeader from './ApplicationHeader'
import RoutesComponent from '../../routes';
import React, { useEffect, useState } from 'react';
import { login_API } from '../../api/controller/userController';
import { useApplicationContext } from '../../context/ApplicationContext';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, redirect } from 'react-router-dom';
import ProtectedRoute from '../../routes/ProtectedRoute';
import ProjectsContainer from '../projects/ProjectsContainer';
import ExecutionContainer from '../execution';
import MarketplaceContainer from '../marketplace';
import StoryContainer from '../story';
import RequirementsContainer from '../projects/ActiveProject';
import LoginContainer from '../auth/login';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import { APP_CONFIG, FolderType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import ActiveProjectContainer from '../projects/ActiveProject';
import { font } from '../../styles/font';
import { AddTwoTone, DeleteTwoTone, EditTwoTone, HelpTwoTone, LogoutOutlined } from '@mui/icons-material';
import ApplicationSidebar from './ApplicationSidebar';
import ChatWindow from '../projects/ChatWindow';
import { addModule_API, deleteModule_API, updateModule_API } from '../../api/controller/ModuleController';
import CreateModuleDialog from '../dialog/CreateModuleDialog';
import EditModuleDialog from '../dialog/EditModuleDialog';
import DeleteModuleDialog from '../dialog/DeleteModuleDialog';



export default function ApplicationLayout() {
    const { globalSnackbar, setGLobalSnackBar, globalLoader, setGlobalLoader, handleSnackbar, profileDetails, setProfileDetails } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    let UnderDevelopmentText = "Under Development"



    console.log(profileDetails, "profileDetails");

    return (

        <Box className="w-full h-screen flex overflow-hidden relative" >


            <Box className="w-full h-[100dvh] flex flex-col">
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route element={<ApplicationLayoutMainContainer />}>
                            <Route path={ModulePath.home} element={<div>Home</div>} key='home' />
                            <Route path={ModulePath.settings} element={<div>Settings - @{APP_CONFIG.appName} v{APP_CONFIG.appVersion} <br /> {UnderDevelopmentText}</div>} key='settings' />
                            <Route path={ModulePath.projects} element={<ProjectsContainer />} key='project' />
                            <Route path={ModulePath.requirements} element={<ActiveProjectContainer />} key='requirements' />
                            <Route path={ModulePath.useCase} element={<ActiveProjectContainer />} key='usecase' />
                            {/* <Route path={ModulePath.testCase} element={<ActiveProjectContainer />} key='testcase' /> */}
                            <Route path={ModulePath.testCase} element={<div>{UnderDevelopmentText}</div>} key='testcase' />
                            <Route path={ModulePath.dashboard} element={<div>{UnderDevelopmentText}</div>} key='dashboard' />
                            <Route path={ModulePath.execution} element={<div>{UnderDevelopmentText}</div>} key='execution' />
                            <Route path={ModulePath.planning} element={<div>{UnderDevelopmentText}</div>} key='planning' />

                            {/* <Route path={ModulePath.story} element={<StoryContainer />} key='story' />
                            <Route path={ModulePath.marketplace} element={<MarketplaceContainer />} key='marketplace' />
                            <Route path={ModulePath.execution} element={<ExecutionContainer />} key='execution' /> */}
                        </Route>
                    </Route>

                    {localStorage.getItem("access-token") && <Route path="*" element={<Navigate to={ModulePath.projects} />} key="notfound" />}
                    {/* <Route exact path="*" element={<Box className="w-full h-full flex flex-col items-center justify-center gap-2">
                        <Typography>No page found</Typography>
                        <Button variant="contained" onClick={() => navigate(ModulePath.login)}>Go to Login</Button>
                    </Box>} key="notfound" /> */}

                    <Route path={ModulePath.login} element={<LoginContainer />} key="login" />
                </Routes>
            </Box>

            {false && <Box className="w-full h-[100dvh] flex flex-col">

                {/* header */}
                <Box className="h-[11dvh] ">
                    <ApplicationHeader />
                </Box>

                {/* main container */}
                <Box className="h-[89dvh] flex grow px-0 pb-0 overflow-hidden " >
                    <ApplicationLayoutMainContainer />
                </Box>
            </Box>}







            <SnackbarT2T
                globalSnackbar={globalSnackbar}
                setGLobalSnackBar={setGLobalSnackBar}
            />

            {globalLoader && <GlobalLoader />}

        </Box>
    )
}

function ApplicationLayoutMainContainer() {
    const {
        toggleChatWindow, setToggleChatWindow,
        toggleFolderSidebar, setToggleFolderSidebar,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;

    return (
        <Box className="h-screen w-screen flex flex-col" bgcolor={theme.palette.background.custom}>


            <Box className=" h-full w-full flex"   >

                {pathName !== `${(ModulePath.projects)}` && (toggleFolderSidebar &&
                    <Drawer
                        sx={{
                            width: "20%",
                            flexShrink: 0,

                            '& .MuiDrawer-paper': {
                                width: "20%",
                                borderRadius: 0,
                                boxSizing: 'border-box',
                                bgcolor: theme.palette.background.default,
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={true}
                        // className="h-full w-[20%]  flex flex-col" bgcolor={theme.palette.common.white}
                        bgcolor={theme.palette.common.white}
                    >

                        <ApplicationSidebar />

                    </Drawer>
                )}

                <Box className="h-screen  flex-1 min-w-[50%] flex flex-col gap-0" >

                    <Box className={`h-[8vh] w-full flex px-4 py-0 border-b`} bgcolor={theme.palette.background.default}>
                        <ApplicationHeader />
                    </Box>

                    {/* main container */}
                    <Box className={`h-[92vh] w-full flex px-4 py-0  `}>
                        <Outlet />
                    </Box>
                </Box>

                {pathName !== `${(ModulePath.projects)}` && (toggleChatWindow && <Drawer
                    sx={{
                        width: "30%",
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: "30%",
                            borderRadius: 0,
                            boxSizing: 'border-box',
                            bgcolor :  theme.palette.background.default
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={true}
                // className="h-full w-[20%]  flex flex-col" bgcolor={theme.palette.common.white}
                >
                    <ChatWindow />
                </Drawer>)}
            </Box>

        </Box>
    )
}


const SnackbarT2T = ({ globalSnackbar, setGLobalSnackBar }) => (
    <Snackbar
        autoHideDuration={3000}
        sx={{ zIndex: '99999' }}
        onClose={() =>
            setGLobalSnackBar({
                open: false,
                title: "",
                severity: "",
            })
        }
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={globalSnackbar?.open}
    >
        <Alert
            severity={globalSnackbar?.severity}
            variant="filled"
            sx={{ width: "100%" }}
        >
            {globalSnackbar?.title}
        </Alert>
    </Snackbar>
);

const GlobalLoader = () => {
    return (
        <Box className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center w-full h-full z-20 backdrop-blur-sm">
            <CircularProgress />
        </Box>
    )
} 