import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { font } from '../../styles/font'
import { useNavigate } from 'react-router-dom'
import { ModulePath } from '../../constant/ApplicationVariables';

export default function ApplicationLogo({ className }) {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box className={`flex items-center justify-center gap-2 w-full py-0 px-0  ${className} `} borderRadius={theme.shape.borderRadius}  onClick={() => navigate(ModulePath.projects)}>
            {/* <img src="/favicon.ico" alt="FIcon" className="object-contain border border-black rounded-lg w-8 h-8 " /> */}
            {/* <Box className="flex items-center justify-center   rounded-lg w-8 h-8 " bgcolor={theme.palette.primary.layout_col}>
        <Typography fontWeight={500}>FP</Typography>
    </Box> */}
            <Typography fontSize={font.size.fs_3} className="flex items-center h-fit " sx={{color : theme.palette.text.primary}}>
                FLO
                <Typography fontSize={font.size.fs_2} lineHeight={font.size.fs_4} color="secondary" >X</Typography>
                PAD
            </Typography>
        </Box>
    )
}


