import { alpha, colors, createTheme, darken, getContrastRatio, responsiveFontSizes } from "@mui/material";
import { common, green, grey, indigo, lime, purple, red } from "@mui/material/colors";
import { font } from "./font";

const BORDER_RADIUS = "0.6rem";

/* Color variables */
export const COLORS = {
    col_1: "#bbdbe3",  //bfd9e3
    col_2: "#e8eff3", //ebedf2
    col_3: "#272525",

    col_4: "#5B478E",


    primary: "#000000", // Black primary theme
    secondary: "#1A73E8", // Nice green secondary theme color
    text: "#222222", // Black text color
    background: "#FFFFFF", // White background
    lightBackground: "#F3F2F0", // Light background
    
    header : "#e0e0e0",
    highlight : "#F3F2F0",
    

    accent: "#FFD700", // Golden accent color
    success: "#2ECC71", // Success color
    error: "#E74C3C", // Error color
    warning: "#F1C40F", // Warning color
    info: "#3498DB", // Info color
};

/* MUI theme palette for overidiing and apply custom themes */
let theme = createTheme({
    typography: {
        allVariants: {
            //   fontFamily: ["Space Grotesk", "sans-serif"].join(","),
            fontFamily: ["Poppins", "sans-serif"].join(","),
        },
        fontSize: 12,
    },
    palette: {
        primary: {
            main: COLORS.primary,
            light: `${COLORS.primary}CC`, // 80% opacity
            dark: `${COLORS.primary}99`, // 60% opacity
            contrastText: getContrastRatio(COLORS.primary, COLORS.background) > 4.5 ? COLORS.background : COLORS.text
        },
        secondary: {
            main: COLORS.secondary,
            light: alpha(COLORS.secondary, 0.7), // 60% opacity
            dark: darken(COLORS.secondary, 0.6), // 60% opacity
            contrastText: getContrastRatio(COLORS.secondary, COLORS.background) > 4.5 ? COLORS.background : darken(COLORS.secondary, 0.8)
        },
        text: {
            primary: COLORS.text,
            // secondary: grey[600],
            secondary: alpha(COLORS.text, 0.8),
        },

        background: {
            default: COLORS.background,
            light: alpha(COLORS.background, 0.4), 
            dark: darken(COLORS.background, 0.1), 
            custom: `${COLORS.lightBackground}`,
            paper: COLORS.background,
        },


        header: {
            // main: COLORS.header,
            main: COLORS.background,
            light: `${COLORS.header}CC`, // 80% opacity
            dark: `${COLORS.header}99`, // 60% opacity
        },
        highlight: {
            main: COLORS.highlight,
            light: alpha(COLORS.highlight, 0.4), // 80% opacity
            dark: darken(COLORS.highlight, 0.6), // 60% opacity
            contrastText : getContrastRatio(COLORS.highlight, COLORS.background) > 4.5 ? COLORS.background : darken(COLORS.highlight, 0.8)
        },
        card : {
            background : COLORS.background
        }
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            // most basic recommended timing
            standard: 300,
            // this is to be used in complex animations
            complex: 375,
            // recommended when something is entering screen
            enteringScreen: 225,
            // recommended when something is leaving screen
            leavingScreen: 195,
        },
    },
    shape: {
        borderRadius: BORDER_RADIUS
    },
    components: {
        MuiTypography: {
            defaultProps: {
                color: alpha(COLORS.text, 0.6)
            }
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    color: COLORS.text,
                    borderRadius: BORDER_RADIUS,
                    // backgroundColor: COLORS.primary,
                    "&:hover": {
                        // backgroundColor: `${COLORS.primary}CC`, // 80% opacity
                    },
                },
                size: "small",
            },
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                    whiteSpace: "nowrap",
                    textTransform: "capitalize",
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiIcon: {
            defaultProps: {
                sx: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiToggleButton: {
            defaultProps: {
                size: "small",
                sx: {
                    background: COLORS.background,
                    "&:hover": {
                        background: grey[100],
                    },
                },
            },
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: BORDER_RADIUS,
                    transition: "all 0.3s ease-in-out",
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: "top",
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                    borderRadius: BORDER_RADIUS,
                },
            },
            defaultProps: {
                size: "small",
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
            styleOverrides: {
                "& label.Mui-focused": {
                    color: COLORS.secondary,
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: COLORS.secondary,
                },
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: COLORS.secondary,
                    },
                    "&:hover fieldset": {
                        borderColor: COLORS.secondary,
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: COLORS.secondary,
                    },
                    "& input": {
                        fontSize: font.size.fs_5,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: "7px",
                    borderColor: COLORS.col_2,
                },
            },
        },
        MuiDialog: { styleOverrides: { root: { backdropFilter: 'blur(2px)', } } },
        MuiCssBaseline: {
            MuiCssBaseline: {
                styleOverrides: `
                  @font-face {
                    font-family: "Space Grotesk";
                    font-style: normal;
                    font-display: swap;
                    font-weight: 500;
                    src: local("Space Grotesk"),;
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                  }
                `,
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: font.size.fs_5,
                    minWidth: "unset",
                    width: "max-content",
                    paddingInline: "0.5rem",
                }
            }

        }
    },
});


export const themePalette = responsiveFontSizes(theme);