
import { Navigate, Outlet } from 'react-router-dom'
import { ModulePath } from '../constant/ApplicationVariables';

const ProtectedRoute = (props) => {
    let isAuthenticated = localStorage.getItem("access-token");

    return (
        isAuthenticated ?
            <Outlet /> : <Navigate to={ModulePath.login} />
    )
}

export default ProtectedRoute;