import axios, { HttpStatusCode } from "axios";
import { API_CONFIG, MAIN_URL, ModulePath } from "../../constant/ApplicationVariables";

// export default function useAxios() {
// Creating a new Axios instance with baseURL set to MAIN_URL.url

// 5 minutes in milliseconds (for some reason, the default timeout was too low)
let timeout = API_CONFIG.timeout;


const axiosVar = axios;
axiosVar.defaults.timeout = timeout // 5 minutes

export let axiosInstance = axiosVar.create({
  baseURL: MAIN_URL.url,
  timeout: timeout, // 5 minutes


  // headers : {
  //   Authorization : `Bearer ${localStorage.getItem("access-token")}`
  // }
});



// Request interceptor: Modify or handle requests before they are sent
axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("access-token");
    console.log(token, "token useAxios");
    config.timeout = timeout;

    if (config.contentType === "formData") {
      config = contentTypeReq(config);
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    console.log('Timeout value:', config.timeout);
    localStorage.setItem("axios-config", JSON.stringify(config));
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isPromptDisplayed = false;

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response, "response useAxios");
    return response;
  },
  async (error) => {
    console.log(error, "useaxios error");

    // if user has already seen the prompt, reject the other request
    if (isPromptDisplayed) {
      return Promise.reject(error);
    }


    // When the request is unauthorized or forbidden or token is expired
    if (error.response && (error.response.status === HttpStatusCode.Unauthorized || error.response.status === HttpStatusCode.Forbidden)) {

      if (!isPromptDisplayed) {
        isPromptDisplayed = true;

        const userResponse = window.confirm(
          `Error: ${error?.response?.data?.message ? error?.response?.data?.message : error?.message}!\nPlease log in again to continue.`
        );

        if (userResponse) {
          console.log(userResponse, "useaxios userResponse ok")
          return handleTokenExpired(error);
        } else {
          console.log(userResponse, "useaxios userResponse cancel")
        }
        console.log(userResponse, "useaxios userResponse")

      } else {
        // If prompt is already displayed, return a rejected promise to keep the request in pending state
        return Promise.reject(error);
      }
    }



    // it is only for debugging
    if (error.response) {
      // request made and server responded
      console.log(error, "useaxios error.response server reponded");
      localStorage.setItem("axios-error", JSON.stringify(error));

    } else if (error.request) {
      // request made but no response received
      console.log(error, "useaxios error.request server not responded");
      localStorage.setItem("axios-error", JSON.stringify(error));

    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(error, "useaxios error");
      localStorage.setItem("axios-error", JSON.stringify(error));
    }



    // if(error.code === "ERR_NETWORK") {
    //   localStorage.clear();
    //   setTimeout(() => {
    //     window.location.href = ModulePath.login;
    //   }, 200);
    // }



    return Promise.reject(error);
  }
)

function handleTokenExpired(error) {
  localStorage.clear();

  setTimeout(() => {
    window.location.href = ModulePath.login;
  }, 200);

  return Promise.reject(error);
}


const contentTypeReq = async (config) => {
  // Handling formData content type transformation
  if (config.contentType === "formData") {
    let body = config.data;
    let keys = Object.keys(body);
    let formData = new FormData();
    keys.forEach((key) => {
      formData.append(key, body[key]);
    });

    config.data = formData; // Setting config.data to FormData object
  }

  return config; // Returning modified or original config object
};

// Response interceptor: Handle responses or errors from the server


//   return axiosInstance; // Returning the configured Axios instance
// }
