import ApplicationLayout from "./components/layout/ApplicationLayout";
import { ApplicationContextProvider } from "./context/ApplicationContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { BrowserRouter } from "react-router-dom";
import "./App.css";


function App() {
  return (
    <BrowserRouter>
    <ThemeContextProvider>
      <ApplicationContextProvider>
   
          <ApplicationLayout />

      </ApplicationContextProvider>
    </ThemeContextProvider>
    </BrowserRouter>
  );
}

export default App;
