import { Avatar, Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Skeleton, ToggleButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FolderType, ModulePath, Severity } from "../../constant/ApplicationVariables";
import { HttpStatusCode } from "axios";
import { addModule_API, deleteModule_API, updateModule_API } from "../../api/controller/ModuleController";
import { AddOutlined, AddTwoTone, DeleteOutline, DeleteTwoTone, EditOutlined, EditTwoTone, FolderSpecialOutlined, FolderSpecialTwoTone, HelpOutline, HelpTwoTone, LogoutOutlined, Settings } from "@mui/icons-material";
import { LightTooltip } from "../styled/CustomComponent";
import { getFolderStructureList_API } from "../../api/controller/RequirementController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { useApplicationContext } from "../../context/ApplicationContext";
import Treeview from "../common/Treeview";
import DeleteModuleDialog from "../dialog/DeleteModuleDialog";
import EditModuleDialog from "../dialog/EditModuleDialog";
import CreateModuleDialog from "../dialog/CreateModuleDialog";
import { font } from "../../styles/font";
import { useNavigate } from "react-router-dom";
import ApplicationLogo from "./ApplicationLogo";


export default function ApplicationSidebar() {
    const {
        toggleChatWindow, setToggleChatWindow,
        toggleFolderSidebar, setToggleFolderSidebar,
        folderStructureList, setFolderStructureList,
        activeProject, setActiveProject,
        handleSnackbar,
        setGlobalLoader,
        currentFolderData, setCurrentFolderData,
        isUpdateCurrentFolderData, setIsUpdateCurrentFolderData,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        handleLogout,
        profileDetails, setProfileDetails,
        currentFolderAnchorEl, setCurrentFolderAnchorEl,
        isUpdateProjectList, setIsUpdateProjectList,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [openCreateModuleDialog, setOpenCreateModuleDialog] = useState(false);
    const [openEditModuleDialog, setOpenEditModuleDialog] = useState(false);
    const [openDeleteModuleDialog, setOpenDeleteModuleDialog] = useState(false);


    const handleToggleSidebar = () => {
        setToggleFolderSidebar(!toggleFolderSidebar);
    }

    const handleOpenAddModule = () => {
        setOpenCreateModuleDialog(true);
        setCurrentFolderAnchorEl(null);
    }

    const handleOpenEditModule = () => {
        setOpenEditModuleDialog(true);
        setCurrentFolderAnchorEl(null);
    }

    const handleOpenDeleteModule = () => {
        setOpenDeleteModuleDialog(true);
        setCurrentFolderAnchorEl(null);
    }


    const handleGetFolderStructureList = async (projectData) => {
        try {
            setLoading(true);
            let projectName = projectData?.name;
            let query = `name=${projectName}`

            const response = await getFolderStructureList_API(query);
            // setIsUpdateFolderStructure(false);
            console.log(response, "projectreponse handleGetFolderStructureList layout")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setIsUpdateFolderStructure(false);
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }


    const handleCreateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                ...moduleData,
                "projectName": currentFolderData?.data?.name,
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.data);
                setIsUpdateFolderStructure(true);
                setIsUpdateProjectList(true);
                setOpenCreateModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "currentName": currentFolderData?.data?.name,
                "newName": moduleData?.name,
                "description": moduleData?.description
            }

            const response = await updateModule_API(body);
            console.log(response, "response handleUpdateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setOpenEditModuleDialog(false);
                setIsUpdateCurrentFolderData(true);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteModule = async () => {
        try {
            setLoading(true);

            let body = {
                "projectName": currentFolderData?.parent?.data?.name,
                "name": currentFolderData?.data?.name,
            }

            const response = await deleteModule_API(body);
            console.log(response, "response handleDeleteModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleGetFolderStructureList(currentFolderData?.parent?.data);
                setIsUpdateFolderStructure(true);
                setIsUpdateProjectList(true);
                setOpenDeleteModuleDialog(false);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    // useEffect(() => {
    //     if (activeProject && folderStructureList?.length === 0) {
    //         handleGetFolderStructureList(activeProject);
    //     }
    // }, [activeProject, folderStructureList?.length]);

    useEffect(() => {
        if (activeProject && folderStructureList?.length === 0) {
            handleGetFolderStructureList(activeProject);
        }
    }, [activeProject]);


    useEffect(() => {
        if (isUpdateFolderStructure && activeProject) {
            handleGetFolderStructureList(activeProject);
        }
    }, [isUpdateFolderStructure, activeProject]);

    console.log(isUpdateProjectList, " isUpdateProjectList")

    return (
        <Box className="flex flex-col gap-0 w-full h-full">

            <Box className="w-full h-[8vh] flex items-center justify-center ">
                <ApplicationLogo />

            </Box>

            <Box className="flex items-center px-4 py-4 gap-2 border-b">
                <Avatar variant="rounded" sx={{ background: theme.palette.highlight.main, color : theme.palette.text.primary }}>
                    <FolderSpecialOutlined />
                </Avatar>
                <Box className="flex flex-col flex-1 " >
                    <Typography fontSize={font.size.fs_4} sx={{color : theme.palette.text.primary}}>{activeProject?.name}</Typography>
                    <Typography fontSize={font.size.fs_5} sx={{color : theme.palette.text.secondary}}>{"Project"}</Typography>
                </Box>
                <IconButton>
                    <Settings />
                </IconButton>
            </Box>

            {/* folder structure */}
            <Box className="flex-1 flex py-2">

                {/* folder actions */}
                {false && <Box className="w-full min-h-[8%] max-h-[8%]  flex items-center justify-between py-2 border-b sticky top-0 z-10 backdrop-blur-md">
                    {/* <IconButton >
                        <FolderOutlined />
                    </IconButton> */}

                    {toggleFolderSidebar && <Box className="w-full flex items-center justify-between px-4 overflow-x-auto">
                        <LightTooltip title="Create Module" >
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type !== FolderType.project}
                                selected={openCreateModuleDialog}
                                onChange={() => setOpenCreateModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <AddOutlined />
                            </ToggleButton>
                        </LightTooltip>

                        <LightTooltip title="Edit Module">
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type === FolderType.project}
                                selected={openEditModuleDialog}
                                onChange={() => setOpenEditModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <EditOutlined />
                            </ToggleButton>
                        </LightTooltip>

                        <LightTooltip title="Delete Module">
                            <ToggleButton
                                value="check"
                                size="small"
                                disabled={currentFolderData?.data?.type === FolderType.project}
                                selected={openDeleteModuleDialog}
                                onChange={() => setOpenDeleteModuleDialog((prevSelected) => !prevSelected)}
                            >
                                <DeleteOutline />
                            </ToggleButton>
                        </LightTooltip>

                        {/* <LightTooltip title="Hide Sidebar">
                            <ToggleButton
                                value="check"
                                size="small"
                                color="secondary"
                                selected={toggleFolderSidebar}
                                onChange={handleToggleSidebar}
                            >
                                <MenuOpen />
                            </ToggleButton>
                        </LightTooltip> */}

                    </Box>
                    }
                    {/* <SidebarToggleButton /> */}
                </Box>}

                {/* folder list */}
                <Box className=" flex w-full h-full  ">
                    {
                        (loading) ?
                            <Box className=" flex flex-col items-center  gap-2 w-full h-full px-4 ">
                                {/* <CircularProgress />
                                <Typography>Loading...</Typography> */}
                                {
                                    Array.from({ length: 15 }).map((item, index) => <Skeleton key={index} variant="rounded" animation="pulse" width={"100%"} height={30} />)
                                }
                            </Box>
                            :
                            (
                                <Box className=" flex w-full h-full ">
                                    <Treeview dataList={folderStructureList} />
                                </Box>
                            )
                    }
                </Box>

            </Box>

            {/* help & support */}
            <Box className="flex items-center gap-1 px-4 py-2">
                <HelpOutline />
                <Typography>Help & Support</Typography>
            </Box>

            {/* user profile */}
            {false && <Box className="flex items-center justify-center gap-2 w-full py-3 px-4 border-t">
                <Avatar variant="rounded" sx={{ background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
                    {profileDetails?.name?.charAt(0) || "-"}
                </Avatar>
                <Box className="flex-1 w-auto flex flex-col overflow-hidden">
                    <Typography fontSize={font.size.fs_4} className=" overflow-hidden text-ellipsis whitespace-nowrap box-border">{profileDetails?.name}</Typography>
                    <Typography fontSize={font.size.fs_5} className="overflow-hidden text-ellipsis whitespace-nowrap box-border opacity-60">{profileDetails?.role || "Admin"}</Typography>
                </Box>
                <IconButton onClick={() => navigate(ModulePath.settings)}>
                    <Settings />
                </IconButton>
                <IconButton onClick={handleLogout}>
                    <LogoutOutlined />
                </IconButton>
            </Box>}



            {openCreateModuleDialog &&
                <CreateModuleDialog
                    open={openCreateModuleDialog}
                    handleClose={() => setOpenCreateModuleDialog(false)}
                    handleConfirm={handleCreateModule}
                    loading={loading}
                    setLoading={setLoading}
                />}

            {openEditModuleDialog &&
                <EditModuleDialog
                    open={openEditModuleDialog}
                    handleClose={() => setOpenEditModuleDialog(false)}
                    handleConfirm={handleUpdateModule}
                    loading={loading}
                    setLoading={setLoading}
                />}

            {openDeleteModuleDialog &&
                <DeleteModuleDialog
                    open={openDeleteModuleDialog}
                    handleClose={() => setOpenDeleteModuleDialog(false)}
                    handleConfirm={handleDeleteModule}
                    loading={loading}
                    setLoading={setLoading}

                />}




            {/* current folder menu */}
            <Popover
                id="folder-menu"
                open={Boolean(currentFolderAnchorEl)}
                anchorEl={currentFolderAnchorEl}
                onClose={() => setCurrentFolderAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="flex flex-col ">
                    {currentFolderData?.data?.type === FolderType.project &&
                        <MenuItem
                            className="flex items-center gap-2"
                            disabled={currentFolderData?.data?.type !== FolderType.project}
                            onClick={handleOpenAddModule}>
                            <ListItemIcon>
                                <AddTwoTone fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Add Module</ListItemText>
                        </MenuItem>}
                    {currentFolderData?.data?.type !== FolderType.project &&
                        <MenuItem
                            className="flex items-center gap-2"
                            disabled={currentFolderData?.data?.type === FolderType.project}
                            onClick={handleOpenEditModule}>
                            <ListItemIcon>
                                <EditTwoTone fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit Module</ListItemText>
                        </MenuItem>}
                    {currentFolderData?.data?.type !== FolderType.project &&
                        <MenuItem
                            className="flex items-center gap-2"
                            disabled={currentFolderData?.data?.type === FolderType.project}
                            onClick={handleOpenDeleteModule}>
                            <ListItemIcon>
                                <DeleteTwoTone fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete Module</ListItemText>
                        </MenuItem>}
                </Box>
            </Popover>
        </Box>
    )
}

