import { ArrowDropDown, Clear, ClearAll, Close, Delete, DeleteOutline, Description, Edit, FileDownloadOutlined, FileUploadOutlined, Filter, FilterAlt, FilterAltOffOutlined, FilterAltOutlined, FilterOutlined, Folder, FolderOutlined, GridView, ListAlt, More, MoreHoriz, MoreVert, NavigateNext, Pages, PlayArrow, Search } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, CircularProgress, debounce, IconButton, InputAdornment, List, MenuItem, Paper, Select, Skeleton, styled, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, Typography, useMediaQuery, useTheme } from '@mui/material'
import { font } from '../../../styles/font';
import { useEffect, useState } from 'react';
import { purple } from '@mui/material/colors';
import StoryCard from '../../story/StoryCard';
import { useApplicationContext } from '../../../context/ApplicationContext';
import { LightTooltip } from '../../styled/CustomComponent';
import UserStoryList from '../../story/UserStoryList';
import CreateStoryComponent from '../../story/CreateStoryComponent';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Entity_Label, FolderType, ModulePath, Severity, StoryStatus } from '../../../constant/ApplicationVariables';
import { addStory_API, deleteStory_API, getStoryList_API, updateStory_API } from '../../../api/controller/StoryController';
import DeleteStoryDialog from '../../dialog/DeleteProjectItemDialog';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecialOutlined';
import EditStoryComponent from '../../story/EditStoryComponent';
import LinkTagDrawer from '../../drawer/LinkTagDrawer';
import UserStoryGrid from '../../story/UserStoryGrid';

import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import { useLocation } from 'react-router-dom';
import UseCaseGrid from '../../useCase/UseCaseGrid';
import UseCaseList from '../../useCase/UseCaseList';
import EditUseCaseComponent from '../../useCase/EditUseCaseComponent';
import { addUseCase_API, deleteUseCase_API, getUseCaseList_API, updateUseCase_API } from '../../../api/controller/UseCaseController';
import CreateUseCaseComponent from '../../useCase/CreateUseCaseComponent';
import DeleteProjectItemDialog from '../../dialog/DeleteProjectItemDialog';
import FilterUserStoryDrawer from '../../drawer/FilterUserStoryDrawer';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    height: "0.6rem",
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',

    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: "transparent",
    },
});

const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        fontSize: font.size.fs_4,
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        transition: "all 0.3s ease-in-out",
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

class ACTIVE_PROJECT_TABS {
    static dashboard = 0;
    static userStory = 1;
    static useCase = 2;
    static testCase = 3;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    padding: theme.spacing(0.5),


    // '&:hover': {
    //     backgroundColor: theme.palette.secondary.main,
    // },
    // '&.Mui-selected': {
    //     backgroundColor: theme.palette.secondary.main,
    // },

}));



export default function RequirementMainContainer() {
    const {
        openCreateStoryDialog, setOpenCreateStoryDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        currentFolderData, setCurrentFolderData,
        currentUserStoryData, setCurrentUserStoryData,
        userStoryList, setUserStoryList,
        globalLoader, setGlobalLoader,
        handleSnackbar,
        selectedUserStoryList, setSelectedUserStoryList,
        activeProject,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        isGridViewEnabled, setIsGridViewEnabled,
        setIsUpdateFolderStructure,
        openCreateUseCaseDialog, setOpenCreateUseCaseDialog,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openDeleteUseCaseDialog, setOpenDeleteUseCaseDialog,
        currentUseCaseData, setCurrentUseCaseData,
        useCaseList, setUseCaseList,
        selectedUseCaseList, setSelectedUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        isItemChatWindow, setIsItemChatWindow,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;

    const [breadCrumbsList, setBreadCrumbsList] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [tabsList, setTabsList] = useState([
        { label: "View List", value: ACTIVE_PROJECT_TABS.userStory, disabled: false },
        { label: "View List", value: ACTIVE_PROJECT_TABS.useCase, disabled: false },
        { label: "Dashboard", value: ACTIVE_PROJECT_TABS.dashboard, disabled: true },
    ]);


    function handleBreadCrumb() {
        let array = [];

        // when userstory is opened
        if (currentUserStoryData && openEditStoryDialog) {
            array.push(currentUserStoryData)
        }

        // when usecase is opened
        if (currentUseCaseData && openEditUseCaseDialog) {
            array.push(currentUseCaseData)
        }


        let dataObject = currentFolderData;
        array.push(dataObject);

        while (dataObject?.level > 0) {
            dataObject = dataObject?.parent;
            array.push(dataObject);
        }



        // if (dataObject) {
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={projectActive && "bold"}
        //                 key="1"
        //                 // sx={{ opacity: projectActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )
        // }

        // while (dataObject?.level > 0) {
        //     dataObject = dataObject?.parent;
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={moduleActive && "bold"}
        //                 key="2"
        //                 // sx={{ opacity: moduleActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )

        // }

        setBreadCrumbsList(array?.reverse())
    }

    function handleChangeTab(event, newValue) {
        setCurrentTab(newValue);

        if (newValue === 0 && (openCreateStoryDialog || openEditStoryDialog)) {
            setOpenCreateStoryDialog(false);
            setOpenEditStoryDialog(false);
        }
    }

    const handleClickPathItem = (itemData) => {
        if (itemData?.data?.type === FolderType.project || itemData?.data?.type === FolderType.module) {
            setCurrentFolderData(itemData)
        } else {
            return;
        }
    }

    function handleComponent() {

        // switch (currentTab) {
        //     case ACTIVE_PROJECT_TABS.dashboard:
        //         return <DashboardContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.userStory:
        //         return <UserStoryContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.useCase:
        //         return <UseCaseContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.testCase:
        //         return <TestCaseContainerComponent />;
        //     default:
        //         return <DashboardContainerComponent />;
        // }

        switch (pathName) {
            case ModulePath.requirements:
                return <UserStoryContainerComponent />;
            case ModulePath.useCase:
                return <UseCaseContainerComponent />;
            case ModulePath.testCase:
                return <TestCaseContainerComponent />;
            default:
                return <UserStoryContainerComponent />;
        }
    }

    // user story
    const handleGetUserStoryList = async () => {
        try {
            setIsUserStoryLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsUserStoryLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsUserStoryLoading(false);
            }, 1000);
        }
    }

    const handleCreateStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
                "priority": "High",
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name,
            }


            const response = await addStory_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenCreateStoryDialog(false);
            }

            setIsUpdateFolderStructure(true);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleUpdateStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
            }


            const response = await updateStory_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenEditStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleDeleteStory = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.data?.name,
                "projectName": currentFolderData?.parent?.data?.name
            }

            const response = await deleteStory_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        }
    }


    // use case
    const handleGetUseCaseList = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleCreateUseCase = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
                // "priority": "High",
                // "moduleName": currentFolderData?.data?.name,
                // "projectName": currentFolderData?.parent?.data?.name,
            }


            const response = await addUseCase_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUseCaseList();
                setOpenCreateUseCaseDialog(false);
            }

            setIsUpdateFolderStructure(true);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleUpdateUseCase = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                ...storyData,
            }


            const response = await updateUseCase_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUseCaseList();
                setOpenEditStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleDeleteUseCase = async (storyData) => {
        try {
            setGlobalLoader(true);

            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await deleteUseCase_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        }
    }

    // fetch the userstory / usecase list
    useEffect(() => {
        if (pathName === ModulePath.requirements) {
            if (currentFolderData) {
                handleGetUserStoryList();
            } else {
                setUserStoryList([]);
            }
        }

        if (pathName === ModulePath.useCase) {
            if (currentFolderData) {
                handleGetUseCaseList();
            } else {
                setUseCaseList([]);
            }
        }

    }, [currentFolderData?.id, pathName]);

    // close the dialog when page changes
    useEffect(() => {
        setOpenEditStoryDialog(false);
        setOpenEditUseCaseDialog(false);
        setOpenCreateUseCaseDialog(false);
        setOpenCreateStoryDialog(false);
    }, [pathName])



    // use to create the tab list and active tab
    useEffect(() => {

        let foundIndex;

        if (pathName === ModulePath.requirements) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.userStory);
            setCurrentTab(ACTIVE_PROJECT_TABS.userStory);
        }

        if (pathName === ModulePath.useCase) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.useCase);
            setCurrentTab(ACTIVE_PROJECT_TABS.useCase);
        }

        if (pathName === ModulePath.testCase) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.testCase);
            setCurrentTab(ACTIVE_PROJECT_TABS.testCase);
        }

        if (foundIndex > -1) {
            const newTabsList = [...tabsList];
            let dashboardIndex = newTabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.dashboard);

            newTabsList[dashboardIndex].disabled = false;
            newTabsList[foundIndex].disabled = false;

            newTabsList.forEach((tab, index) => {

                if (index !== foundIndex && index !== dashboardIndex) {
                    tab.disabled = true;
                }
            });

            setTabsList(newTabsList);
            // setCurrentTab(ACTIVE_PROJECT_TABS.dashboard);
        }

    }, [pathName]);




    console.log(pathName, "pathNamepathName")
    console.log(breadCrumbsList, "node1breadcrumbs")
    console.log(selectedUserStoryList, "node1selectedUserStoryList");
    console.log(useCaseList, "useCaseList")

    useEffect(() => {
        handleBreadCrumb();
    }, [currentFolderData, pathName, openEditStoryDialog, openEditUseCaseDialog]);

    return (
        <Box className="flex flex-col gap-2 w-full h-full py-4 ">

            {/* header */}
            <Box className="w-full h-fit flex flex-col py-0  gap-4 " bgcolor={theme.palette.primary.layout_col} >

                {/* upper container - path */}
                <Box className="flex w-full h-fit items-center   border-b-gray-700 justify-between gap-2" >
                    <Box
                        className="flex "
                        sx={{
                            // border: `1px solid ${theme.palette.primary.light}`
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {
                                breadCrumbsList?.map((item, index) => {
                                    return (
                                        <LightTooltip
                                            key={index}
                                            title={
                                                <Typography fontSize={font.size.fs_5} className="capitalize">
                                                    {` 
                                                     ${(item?.data?.type === FolderType.project || item?.data?.type === FolderType.module)
                                                            ? `${item?.data?.type}: ${item?.data?.name}`
                                                            : `${pathName === ModulePath.requirements ? "User Story" : "Use Case"}: #${item?.customId}`}
                                                    `}
                                                </Typography>
                                            }
                                            placement="bottom"
                                        >
                                            <Box key={index} className="flex items-center gap-1" onClick={() => { handleClickPathItem(item) }}>
                                                {
                                                    ((currentUserStoryData && currentUserStoryData?.customId === item?.customId) || (currentUseCaseData && currentUseCaseData?.customId === item?.customId)) ?
                                                        (
                                                            <Box className="flex items-center gap-1" sx={{ color: theme.palette.secondary.main }}>
                                                                <Description sx={{ fontSize: font.size.fs_4, color: "inherit" }} />
                                                                <Typography
                                                                    fontSize={font.size.fs_5}
                                                                    // className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                                    key={index + 1}
                                                                    // fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                                    // color="primary"
                                                                    sx={{ color: "inherit" }}
                                                                    onClick={() => { }}>
                                                                    #{item?.customId}
                                                                </Typography>
                                                            </Box>
                                                        )
                                                        :
                                                        (
                                                            <Box className="flex items-center gap-1" sx={{ color: (breadCrumbsList?.length - 1 === index) ? theme.palette.text.primary : theme.palette.text.secondary }}>
                                                                {item?.data?.type === FolderType.project &&
                                                                    <FolderSpecialIcon
                                                                        sx={{ fontSize: font.size.fs_4, }}
                                                                    />}

                                                                {item?.data?.type === FolderType.module &&
                                                                    <FolderOutlined
                                                                        sx={{ fontSize: font.size.fs_4 }}
                                                                    />}

                                                                <Typography
                                                                    fontSize={font.size.fs_5}
                                                                    // className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                                    key={index + 1}
                                                                    // fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                                    // color="primary"
                                                                    sx={{ color: "inherit" }}
                                                                    // color={(breadCrumbsList?.length - 1 === index) && theme.palette.secondary.main}
                                                                    // sx={{ opacity: breadCrumbsList?.length - 1 === index ? 1 : 0.8 }}
                                                                    onClick={() => { }}>
                                                                    {item?.data?.name}
                                                                </Typography>
                                                            </Box>
                                                        )


                                                }

                                            </Box>
                                        </LightTooltip>
                                    )
                                })
                            }
                        </Breadcrumbs>
                    </Box>

                </Box>

                {/* lower container - viewlist, dashboard tabs and grid, list view / removed from UI */}
                {false && <Box className="w-full h-fit flex items-center justify-between gap-4 border border-gray-600">
                    {/* <Box
                        className="flex flex-1 items-end h-full"
                        sx={{
                            // border: `1px solid ${theme.palette.primary.light}`
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {
                                breadCrumbsList?.map((item, index) => {
                                    return (
                                        <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${item?.data?.type}: ${item?.data?.name}`}</Typography>}>
                                            <Box className="flex items-center gap-1" onClick={() => { setCurrentFolderData(item) }}>
                                                {item?.data?.type === FolderType.project &&
                                                    <FolderSpecialIcon
                                                        // color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"}
                                                        sx={{ fontSize: font.size.fs_3, }}
                                                    />}

                                                {item?.data?.type === FolderType.module &&
                                                    <FolderOutlined
                                                        // color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"}
                                                        sx={{ fontSize: font.size.fs_3 }}
                                                    />}
                                                <Typography
                                                    fontSize={font.size.fs_5}
                                                    className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                    key={index + 1}
                                                    fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                    color="primary"
                                                    // color={(breadCrumbsList?.length - 1 === index) && theme.palette.secondary.main}
                                                    // sx={{ opacity: breadCrumbsList?.length - 1 === index ? 1 : 0.8 }}
                                                    onClick={() => { }}>
                                                    {item?.data?.name}
                                                </Typography>
                                            </Box>
                                        </LightTooltip>
                                    )
                                })
                            }
                        </Breadcrumbs>
                    </Box> */}

                    <Box className=" flex items-center justify-center">
                        {/* <StyledTabs
                        value={currentTab}
                        onChange={(event, newValue) => handleChangeTab(event, newValue)}
                        sx={{ height: '100%' }}
                    >
                        <StyledTab value={0} label="Dashboard" />
                        <StyledTab value={1} label="User Story" />
                    </StyledTabs> */}
                        {
                            tabsList?.filter((item) => !item?.disabled).map((item, index) => {
                                return (
                                    <Button
                                        variant={item?.value === currentTab ? "text" : "text"}
                                        size="medium"
                                        key={index}
                                        onClick={(e) => handleChangeTab(e, item?.value)}
                                        sx={{
                                            px: 1,
                                            borderRadius: 0,
                                            borderBottom: item?.value === currentTab ? `2px solid ${theme.palette.primary.main}` : `2px solid transparent`,
                                            // background: item?.value === currentTab ? theme.palette.primary.main : "transparent",
                                            // color: item?.value === currentTab ? theme.palette.primary.contrastText : theme.palette.primary.main,

                                            opacity: item?.value === currentTab ? 1 : 0.6,
                                            // "&:hover" : { background: theme.palette.secondary.main}
                                        }}
                                    >
                                        {item?.label}
                                    </Button>
                                )
                            })
                        }
                    </Box>


                    {!openCreateStoryDialog &&
                        <Box className=" flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>}
                </Box>}

            </Box>

            {/* body */}
            <Box className="h-[100%]  flex flex-col w-full relative overflow-hidden">
                {handleComponent()}


                {/* dialog */}
                {openCreateStoryDialog &&
                    <CreateStoryComponent
                        open={openCreateStoryDialog}
                        closeDialog={() => setOpenCreateStoryDialog(false)}
                    />}

                {openEditStoryDialog && currentUserStoryData &&
                    <EditStoryComponent
                        open={openEditStoryDialog}
                        closeDialog={() => { setOpenEditStoryDialog(false); setIsItemChatWindow(false); setCurrentUserStoryData(null) }}
                    />}

                {openDeleteStoryDialog &&
                    <DeleteProjectItemDialog
                        open={openDeleteStoryDialog}
                        handleClose={() => { setOpenDeleteStoryDialog(false); setCurrentUserStoryData(null) }}
                    />}

                {openLinkTagDrawer && (currentUserStoryData || currentUseCaseData) &&
                    <LinkTagDrawer
                        open={openLinkTagDrawer}
                        handleClose={() => setOpenLinkTagDrawer(false)}
                    />}

                {openCreateUseCaseDialog &&
                    <CreateUseCaseComponent
                        open={openCreateUseCaseDialog}
                        closeDialog={() => setOpenCreateUseCaseDialog()}
                    />
                }

                {openEditUseCaseDialog && currentUseCaseData &&
                    <EditUseCaseComponent
                        open={openEditUseCaseDialog}
                        closeDialog={() => { setOpenEditUseCaseDialog(false); setIsItemChatWindow(false); setCurrentUseCaseData(null) }}
                    />}

                {openDeleteUseCaseDialog &&
                    <DeleteProjectItemDialog
                        open={openDeleteUseCaseDialog}
                        handleClose={() => { setOpenDeleteUseCaseDialog(false); setCurrentUseCaseData(null) }}
                    />
                }

            </Box>
        </Box>
    )
}




function UserStoryContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, handleSnackbar, currentFolderData, setCurrentFolderData, userStoryList, setUserStoryList, currentUserStoryData, setCurrentUserStoryData, isShowStoryFilter, setIsShowStoryFilter, selectedUserStoryList, setSelectedUserStoryList, isUserStoryLoading, setIsUserStoryLoading, filteredUserStoryList, setFilteredUserStoryList, openDeleteStoryDialog, setOpenDeleteStoryDialog, openCreateStoryDialog } = useApplicationContext();
    const theme = useTheme();
    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    // const [filteredList, setFilteredList] = useState([]);
    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };


    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        const userInput = e.target.value;

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            setSearchText(userInput);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteStoryDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }


    useEffect(() => {
        let filteredList = userStoryList;

        if (searchText) {
            filteredList = userStoryList?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.description?.toLowerCase()?.includes(searchText.toLowerCase())));
        }

        setFilteredUserStoryList(filteredList);
    }, [searchText, userStoryList, setFilteredUserStoryList]);

    useEffect(() => {
        let list = userStoryList;

        setIsLoading(true);

        if (activeFilterData?.status) {
            list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
        }

        setFilteredUserStoryList(list);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);

        console.log(list, "filteredList")

    }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isUserStoryLoading, setIsUserStoryLoading]);

    console.log(isTabletScreen, "isTabletScreen");

    return (
        <Box className="flex flex-col gap-6 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="flex flex-col gap-2 ">
                {/* filter list, grid/list view */}
                {false && <Box className="h-auto w-full flex items-center justify-between gap-2  ">

                    {/* filters list */}
                    <Box className="min-h-fit w-[86.5%] flex items-center gap-2 overflow-x-auto  "
                        sx={{ 'scrollbarWidth': 'none', '&::-webkit-scrollbar': { width: 0, background: 'transparent' } }}
                    >

                        {/* status filter */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Status</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* created by */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created By</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                disabled
                            // onChange={handleChangeFilter}
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* created at */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created At</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                                disabled
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* assigned to */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Assigned To</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                                disabled
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                    </Box>


                </Box>}

                {/* search and filter */}
                <Box className={`flex items-center justify-between gap-2 h-fit `}>

                    <Box className="flex items-center justify-between gap-2  ">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            className="max-w-72"
                            disabled={userStoryList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                    </InputAdornment>,
                                }
                            }}
                            defaultValue={searchText}
                            onChange={handleChangeSearch}
                        />

                        <FilterUserStoryDrawer />

                    </Box>



                    <Box className="flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`Delete`}>
                                <IconButton disabled={currentFolderData?.data?.type !== FolderType.module || selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Upload`}>
                                <IconButton  >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Download`}>
                                <IconButton  >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

            </Box>

            {/* user story */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `}>
                {isLoading || isUserStoryLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    filteredUserStoryList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {Entity_Label.userStory}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full  ${isGridViewEnabled ? "" : " "}  `}>
                                {isGridViewEnabled ?
                                    <UserStoryGrid dataList={filteredUserStoryList} isLoading={isUserStoryLoading} />
                                    : <UserStoryList dataList={filteredUserStoryList} isLoading={isUserStoryLoading} />
                                }
                            </Box>
                        )
                }

            </Box>


        </Box>
    )
}

function UseCaseContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, handleSnackbar, currentFolderData, setCurrentFolderData, userStoryList, setUserStoryList, currentUserStoryData, setCurrentUserStoryData, isShowStoryFilter, setIsShowStoryFilter, selectedUserStoryList, setSelectedUserStoryList, isUserStoryLoading, setIsUserStoryLoading, filteredUserStoryList, setFilteredUserStoryList, openDeleteStoryDialog, setOpenDeleteStoryDialog, openCreateStoryDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenDeleteUseCaseDialog
    } = useApplicationContext();
    const theme = useTheme();
    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    // const [filteredList, setFilteredList] = useState([]);
    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        const userInput = e.target.value;

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            setSearchText(userInput);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteUseCaseDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }


    useEffect(() => {
        let filteredList = useCaseList;

        if (searchText) {
            filteredList = useCaseList?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
        }

        setFilteredUseCaseList(filteredList);
    }, [searchText, useCaseList, setFilteredUseCaseList]);

    useEffect(() => {
        let list = useCaseList;

        setIsLoading(true);

        if (activeFilterData?.status) {
            list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
        }

        setFilteredUseCaseList(list);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);

        console.log(list, "filteredList")

    }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading]);

    console.log(isTabletScreen, "isTabletScreen");

    return (
        <Box className="flex flex-col gap-6 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="flex flex-col gap-2 ">
                {/* filter list, grid/list view */}
                {false && <Box className="h-auto w-full flex items-center justify-between gap-2  ">

                    {/* filters list */}
                    <Box className="min-h-fit w-[86.5%] flex items-center gap-2 overflow-x-auto  "
                        sx={{ 'scrollbarWidth': 'none', '&::-webkit-scrollbar': { width: 0, background: 'transparent' } }}
                    >

                        {/* status filter */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Status</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* created by */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created By</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                disabled
                            // onChange={handleChangeFilter}
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* created at */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created At</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                                disabled
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>

                        {/* assigned to */}
                        <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                            <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Assigned To</Typography>
                            <Select
                                size="small"
                                className="w-36"
                                // variant="standard"
                                value={(activeFilterData && activeFilterData?.status) || ""}
                                displayEmpty
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                                name="status"
                                onChange={handleChangeFilter}
                                disabled
                            >
                                {
                                    ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                                {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                                {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                    </Box>


                </Box>}

                {/* search and filter */}
                <Box className={`flex items-center justify-between gap-2 h-fit `}>

                    <Box className="flex items-center justify-between gap-2  ">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            className="max-w-72"
                            disabled={useCaseList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                    </InputAdornment>,
                                }
                            }}
                            defaultValue={searchText}
                            onChange={handleChangeSearch}
                        />

                        <FilterUserStoryDrawer />

                    </Box>



                    <Box className="flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`Delete`}>
                                <IconButton disabled={currentFolderData?.data?.type !== FolderType.module || selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Upload`}>
                                <IconButton  >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Download`}>
                                <IconButton  >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

            </Box>

            {/* user story */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `}>
                {isLoading || isActiveProjectContainerLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    filteredUseCaseList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {Entity_Label.useCase}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full    `}>
                                {isGridViewEnabled ?
                                    <UseCaseGrid dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                    : <UseCaseList dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                }
                            </Box>
                        )
                }

            </Box>


        </Box>
    )
}


function TestCaseContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, handleSnackbar, currentFolderData, setCurrentFolderData, userStoryList, setUserStoryList, currentUserStoryData, setCurrentUserStoryData, isShowStoryFilter, setIsShowStoryFilter, selectedUserStoryList, setSelectedUserStoryList, isUserStoryLoading, setIsUserStoryLoading, filteredUserStoryList, setFilteredUserStoryList, openDeleteStoryDialog, setOpenDeleteStoryDialog, openCreateStoryDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenDeleteUseCaseDialog
    } = useApplicationContext();
    const theme = useTheme();
    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    // const [filteredList, setFilteredList] = useState([]);
    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        const userInput = e.target.value;

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            setSearchText(userInput);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteUseCaseDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }


    useEffect(() => {
        let filteredList = useCaseList;

        if (searchText) {
            filteredList = useCaseList?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
        }

        setFilteredUseCaseList(filteredList);
    }, [searchText, useCaseList, setFilteredUseCaseList]);

    useEffect(() => {
        let list = userStoryList;

        setIsLoading(true);

        if (activeFilterData?.status) {
            list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
        }

        setFilteredUserStoryList(list);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);

        console.log(list, "filteredList")

    }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading]);

    console.log(isTabletScreen, "isTabletScreen");

    return (
        <Box className="flex flex-col gap-6 w-full grow ">

            {/* filter list */}
            <Box className="h-10 w-full flex items-center gap-4 overflow-x-auto "
                sx={{ 'scrollbarWidth': 'none', '&::-webkit-scrollbar': { width: 0, background: 'transparent' } }}
            >

                {/* status filter */}
                <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                    <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Status</Typography>
                    <Select
                        size="small"
                        className="w-36"
                        // variant="standard"
                        value={(activeFilterData && activeFilterData?.status) || ""}
                        displayEmpty
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                        name="status"
                        onChange={handleChangeFilter}
                    >
                        {
                            ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                        {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                        {item}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>

                {/* created by */}
                <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                    <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created By</Typography>
                    <Select
                        size="small"
                        className="w-36"
                        // variant="standard"
                        value={(activeFilterData && activeFilterData?.status) || ""}
                        displayEmpty
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                        name="status"
                        disabled
                    // onChange={handleChangeFilter}
                    >
                        {
                            ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                        {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                        {item}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>

                {/* created at */}
                <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                    <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Created At</Typography>
                    <Select
                        size="small"
                        className="w-36"
                        // variant="standard"
                        value={(activeFilterData && activeFilterData?.status) || ""}
                        displayEmpty
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                        name="status"
                        onChange={handleChangeFilter}
                        disabled
                    >
                        {
                            ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                        {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                        {item}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>

                {/* assigned to */}
                <Box className="flex items-center gap-1 px-2  h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.common.white}>
                    <Typography fontSize={font.size.fs_4} className="whitespace-nowrap">Assigned To</Typography>
                    <Select
                        size="small"
                        className="w-36"
                        // variant="standard"
                        value={(activeFilterData && activeFilterData?.status) || ""}
                        displayEmpty
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        renderValue={(selected) => <Typography fontSize={font.size.fs_4} className="opacity-80">{selected}</Typography>}
                        name="status"
                        onChange={handleChangeFilter}
                        disabled
                    >
                        {
                            ["All", StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} className="flex items-center justify-center" >
                                        {/* <StatusBadge status={item?.toLowerCase()} /> */}
                                        {item}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>


            </Box>

            {/* search and filter */}
            <Box className={`flex   h-10 `}>
                {isShowStoryFilter &&
                    (false ? (
                        <Box className="flex items-center gap-2 py-1 ">
                            <Skeleton variant="rounded" width={"100%"} height={42} animation="wave" className="max-w-56" />
                            <Skeleton variant="rounded" width={"100%"} height={42} animation="wave" className="flex-1" />
                        </Box>
                    ) : (
                        <Box className="w-full flex items-center justify-between gap-2 py-0 ">

                            <TextField
                                fullWidth
                                placeholder="Search Keyword"
                                color="white"
                                disabled={userStoryList?.length === 0}
                                // size="medium"
                                sx={{
                                    // py: "6px",
                                    bgcolor: theme.palette.common.white,
                                    borderRadius: theme.shape.borderRadius,
                                    px: "4px",

                                    '& .MuiInputBase-input': {
                                        // boxSizing: 'border-box',
                                        border: "none",
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                slotProps={{
                                    input: {
                                        startAdornment: <InputAdornment position="center">
                                            <Search />
                                        </InputAdornment>,
                                        endAdornment: <InputAdornment position="center">
                                            {isLoading && <CircularProgress size={22} sx={{}} />}
                                        </InputAdornment>,
                                    }
                                }}
                                defaultValue={searchText}
                                onChange={handleChangeSearch}
                            />

                            {!openCreateStoryDialog && <Box className="flex items-center gap-2  ">

                                <LightTooltip title={`Delete`}>
                                    <IconButton disabled={currentFolderData?.data?.type !== FolderType.module || selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                        <DeleteOutline />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Upload`}>
                                    <IconButton  >
                                        <FileUploadOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Download`}>
                                    <IconButton  >
                                        <FileDownloadOutlined />
                                    </IconButton>
                                </LightTooltip>

                                {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
                                    <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
                                        {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
                                    </IconButton>
                                </LightTooltip> */}
                            </Box>}

                        </Box>
                    ))}
            </Box>

            {/* user story */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-[calc(100%-14.5rem)]" : "h-full"}  overflow-y-auto `}>
                {isLoading || isActiveProjectContainerLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        {true ?
                            <Box className="flex flex-col items-center justify-center gap-2">
                                <CircularProgress />
                                <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                            </Box>
                            :
                            Array.from({ length: 10 }).map((item, index) => (
                                <Skeleton key={index} variant="rounded" width={"100%"} height={31} animation="pulse" className="" />
                            ))
                        }
                    </Box>
                )
                    :
                    filteredUseCaseList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}>No Usecase</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full  ${isGridViewEnabled ? "" : "h-full "} overflow-hidden `}>
                                {isGridViewEnabled ?
                                    <UseCaseGrid dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                    : <UseCaseList dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                }
                            </Box>
                        )
                }

            </Box>

        </Box>
    )
}


function DashboardContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, } = useApplicationContext();
    const theme = useTheme();

    let dashboardDataList = [{
        name: "Modules",
        count: 6
    },
    {
        name: "Stories",
        count: 10
    }];

    return (
        <Box className="flex flex-col w-full h-full">

            <Box className="flex flex-wrap gap-4 w-full h-full ">
                {dashboardDataList?.map((data, index) => (
                    <Box key={index} className="flex flex-col rounded-xl px-4 py-4 w-56 h-56 border" bgcolor={theme.palette.common.white}>
                        <Box key={index} className="flex-1 flex items-center justify-center" >
                            <Typography fontSize={font.size.fs_1} >{data?.count}</Typography>
                        </Box>
                        <Typography fontSize={font.size.fs_5} className="opacity-90">{data?.name}</Typography>
                    </Box>
                ))}
            </Box>

        </Box>
    )
}

