
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import useAxios, { axiosInstance } from "../useAxios/useAxios";

// const axiosInstance = axiosInstance;

export const getProjectList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.projects,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}
 
export const getProjectCategoryList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.category,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getProjectSubcategoryList_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.category + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const addProject_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.projects,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getProjectStatus_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.projectStatus,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateProject_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.projects,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const deleteProject_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.projects + `?${query}`,
            method: "DELETE",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}
