// import { PUBLIC_URL } from "../../../next-env";


let PUBLIC_URL = {
  url: "http://localhost:5000"
}

const isProductionMode = false;

export class APP_CONFIG {
  static appName = "Floxpad";
  static appVersion = "0.0.4";
}

export class API_CONFIG {
  static minute = 4;
  
  static timeout = this.minute * 60 * 1000; // 5 minutes
}

export class HOST_URL {
  static base_url = isProductionMode ? PUBLIC_URL.url : "https://floxpad.com/api";

  static host_url = isProductionMode
    ? `${this.base_url}/new/api`
    : `${this.base_url}`;
}
 
export class BASE_URL {
  static base_url = "";
}

export class MAIN_URL {
  static url = HOST_URL.host_url + BASE_URL.base_url;
}

export class REQUEST_URL {
  static login = "/authenticate";
  static category = "/categories";
  static projects = "/projects";
  static projectStatus = "/projects/status";
  static modules = "/modules";
  static folderStructure = "/modules/explorer";
  static userStory = "/userstory";
  static userStoryPriority = "/userstory/priority";
  static userStoryStatus = "/userstory/status";
  static userStoryList = "/userstory/list";
  static userStoryLink = "/userstory/link";
  static userStoryCustomId = "/userstory/fetch_customId";
  static useCase = "/usecase";
  static useCaseList = "/usecase/list";
  static useCaseLink = "/usecase/link";
  static useCaseCustomId = "/usecase/fetch_customId";
  static aiChat = "/ai";
  static aiChatConversation = "/ai/conversation";
  static aiChatSave = "/ai/save";
  static aiChatGenerate = "/ai/generate";
  static aiChatEntity = "/ai/entity";
}

export class ModulePath {
  static login = "/login"
  static home = "/home";
  static story = "/story";
  static marketplace = "/marketplace";
  static execution = "/execution";
  static settings = "/settings";
  
  static projects = "/project";
  static programmes = "/programme";
  static departments = "/department";
  static organisations = "/organisation";
  static requirements = "/userstory";
  static useCase = "/usecase";
  static testCase = "/testcase";
  static planning = "/planning";
  static dashboard = "/dashboard";
}

export class Severity {
  static success = "success";
  static info = "info";
  static warning = "warning";
  static error = "error";
}

export class AI_Entity {
  static module = "Modules";
  static userStory = "UserStory";
  static useCase = "UseCase";
  static testCase = "TestCase";
}

export class AI_Response_SentBy {
  static user = "USER";
  static model = "FLOXPAD";
}

export class FolderType {
  static module = "module";
  static project = "project";
}

export class StoryStatus {
  static pending = "Pending";
  static inprogress = "Inprogress";
  static completed = "Completed";
}

export class Entity_Label {
  static module = "Module";
  static userStory = "User Story";
  static useCase = "Use Case";
  static testCase = "Test Case";
}
