import { Avatar, Box, Button, CircularProgress, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, Popover, Tab, Tabs, TextField, ToggleButton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ApplicationNavigation from './ApplicationNavigation'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useApplicationContext } from '../../context/ApplicationContext';
import { AccountCircleOutlined, ArrowRightAlt, AutoAwesomeOutlined, HomeOutlined, HomeTwoTone, LogoutOutlined, MenuOpen, Search, SearchTwoTone, Settings, Style } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { useLocation, useNavigate } from 'react-router-dom';
import { Entity_Label, FolderType, ModulePath } from '../../constant/ApplicationVariables';
import { font } from '../../styles/font';
import { LightTooltip } from '../styled/CustomComponent';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import ApplicationLogo from './ApplicationLogo';

export default function ApplicationHeader() {
    const {
        activePage, setActivePage,
        headerSearchText, setHeaderSearchText,
        profileDetails,
        toggleFolderSidebar, setToggleFolderSidebar,
        toggleChatWindow, setToggleChatWindow,
        setActiveProject,
        setFolderStructureList,
        setUserStoryList,
        currentFolderData, setCurrentFolderData,
        handleLogout,
        isItemChatWindow, setIsItemChatWindow,
        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
        openCreateStoryDialog, setOpenCreateStoryDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        openCreateUseCaseDialog, setOpenCreateUseCaseDialog,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openDeleteUseCaseDialog, setOpenDeleteUseCaseDialog,
        getAILabel
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activePageTab, setActivePageTab] = useState(ModulePath.requirements);
    let [headerPagesList, setHeaderPagesList] = useState([]);

    const [profileAnchorEl, setProfileAnchorEl] = useState(null);

    const handleClickProfilePopup = (event) => {
        if (profileAnchorEl) {
            setProfileAnchorEl(null);
        } else {
            setProfileAnchorEl(event.currentTarget);
        }
    };

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        setLoading(true);
        setIsLoading(true)

        timer = setTimeout(() => {
            setHeaderSearchText(e.target.value);
            setLoading(false);
            setIsLoading(false)
        }, 1100);
    }


    const getAIButtonText = () => {
        if (toggleChatWindow) {
            return "Close AI Assistant";
        } else {
            return getAILabel()
        }
    }

    const handleHomeClick = () => {
        navigate(ModulePath.projects);
        setActiveProject(null);
        localStorage.removeItem("activeProject");
        setFolderStructureList([]);
        setUserStoryList([]);
        setCurrentFolderData(null);

        // to close the project item dialog
        setOpenEditStoryDialog(false);
        setOpenCreateStoryDialog(false);
        setOpenDeleteStoryDialog(false);
        setOpenEditUseCaseDialog(false);
        setOpenCreateUseCaseDialog(false);
        setOpenDeleteUseCaseDialog(false);
    }

    // const getAILabel = () => {
    //     switch (currentFolderData?.data?.type) {
    //         case FolderType.project: return `AI assistance for: 
    //             ${isItemChatWindow
    //                 ? (pathName === ModulePath.requirements ? Entity_Label.useCase : Entity_Label.testCase)
    //                 : pathName === ModulePath.requirements ? Entity_Label.module : Entity_Label.useCase}`;
    //         case FolderType.module: return `AI assistance for: 
    //             ${isItemChatWindow
    //                 ? (pathName === ModulePath.requirements ? Entity_Label.useCase : Entity_Label.testCase)
    //                 : pathName === ModulePath.requirements ? Entity_Label.userStory : Entity_Label.useCase}`;
    //         default: return "using AI";
    //     }
    // }

    // To create the navigation pages
    useEffect(() => {
        let array = [];

        setCurrentUserStoryData(null);
        setCurrentUseCaseData(null);
        setIsItemChatWindow(false);

        setActivePageTab(pathName);

        if (pathName === ModulePath.projects) {
            array = [
                {
                    name: `${ModulePath.projects?.replace("/", "")}`,
                    path: ModulePath.projects,
                    onClick: () => navigate(ModulePath.projects),
                    style: {}
                },
                {
                    name: `${ModulePath.programmes?.replace("/", "")}`,
                    path: ModulePath.programmes,
                    onClick: () => navigate(ModulePath.programmes),
                    style: {}

                },
                // {
                //     name: `${ModulePath.departments?.replace("/", "")}`,
                //     path: ModulePath.departments,
                //     onClick: () => navigate(ModulePath.departments),
                //     style: {}

                // },
                {
                    name: `${ModulePath.organisations?.replace("/", "")}`,
                    path: ModulePath.organisations,
                    onClick: () => navigate(ModulePath.organisations),
                    style: {}

                },
            ]
        }


        else {
            array = [{
                name: "User Story",
                path: ModulePath.requirements,
                onClick: () => navigate(ModulePath.requirements),
                style: {}
            },
            {
                name: "Use Case",
                path: ModulePath.useCase,
                onClick: () => navigate(ModulePath.useCase),
                style: {}

            },
            {
                name: "Test Case",
                path: ModulePath.testCase,
                onClick: () => navigate(ModulePath.testCase),
                style: {}

            },
            {
                name: "Planning",
                path: ModulePath.planning,
                onClick: () => navigate(ModulePath.planning),
                style: {}

            },
            {
                name: "Execution",
                path: ModulePath.execution,
                onClick: () => navigate(ModulePath.execution),
                style: {}

            },
            {
                name: "Dashboard",
                path: ModulePath.dashboard,
                onClick: () => navigate(ModulePath.dashboard),
                style: {}

            },
            ]
        }

        setHeaderPagesList(array);

    }, [pathName])

    useEffect(() => {

    })

    console.log(headerSearchText, "headerSearchText");
    console.log(headerPagesList, "headerpageslist", pathName, ModulePath.requirements)

    return (
        <Box className="h-full w-full flex flex-col gap-4" >

            {/* main container */}
            <Box className="h-full w-full flex items-center gap-4 px-0">


                {pathName === ModulePath.projects && <Box className="w-fit">
                    <ApplicationLogo />
                </Box>}

                {pathName !== `${(ModulePath.projects)}` &&
                    <LightTooltip title={`${toggleFolderSidebar ? "Hide Sidebar" : "Show Sidebar"}`}>
                        <ToggleButton
                            value="check"
                            size="small"
                            selected={!toggleFolderSidebar}
                            onChange={() => setToggleFolderSidebar((prevSelected) => !prevSelected)}
                        >
                            <MenuOpen sx={{ transform: !toggleFolderSidebar && "rotate(180deg)" }} />
                        </ToggleButton>
                    </LightTooltip>}

                {pathName !== `${(ModulePath.projects)}` &&
                    <LightTooltip title={`Back to Projects`}>
                        <ToggleButton
                            value="check"
                            size="small"
                            selected={true}
                            onChange={handleHomeClick}
                        >
                            <HomeOutlined />
                        </ToggleButton>
                    </LightTooltip>}

                {/* pages */}
                <Box className="flex-1 h-fit flex items-center gap-0 overflow-x-auto" sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none', "&::-webkit-scrollbar": { display: 'none' } }}>
                    {
                        headerPagesList?.map((page, index) => {
                            return (
                                <Button
                                    variant={page?.path === activePageTab ? "outlined" : "text"}
                                    size="medium"

                                    className='min-w-fit'
                                    // color={page?.path === activePageTab ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText}
                                    key={index}
                                    onClick={page?.onClick}
                                    sx={{
                                        ...page?.style,
                                        minWidth: "fit-content",
                                        px: page?.path === activePageTab ? 1 : 1,
                                        // background: page?.path === activePageTab ? theme.palette.primary.main : "transparent",
                                        // color: page?.path === activePageTab ? theme.palette.primary.contrastText : theme.palette.primary.main
                                    }}
                                >
                                    {page?.name}
                                </Button>
                            )
                        })
                    }


                </Box>

                {/* search container */}
                <Box className="h-full w-fit py-1 max-w-[24vw] flex items-center">
                    <TextField
                        fullWidth
                        placeholder="Search"
                        color="white"
                        size="medium"
                        sx={{
                            py: "6px",
                            borderRadius: theme.shape.borderRadius,
                            background: theme.palette.background.custom,
                            px: "4px",

                            '& .MuiInputBase-input': {
                                boxSizing: 'border-box',
                                border: "none",
                                // background: theme.palette.secondary.light,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="center">
                                    <Search />
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="center">
                                    {loading && <CircularProgress size={22} color="inherit" />}
                                </InputAdornment>,
                            }
                        }}
                        // value={headerSearchText}
                        onChange={handleChangeSearch}
                    />
                </Box>

                {/* AI icon */}
                {pathName !== `${(ModulePath.projects)}` &&
                    <LightTooltip title={`${getAIButtonText()}`}>
                        <ToggleButton
                            value="check"
                            size="small"
                            selected={!toggleChatWindow}
                            onChange={() => setToggleChatWindow((prevSelected) => !prevSelected)}
                            sx={{
                                "&.MuiToggleButton-root": {
                                    background: !toggleChatWindow && theme.palette.secondary.main,
                                    color: !toggleChatWindow && theme.palette.secondary.contrastText,
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        background: !toggleChatWindow && theme.palette.secondary.main,
                                        // opacity: !toggleChatWindow && 0.8
                                    }
                                }
                            }}
                        >
                            <AutoAwesomeTwoToneIcon />
                        </ToggleButton>
                    </LightTooltip>}


                {/* profile */}
                <Box className="flex items-center justify-center gap-2" sx={{ color: theme.palette.text.primary }}>
                    <Box className="flex items-center justify-center gap-2 cursor-pointer" onClick={handleClickProfilePopup} sx={{ color: "inherit" }}>
                        <Avatar variant="rounded" sx={{ background: theme.palette.highlight.main, color: theme.palette.text.primary }}>
                            {profileDetails?.name?.charAt(0) || "-"}
                        </Avatar>
                        <Box className="flex-1 w-auto flex flex-col" sx={{ color: "inherit" }}>
                            <Typography fontSize={font.size.fs_5} className="line-clamp-1" sx={{ color: "inherit" }}>{profileDetails?.name}</Typography>
                            <Typography fontSize={font.size.fs_6} className="line-clamp-1 opacity-60" sx={{ color: theme.palette.text.secondary }}>{profileDetails?.role || "Admin"}</Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={() => navigate(ModulePath.settings)}>
                        <Settings />
                    </IconButton>
                </Box>
            </Box>


            {/* profie popup */}
            <Popover

                open={Boolean(profileAnchorEl)}
                anchorEl={profileAnchorEl}
                onClose={handleClickProfilePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem className="flex items-center gap-0" onClick={() => { }}>
                    <ListItemIcon>
                        <AccountCircleOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className='flex flex-1'>Profile</ListItemText>
                </MenuItem>
                <MenuItem className="flex items-center gap-0" onClick={() => handleLogout()}>
                    <ListItemIcon>
                        <LogoutOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className='flex flex-1'>Logout</ListItemText>
                </MenuItem>
            </Popover>
        </Box>
    )
}

