import { AccountCircleOutlined, Add, ArrowRightAlt, Delete, Description, Edit, FolderOutlined, MoreHoriz } from '@mui/icons-material';
import { Avatar, Box, Chip, Divider, Fab, IconButton, Typography, useTheme } from '@mui/material'
import StoryPreview from './StoryPreview';
import { useApplicationContext } from '../../context/ApplicationContext';
import CreateStoryComponent from '../dialog/CreateStoryDialog.js';
import { useState } from 'react';
import { blue, brown, green, grey, purple, red } from '@mui/material/colors';
import { font } from '../../styles/font';
import StoryCard from './StoryCard.js';
import StoryConfirmationPreview from './StoryConfirmationPreview.js';

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}
 
function StoryContainer() {
    const { currentUserStory, setCurrentUserStory, openStoryPreview, setOpenStoryPreview, openStoryConfirmationPreview, setOpenStoryConfirmationPreview } = useApplicationContext();
    const theme = useTheme();

    const [openCreateStoryDialog, setOpenCreateStoryDialog] = useState(false);
    const [currentStoryFilter, setCurrentStoryFilter] = useState("all");


    let storyList = [
        {
            name: "Create a new user profile",
            description: {
                given: `Given a web page with a form to create a new user profile. The user is on the sign up page.`,
                when: `When the user enters the required information such as name, email, password, and phone number.`,
                then: `Then the system creates a new user profile with the required information such as name, email, password, and phone number.`
            },
            type: "story",
            id: 1,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new user profile with the required information such as name, email, password, and phone number.",
                "The user should not be able to create a new user profile if the required information is not provided.",
                "The user should not be able to create a new user profile if the email address already exists."
            ]
        },
        {
            name: "Log in to the service",
            description: {
                given: `Given a web page with a form to log in to the service.`,
                when: `When the user enters the email address and password.`,
                then: `Then the system logs the user in to the service with the email address and password.`
            },
            type: "story",
            id: 2,
            status: "Approved",
            acceptanceCriteria: [
                "The user should be able to log in to the service with the email address and password.",
                "The user should not be able to log in to the service if the email address or password is incorrect.",
                "The user should not be able to log in to the service if the email address does not exist."
            ]
        },
        {
            name: "Reset password",
            description: {
                given: `Given a web page with a form to reset the password.`,
                when: `When the user enters the email address.`,
                then: `Then the system resets the password with the email address.`
            },
            type: "story",
            id: 3,
            status: "Under development",
            acceptanceCriteria: [
                "The user should be able to reset the password with the email address.",
                "The user should not be able to reset the password if the email address does not exist."
            ]
        },
        {
            name: "Shopping Website",
            description: {
                given: `Given a web page with a form to place an order.`,
                when: `When the user places an order.`,
                then: `Then the system sends a confirmation message to the user after placing an order.`
            },
            type: "project",
            id: 4,
            status: "Completed",
            acceptanceCriteria: [
                "The platform should allow users to browse products.",
                "The platform should provide a secure checkout process.",
                "The platform should have an admin panel to manage products."
            ]
        },
        {
            name: "Banking App",
            description: {
                given: `Given a mobile app with a form to log in to the banking app.`,
                when: `When the user logs in.`,
                then: `Then the system logs the user in to the banking app.`
            },
            type: "project",
            id: 5,
            status: "Closed",
            acceptanceCriteria: [
                "The app should allow users to check their account balance.",
                "The app should enable users to transfer money securely.",
                "The app should provide transaction history and notifications."
            ]
        },
        {
            name: "Create a new project",
            description: {
                given: `Given a web page with a form to create a new project.`,
                when: `When the user enters the name and description of the project.`,
                then: `Then the system creates a new project with the required information such as name and description.`
            },
            type: "story",
            id: 6,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to create a new project with the required information such as name and description.",
                "The user should not be able to create a new project if the required information is not provided.",
                "The user should not be able to create a new project if the project name already exists."
            ]
        },
        {
            name: "Edit a project",
            description: {
                given: `Given a web page with a form to edit a project.`,
                when: `When the user enters the name and description of the project.`,
                then: `Then the system edits the project with the required information such as name and description.`
            },
            type: "story",
            id: 7,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to edit the project with the required information such as name and description.",
                "The user should not be able to edit the project if the required information is not provided.",
                "The user should not be able to edit the project if the project name already exists."
            ]
        },
        {
            name: "Upload contract",
            description: {
                given: `A sales representative needs to upload a signed contract for a customer.`,
                when: `The representative selects the associated contact or opportunity, names the document, selects "Contract" as the document type, and uploads the file.`,
                then: `The system saves the document, linking it to the appropriate contact or opportunity, and auto-generates the upload date.`
            },
            type: "story",
            id: 8,
            status: "Draft",
            acceptanceCriteria: [
                "The user should be able to upload a signed contract for a customer.",
                "The system should save the document, linking it to the appropriate contact or opportunity, and auto-generate the upload date."
            ]
        }
    ];

    function GetStoryStatusColor(story_status) {
        // console.log(story_status, "story_status")
        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return brown[500];
            case STORY_STATUS.APPROVED?.toLowerCase():
                return green[500];
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return blue[500];
            case STORY_STATUS.CLOSED?.toLowerCase():
                return red[500];
            default:
                return grey[500];
        }
    }

    function GetStoryStatusFilterLength(story_status) {

        switch (story_status?.toLowerCase()) {
            case STORY_STATUS.DRAFT?.toLowerCase():
                return storyList
                    ?.filter((item) => item?.type === "story")
                    ?.filter((item) => item?.status?.toLowerCase() === STORY_STATUS.DRAFT?.toLowerCase())?.length
            case STORY_STATUS.APPROVED?.toLowerCase():
                return storyList
                    ?.filter((item) => item?.type === "story")
                    ?.filter((item) => item?.status?.toLowerCase() === STORY_STATUS.APPROVED?.toLowerCase())?.length
            case STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase():
                return storyList
                    ?.filter((item) => item?.type === "story")
                    ?.filter((item) => item?.status?.toLowerCase() === STORY_STATUS.UNDER_DEVELOPMENT?.toLowerCase())?.length
            case STORY_STATUS.CLOSED?.toLowerCase():
                return storyList
                    ?.filter((item) => item?.type === "story")
                    ?.filter((item) => item?.status?.toLowerCase() === STORY_STATUS.CLOSED?.toLowerCase())?.length
            default:
                return storyList?.filter((item) => item?.type === "story")?.length;
        }
    }

    const handleOpenStory = (story) => {
        setCurrentUserStory(story);
        setOpenStoryPreview(true);
    }

    const handleOpenCreateStoryDialog = () => {
        setOpenCreateStoryDialog(true);
    }

    return (
        <Box className=" flex flex-col gap-4 relative h-full w-full box-border overflow-hidden">

            {!openStoryPreview && !openStoryConfirmationPreview && <Fab color="primary" onClick={handleOpenCreateStoryDialog} style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                <Add />
            </Fab>}

            {/* header */}
            <Box className="w-full flex justify-between">
                <Typography variant="h6">User Story</Typography>


                <Box className="flex items-center gap-2">
                    <Avatar sx={{ width: "25px", height: "25px", bgcolor: purple[500], fontSize: "14px" }}>
                        {storyList
                            ?.filter((item) => item?.type === "story")?.length
                            ?.toString()}
                    </Avatar>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    {/* {
                        ["All", "Draft", "Approved", "Under development", "Closed"].map((item, index) => {
                            return (
                                <Chip
                                    key={index}
                                    variant={currentStoryFilter === item?.toLowerCase() ? "filled" : "outlined"}
                                    size="small"
                                    label={`${item}
                                    (${GetStoryStatusFilterLength(item?.toLowerCase())})`}
                                    className="transition-all ease-in-out duration-300 cursor-pointer"
                                    onClick={() => setCurrentStoryFilter(item?.toLowerCase())}
                                    sx={{ color: currentStoryFilter === item?.toLowerCase() ? "white" : GetStoryStatusColor(item?.toLowerCase()), background: currentStoryFilter === item?.toLowerCase() && GetStoryStatusColor(item?.toLowerCase()), "&:hover": { background: currentStoryFilter === item?.toLowerCase() && GetStoryStatusColor(item?.toLowerCase()) } }}
                                />
                            )
                        })
                    } */}
                </Box>
            </Box>

            {/* story list */}
            {false && <Box className=" grow flex   overflow-y-auto ">
                {storyList
                    ?.filter((item) => item?.type === "story")?.length === 0 ?
                    <Box className="grow flex flex-col gap-2 justify-center items-center ">
                        <Description sx={{ fontSize: font.size.fs_1 }} />
                        <Typography fontSize={font.size.fs_5}>No story</Typography>
                    </Box>
                    :
                    (
                        storyList
                            ?.filter((item) => item?.type === "story")
                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)?.length === 0 ?
                            (
                                <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                    <Description sx={{ fontSize: font.size.fs_1 }} />
                                    <Typography fontSize={font.size.fs_5}>No {currentStoryFilter} story</Typography>
                                </Box>
                            ) :

                            (
                                <Box className=" min-w-full h-fit flex flex-wrap gap-6 ">
                                    {
                                        storyList
                                            ?.filter((item) => item?.type === "story")
                                            ?.filter((item) => currentStoryFilter === "all" ? true : item?.status?.toLowerCase() === currentStoryFilter)
                                            ?.map((item, index) => {
                                                return (
                                                    <Box
                                                        // className="bg-white rounded-lg shadow hover:shadow-lg transition-all ease-in-out duration-300 cursor-pointer h-fit min-w-[150px] w-[100%] sm:w-[48%] md:w-[31%] lg:w-[24%] xl:w-[18%] 2xl:w-[18.6%]"
                                                        className="bg-white overflow-hidden rounded-lg border hover:shadow-xl transition-all ease-in-out duration-300 h-[115px] min-w-[120px] w-[98%] sm:w-[47%] md:w-[31%] lg:w-[23%]"
                                                        key={index}
                                                    >
                                                        <StoryCard item={item} handleClick={() => handleOpenStory(item)} />
                                                    </Box>
                                                )
                                            })
                                    }

                                </Box>
                            )
                    )
                }
            </Box>}

            <Box className="flex gap-4 flex-1 h-full overflow-x-auto">
                {
                    [STORY_STATUS.DRAFT, STORY_STATUS.APPROVED, STORY_STATUS.UNDER_DEVELOPMENT, STORY_STATUS.CLOSED]?.map((storyStatus, index) => {
                        return (
                            <Box className={`flex-1 h-full flex flex-col rounded-lg  border-2 border-dashed overflow-hidden bg-white px-4 py-4 gap-4 min-w-[22vw]`} key={index}
                            // sx={{ border: `1px dashed ${GetStoryStatusColor(storyStatus?.toLowerCase())}` }}
                            >

                                <Box className="flex items-center justify-between h-fit">
                                    <Typography fontSize={font.size.fs_4} className="" sx={{ color: GetStoryStatusColor(storyStatus?.toLowerCase()) }}>{storyStatus}</Typography>
                                    <Avatar sx={{ width: "27px", height: "27px", background: GetStoryStatusColor(storyStatus?.toLowerCase()), fontSize: font.size.fs_4 }}>
                                        {
                                            storyList
                                                ?.filter((item) => item?.type === "story")
                                                ?.filter((item) => item?.status?.toLowerCase() === storyStatus?.toLowerCase())?.length < 9 ? `0${storyList
                                                    ?.filter((item) => item?.type === "story")
                                                    ?.filter((item) => item?.status?.toLowerCase() === storyStatus?.toLowerCase())?.length}` : storyList
                                                        ?.filter((item) => item?.type === "story")
                                                        ?.filter((item) => item?.status?.toLowerCase() === storyStatus?.toLowerCase())?.length
                                        }
                                    </Avatar>
                                </Box>

                                <Box className="flex grow rounded-lg overflow-y-auto box-content overflow-x-hidden" sx={{ background: grey[100] }}>
                                    {
                                        storyList
                                            ?.filter((item) => item?.type === "story")
                                            ?.filter((item) => item?.status?.toLowerCase() === storyStatus?.toLowerCase())?.length === 0 ?
                                            (
                                                <Box className="grow flex flex-col gap-2 justify-center items-center ">
                                                    <Description sx={{ fontSize: font.size.fs_1, color: GetStoryStatusColor(storyStatus?.toLowerCase()) }} />
                                                    <Typography fontSize={font.size.fs_5}>No {storyStatus?.toLowerCase()} story</Typography>
                                                </Box>
                                            ) : (
                                                <Box className=" flex flex-col gap-2 w-full  overflow-y-auto ">
                                                    {
                                                        storyList
                                                            ?.filter((item) => item?.type === "story")
                                                            ?.filter((item) => item?.status?.toLowerCase() === storyStatus?.toLowerCase())
                                                            ?.map((item, index) => {
                                                                return (
                                                                    <Box
                                                                        // className="bg-white rounded-lg shadow hover:shadow-lg transition-all ease-in-out duration-300 cursor-pointer h-fit min-w-[150px] w-[100%] sm:w-[48%] md:w-[31%] lg:w-[24%] xl:w-[18%] 2xl:w-[18.6%]"
                                                                        className="bg-white overflow-hidden rounded-lg border hover:shadow-xl transition-all ease-in-out duration-300 min-h-[115px] w-auto box-border"
                                                                        key={index}
                                                                    >
                                                                        <StoryCard item={item} handleClick={() => handleOpenStory(item)} />
                                                                    </Box>
                                                                )
                                                            })
                                                    }

                                                </Box>
                                            )

                                    }
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>


            {console.log(openStoryConfirmationPreview, currentUserStory, "current userstory")}

            {openStoryPreview && currentUserStory && <StoryPreview />}
            {openStoryConfirmationPreview && currentUserStory && <StoryConfirmationPreview />}
            {openCreateStoryDialog && <CreateStoryComponent open={openCreateStoryDialog} closeDialog={() => setOpenCreateStoryDialog(false)} handleContinue={() => setOpenCreateStoryDialog(false)} />}

        </Box>
    )
}

export default StoryContainer
