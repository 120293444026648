import { Box, Button, Dialog, TextField, Typography } from "@mui/material"
import { font } from "../../styles/font"
import { useState } from "react";


export default function CreateModuleDialog({ open, handleClose, handleConfirm, loading, setLoading }) {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({
    name: "",
    description: ""
  });

  const handleChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
    }
  };

  const handleContinue = () => {
    if (name === "") {
      setError({ ...error, name: "Name is required" });
    }
    if (description === "") {
      setError({ ...error, description: "Description is required" });
    }

    handleConfirm({ name, description });
  }

  return (
    <Dialog open={open} onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
    >
      <Box className="flex flex-col gap-4 px-4 py-2">
        <Box className="flex items-center justify-between gap-4">
          <Typography fontSize={font.size.fs_4}>Create Module</Typography>

        </Box>

        <Box className="flex flex-col gap-4">
          <TextField name="name" required={true} label="Name" fullWidth value={name} onChange={handleChange} error={error.name !== ""} helperText={error.name} />
          <TextField name="description" required={true} label="Description" fullWidth multiline minRows={4} maxRows={4} value={description} onChange={handleChange} error={error.description !== ""} helperText={error.description} />
        </Box>

        <Box className="flex items-center justify-end gap-4">
          <Button variant="outlined" disabled={loading} onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" disabled={!name || !description || loading} onClick={handleContinue}>Confirm</Button>
        </Box>

      </Box>
    </Dialog>
  )
}


