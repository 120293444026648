import { Description, Home, PlayArrowRounded, Store } from "@mui/icons-material";
import { ModulePath, StoryStatus } from "./ApplicationVariables";
import { blue, brown, green } from "@mui/material/colors";

export const ApplicationPages = [
    {
        label: "Home",
        path: ModulePath?.home,
        icon: <Home />,
        activeIcon: <Home />,
        onClick: () => { }
    },
    {
        label: "Story Map",
        path: ModulePath?.story,
        icon: <Description />,
        activeIcon: <Description />,
        onClick: () => { }
    }, 
    {
        label: "Marketplace",
        path: ModulePath?.marketplace,
        icon: <Store />,
        activeIcon: <Store />,
        onClick: () => { }
    },
    {
        label: "Execution",
        path: ModulePath?.execution,
        icon: <PlayArrowRounded />,
        activeIcon: <PlayArrowRounded />,
        onClick: () => { }
    },
];

export const STATUS_COLOR = {
    [StoryStatus.pending?.toLowerCase()] : {
        color: brown[700],
        backgroundColor: brown[50]
    },
    [StoryStatus.inprogress?.toLowerCase()] : {
        color: blue[700],
        backgroundColor: blue[50]
    },
    [StoryStatus.completed?.toLowerCase()] : {
        color: green[700],
        backgroundColor: green[50]
    }
}