import { Add, ArrowBack, Attachment, AutoAwesome, AutoAwesomeOutlined, AutoAwesomeTwoTone, Close, Create, Delete, Description, DescriptionOutlined, Edit, ExpandMore, Light, Link, MoreVert, Save, Settings } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { amber, blue, grey } from "@mui/material/colors";
import { forwardRef, useEffect, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { AI_Entity, Entity_Label, FolderType, Severity } from "../../constant/ApplicationVariables";
import { getStoryStatusList_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import styled from "@emotion/styled";
import 'react-quill/dist/quill.snow.css';
import { TableVirtuoso } from "react-virtuoso";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { getUseCaseList_API, updateUseCase_API } from "../../api/controller/UseCaseController";


const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} sx={{ overflowX: "auto", overflowY: "scroll", }} {...props} ref={ref} />),
    Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important", border: `1px solid black`, borderBottom: "1px solid black", borderRight: "1px solid black", }} />,
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 0,
            border: "none"
            // borderColor: theme.palette.common.black
        },
        "&:hover fieldset": {
            borderRadius: 0,
            border: "none"
        },
        "&.Mui-focused fieldset": {
            borderRadius: 0,
            border: "none"
        },
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    // height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
        border: "none"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
}));

class Field_Configuration {
    static fieldType = "Field Type";
    static fieldLength = "Field Length";
    static fieldValidation = "Field Validation";
    static elementId = "Element Id";
}

class EditSection {
    static customId = "customId";
    static title = "title";
    static description = "description";
    static steps = "steps";
    static linked = "linked";
    static linkedTestCase = "linkedTestCase";

    static acceptanceCriteria = "acceptanceCriteria";
    static attachments = "attachments";
    static status = "status";
    static assignedTo = "assignedTo";
    static priority = "priority";
    static category = "category";
    static moscow = "moscow";
    static storyType = "storyType";
    static userFields = "userFields";
    static userPoints = "userPoints";
    static plannedInRelease = "plannedInRelease";
    static plannedInSprint = "plannedInSprint";
    static confidenciality = "confidenciality";
    static underSOXCategory = "underSOXCategory";
    static environment = "environment";
    static verificationOwner = "verificationOwner";
    static verificationMethod = "verificationMethod";
    static dependency = "dependency";
    static businessValue = "businessValue";
    static userPersona = "userPersona";
    static testingCriteria = "testingCriteria";
    static businessUnit = "businessUnit";
    static vendor = "vendor";
    static externalPoints = "externalPoints";
    static subProducts = "subProducts";
}

export default function EditUseCaseComponent({ open, closeDialog, }) {
    const {
        currentUseCaseData, setCurrentUseCaseData,
        currentFolderData,
        handleSnackbar,
        activeProject,
        setOpenLinkTagDrawer,
        setUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(true);
    const [enableEdit, setEnableEdit] = useState(null);

    const [currentStepText, setCurrentStepText] = useState("");
    const [currentStepIndex, setCurrentStepIndex] = useState(null);
    const [stepsAnchorEl, setStepsAnchorEl] = useState(null);
    const [userData, setUserData] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [editorValue, setEditorValue] = useState("");
    const [validationAnchorEl, setValidationAnchorEl] = useState(null);
    const [currentFieldConfiguration, setCurrentFieldConfiguration] = useState(null);
    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [aiAnchorEl, setAiAnchorEl] = useState(null);


    const handleEditClick = () => {

        if (enableEdit) {
            setUserData(currentUseCaseData);
            setEnableEdit(false);
        } else {
            setEnableEdit(true);
        }

    }

    const handleOpenAIPopup = (e) => {
        e && e.stopPropagation();
        setAiAnchorEl(e.currentTarget);
    }

    const handleCloseAIPopup = () => {
        setAiAnchorEl(null);
    }

    const handleToggleAIWindow = (entityLabel, entityType) => {

        if (isItemChatWindow && currentAIEntityData?.label === entityLabel && currentAIEntityData?.entity === entityType) {
            setIsItemChatWindow(false)
            setCurrentAIEntityData(null);
            // setCurrentUserStoryData(null);
        } else {
            // setCurrentUserStoryData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
            setCurrentAIEntityData({ label: entityLabel, entity: entityType })
        }

        handleCloseAIPopup();
    }

    const handleDragStart = (index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = [...userData.useCaseSteps];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserData({ ...userData, useCaseSteps: newSteps });
    };

    const handleCloseFieldConfiguration = () => {
        setCurrentFieldConfiguration(null);
    }

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const handleAddUpdateListItem = () => {
        if (currentStepIndex !== null) {
            let steps = [...userData?.useCaseSteps];
            steps[currentStepIndex] = currentStepText;
            setUserData({ ...userData, useCaseSteps: steps });
            setCurrentStepIndex(null);
        } else {
            if (userData?.useCaseSteps) {
                setUserData({ ...userData, useCaseSteps: [...userData?.useCaseSteps, currentStepText] });
            } else {
                setUserData({ ...userData, useCaseSteps: [currentStepText] });
            }
        }

        setCurrentStepText("");
    }

    const handleCurrentListItem = (stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = userData?.useCaseSteps
        list[stepIndex] = stepText;
        setUserData({ ...userData, useCaseSteps: list });
    }

    const handleRemoveListItem = (index) => {
        setUserData({ ...userData, useCaseSteps: userData?.useCaseSteps.filter((_, i) => i !== index) });
    }

    const handleAddRowBelow = (index) => {
        let steps = userData?.useCaseSteps ? [...userData?.useCaseSteps] : [];
        if (steps?.length > 0 && steps[index] === "") {
            return;
        }
        if (steps?.length > 0) {
            steps.splice(index + 1, 0, "");
        } else {
            steps.push("");
        }

        setUserData({ ...userData, useCaseSteps: steps });
    }

    const handleChangeFieldConfiguration = (fieldConfiguration) => {
        setCurrentFieldConfiguration(fieldConfiguration);
        setStepsAnchorEl(null);
    }


    const handleEnableSectionEdit = (e, sectionName) => {
        setEnableEdit(sectionName);
        setEditAnchorEl(e.currentTarget);
    }

    const handleCloseSectionEdit = () => {
        setEnableEdit(null);
        setEditAnchorEl(null);
    }

    const handleGetFieldConfigurationComponent = (fieldConfiguration) => {
        switch (fieldConfiguration) {
            case Field_Configuration.fieldType: return <FieldTypeComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldLength: return <FieldLengthComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldValidation: return <FieldValidationComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.elementId: return <FieldElementIdComponent handleClose={handleCloseFieldConfiguration} />;
            default: return null;
        }
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userData?.customId) flag = false;
        if (!userData?.title) flag = false;

        return flag;
    }

    const handleOpenLinkTag = (data) => {
        setOpenLinkTagDrawer(true)
    }

    const handleContinueStory = () => {
        let body = {
            "customId": currentUseCaseData?.customId,
            // "newCustomId": userData?.customId,
            // "title": userData?.title,
            // "description": userData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userData?.assignedToEmail,
            "moduleName": currentFolderData?.data?.name,
            "projectName": activeProject?.name,
            // "acceptanceCriteria": userData?.acceptanceCriteria,
            // "status": userData?.status
        }

        if (userData?.customId !== currentUseCaseData?.customId) {
            body.newCustomId = userData?.customId
        }
        if (userData?.title !== currentUseCaseData?.title) {
            body.title = userData?.title
        }
        // if (userData?.description !== currentUseCaseData?.description) {
        //     body.description = userData?.description
        // }
        // if (userData?.priority !== currentUseCaseData?.priority) {
        //     body.priority = userData?.priority
        // }
        // if (userData?.assignedToEmail !== currentUseCaseData?.assignedToEmail) {
        //     body.assignedToEmail = userData?.assignedToEmail
        // }
        if (JSON.stringify(userData?.useCaseSteps) !== JSON.stringify(currentUseCaseData?.useCaseSteps)) {
            body.useCaseSteps = userData?.useCaseSteps ? userData?.useCaseSteps : []
        }
        // if (userData?.status !== currentUseCaseData?.status) {
        //     body.status = userData?.status
        // }



        // handleContinue(body);
        // setEnableEdit(false)

        handleUpdateUseCase(body);
    }

    const handleGetStatusList = async () => {
        try {
            // setGlobalLoader(true);

            const response = await getStoryStatusList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setStatusList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleUpdateUseCase = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
            }


            const response = await updateUseCase_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUseCaseList();
                setEnableEdit(false);

                handleCloseSectionEdit()
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {
            setLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if (enableEdit) {
            handleGetStatusList();
        }
    }, [enableEdit]);


    useEffect(() => {
        setLoading(true);
        setUserData(currentUseCaseData);

        setTimeout(() => {
            setLoading(false);
        }, 400)
    }, [currentUseCaseData]);



    console.log(userData, "userData", userData?.name, currentStepText, currentStepIndex);
    console.log(editorValue, "textvalue")

    return (
        <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 overflow-hidden "
            // bgcolor={theme.palette.primary.layout_col}
            borderRadius={theme.shape.borderRadius}
            bgcolor={theme.palette.common.white}
        >

            {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                <CircularProgress />
            </Box>}



            <Box className={`flex flex-col px-4 py-4 gap-4 w-full h-full relative rounded-lg overflow-hidden`} >



                {/* top */}
                <Box className="w-full  flex items-center justify-between gap-0 ">
                    {/* left */}
                    <Box className="flex-1 flex items-center gap-2 w-full pr-4 ">

                        <IconButton
                            size="small"
                            value="check"
                            // selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <ArrowBack />
                        </IconButton>

                        <Box className="w-full flex flex-col ">
                            {/* custom Id */}
                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.customId)}>
                                {enableEdit && enableEdit === EditSection.customId &&
                                    <HeadingComponent heading="Custom ID" hideColon={enableEdit && enableEdit === EditSection.customId} />}

                                {enableEdit && enableEdit === EditSection.customId ?
                                    <Box className="w-full flex flex-col gap-0">
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            name="customId"
                                            // label="Custom ID"
                                            required
                                            fullWidth
                                            // focused={userStoryData?.customId}
                                            // autoFocus
                                            value={userData?.customId}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    :
                                    <Box className="flex items-center gap-1">
                                        <Description color="secondary" sx={{ fontSize: font.size.fs_4 }} />
                                        <ValueComponent color={theme.palette.secondary.dark} text={userData?.customId} />
                                    </Box>
                                }
                            </SectionContainer>


                            {/* Title */}
                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.title)}>
                                {enableEdit && enableEdit === EditSection.title &&
                                    <HeadingComponent heading="Title" hideColon={enableEdit && enableEdit === EditSection.title ? true : false} />}

                                {enableEdit && enableEdit === EditSection.title ?
                                    <Box className="w-full flex flex-col gap-0">
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            name="title"
                                            required
                                            // label="Title"
                                            // autoFocus
                                            fullWidth
                                            multiline
                                            minRows={1}
                                            maxRows={2}
                                            // focused={userStoryData?.title}
                                            value={userData?.title}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    :
                                    <Box className="flex flex-col gap-0">
                                        <Typography fontSize={font.size.fs_3_5} fontWeight={500} className="" color={theme.palette.text.primary}>{userData?.title}</Typography>
                                    </Box>
                                }
                            </SectionContainer>
                        </Box>
                    </Box>

                    {/* right */}
                    <Box className="w-[32%] flex items-center justify-end gap-2 ">

                        <IconButton>
                            <Settings />
                        </IconButton>

                        {false && <Button
                            size="medium"
                            variant={isItemChatWindow ? "contained" : "outlined"}
                            disableElevation
                            onClick={handleOpenAIPopup}
                            startIcon={<AutoAwesome />}
                        // endIcon={<KeyboardArrowDown />}
                        >
                            {isItemChatWindow && <Description sx={{ fontSize: font.size.fs_4 }} />}
                            {!isItemChatWindow ? "Generate" : currentAIEntityData?.label}
                        </Button>}

                        {false && <ToggleButton
                            size="small"
                            value="check"
                            selected={enableEdit}
                            onChange={handleEditClick}
                        >
                            <Edit />
                        </ToggleButton>}

                        {false && <ToggleButton
                            size="small"
                            value="check"
                            selected={false}
                            // disabled={!enableEdit || currentFolderData?.data?.type !== FolderType.module || !handleCheckValues()}
                            disabled={!enableEdit || !handleCheckValues()}
                            onClick={() => handleContinueStory(userData)}
                        >
                            <Save />
                        </ToggleButton>}

                        {/* <IconButton
                                disabled={currentFolderData?.data?.type !== FolderType.module || !handleCheckValues()}
                                onClick={() => handleContinueStory(userData)}
                            >
                                <Save />
                            </IconButton> */}

                        {/* <ToggleButton
                            size="small"
                            value="check"
                            selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <Close />
                        </ToggleButton> */}
                    </Box>
                </Box>

                {/* bottom */}
                <Box className="w-full  py-1 flex-1 flex gap-0 overflow-y-auto">
                    {/* left */}
                    <Box className={`flex-1 flex flex-col pr-4   gap-6  overflow-y-auto border-r border-gray-300`}>

                        {/* steps */}
                        <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.steps)}>
                            <Box className="flex items-center justify-between gap-2">
                                <HeadingComponent heading="Steps" hideColon={enableEdit && enableEdit === EditSection.steps ? true : false} />
                                <Box className="flex items-center gap-2">

                                    {/* <LightTooltip title="">
                                        <IconButton onClick={() => setCurrentFieldConfiguration(null)}>
                                            <AutoAwesomeTwoTone />
                                        </IconButton>
                                    </LightTooltip> */}

                                    {!enableEdit && <IconButton onClick={(e) => { e.stopPropagation(); setStepsAnchorEl(e.currentTarget) }}>
                                        <MoreVert />
                                    </IconButton>}


                                    <Popover
                                        open={Boolean(stepsAnchorEl)}
                                        anchorEl={stepsAnchorEl}
                                        onClose={(e) => { e.stopPropagation(); setStepsAnchorEl(null) }}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <MenuItem onClick={(e) => { e.stopPropagation(); handleChangeFieldConfiguration(Field_Configuration.fieldType) }}>Field Type</MenuItem>
                                        <MenuItem onClick={(e) => { e.stopPropagation(); handleChangeFieldConfiguration(Field_Configuration.fieldLength) }}>Field Length</MenuItem>
                                        <MenuItem onClick={(e) => { e.stopPropagation(); handleChangeFieldConfiguration(Field_Configuration.fieldValidation) }}>Field Validation</MenuItem>
                                        <MenuItem onClick={(e) => { e.stopPropagation(); handleChangeFieldConfiguration(Field_Configuration.elementId) }}>Element Id</MenuItem>
                                    </Popover>
                                </Box>
                            </Box>

                            <Box className=" h-fit flex flex-col gap-2  overflow-y-auto ">
                                <Box className={`flex flex-col gap-2  overflow-y-auto ${enableEdit && enableEdit === EditSection.steps ? "h-48" : ""}  ${userData?.useCaseSteps?.length > 0 ? " pl-4 pr-4" : "pl-0"}`} >
                                    {userData?.useCaseSteps?.length > 0 ?
                                        userData?.useCaseSteps?.map((currentStep, index) => (
                                            enableEdit && enableEdit === EditSection.steps ? (
                                                <Box
                                                    key={index}
                                                    className="flex items-center gap-1  relative"
                                                >
                                                    <LightTooltip title="Add Step below" >
                                                        <IconButton
                                                            onClick={() => handleAddRowBelow(index)}
                                                            sx={{ position: "relative", top: "6px" }}
                                                        >
                                                            <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                        </IconButton>
                                                    </LightTooltip>

                                                    <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>

                                                    <Box
                                                        className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                        draggable
                                                        onDragStart={(e) => handleDragStart(index, e)}
                                                        onDragOver={(e) => handleDragOver(e)}
                                                        onDrop={(e) => handleDrop(index, e)}
                                                    >


                                                        <IconButton>
                                                            <DragHandleIcon />
                                                        </IconButton>

                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            placeholder="Step description"
                                                            value={currentStep}
                                                            onChange={(e) => handleCurrentListItem(index, e.target.value)}
                                                            sx={{

                                                                px: 0,
                                                                "& .MuiOutlinedInput-root": {
                                                                    fontSize: font.size.fs_4,
                                                                    "& fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                        // borderColor: theme.palette.common.black
                                                                    },
                                                                    "&:hover fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                    "&.Mui-focused fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <IconButton color="error" onClick={() => handleRemoveListItem(index)}><Delete /></IconButton>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box key={index} className="flex items-center gap-2 " >
                                                    <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>
                                                    <Typography fontSize={font.size.fs_5} className="flex-1">{currentStep}</Typography>
                                                </Box>
                                            )
                                        ))
                                        :

                                        <Box className={`flex  ${enableEdit && enableEdit === EditSection.steps && "bg-gray-100 items-center justify-center    "} `} borderRadius={theme.shape.borderRadius} >
                                            {/* <Typography>{enableEdit && enableEdit === EditSection.steps ? "Add" : "None"}</Typography> */}
                                            <ValueComponent text={enableEdit && enableEdit === EditSection.steps ? "Add" : "None"} />
                                            {enableEdit && enableEdit === EditSection.steps && <IconButton onClick={() => handleAddRowBelow(0)}>
                                                <Add />
                                            </IconButton>}
                                        </Box>}
                                </Box>
                            </Box>
                        </SectionContainer>


                        {/* Linked Use Stories list */}
                        <SectionContainer >

                            <Box className="flex items-center justify-between">
                                <HeadingComponent heading="Linked User Story" className="flex-1" />

                                <LightTooltip title="Generate using AI" >
                                    <IconButton onClick={() => handleToggleAIWindow(Entity_Label.userStory, AI_Entity.userStory)}>
                                        {currentAIEntityData?.entity === AI_Entity.userStory ? <AutoAwesome /> : <AutoAwesomeOutlined />}
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title="Link " >
                                    <IconButton onClick={() => handleOpenLinkTag(currentUseCaseData)}>
                                        <Link />
                                    </IconButton>
                                </LightTooltip>
                            </Box>


                            {enableEdit && enableEdit === EditSection.linked ?
                                <Box className={`w-full flex flex-col gap-1 ${currentUseCaseData?.userStories?.length === 0 ? "h-auto" : "min-h-52 max-h-52"}`}>
                                    {/* table */}
                                    <Box className="flex-1 flex flex-col">
                                        {currentUseCaseData?.userStories?.length === 0 ?
                                            <ValueComponent text={"None"} />
                                            :
                                            (
                                                <TableVirtuoso
                                                    style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                    data={currentUseCaseData?.userStories}
                                                    components={TableComponents}
                                                    fixedHeaderContent={() => (
                                                        <TableHead>


                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                            </TableCell>
                                                        </TableHead>
                                                    )}


                                                    itemContent={(index, itemData) => (

                                                        <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="py-1">{index + 1}.</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                    <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                        </TableRow>

                                                    )}
                                                />
                                            )
                                        }

                                    </Box>
                                </Box>
                                :
                                <Box className={`w-full flex flex-col gap-1 ${currentUseCaseData?.userStories?.length === 0 ? "h-auto" : "min-h-52 max-h-52"}`}>
                                    {/* table */}
                                    <Box className="flex-1 flex flex-col">
                                        {currentUseCaseData?.userStories?.length === 0 ?
                                            <ValueComponent text={"None"} />
                                            :
                                            (
                                                <TableVirtuoso
                                                    style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                    data={currentUseCaseData?.userStories}
                                                    components={TableComponents}
                                                    fixedHeaderContent={() => (
                                                        <TableHead>


                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                            </TableCell>
                                                        </TableHead>
                                                    )}


                                                    itemContent={(index, itemData) => (

                                                        <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="py-1">{index + 1}.</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                    <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                        </TableRow>

                                                    )}
                                                />
                                            )
                                        }

                                    </Box>
                                </Box>
                            }
                        </SectionContainer>

                        {/* Linked test case list */}
                        <SectionContainer >

                            <Box className="flex items-center justify-between">
                                <HeadingComponent heading="Linked Test Case" className="flex-1" />

                                <LightTooltip title="Generate using AI" >
                                    <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}>
                                        {currentAIEntityData?.entity === AI_Entity.testCase ? <AutoAwesome /> : <AutoAwesomeOutlined />}
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title="Link " >
                                    <IconButton onClick={() => handleOpenLinkTag(currentUseCaseData)}>
                                        <Link />
                                    </IconButton>
                                </LightTooltip>
                            </Box>


                            {enableEdit && enableEdit === EditSection.linkedTestCase ?
                                <Box className={`w-full flex flex-col gap-1 ${(!currentUseCaseData?.testCases || currentUseCaseData?.testCases?.length === 0) ? "h-auto" : "min-h-52 max-h-52"}`}>
                                    {/* table */}
                                    <Box className="flex-1 flex flex-col">
                                        {(!currentUseCaseData?.testCases || currentUseCaseData?.testCases?.length === 0) ?
                                            <ValueComponent text={"None"} />
                                            :
                                            (
                                                <TableVirtuoso
                                                    style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                    data={currentUseCaseData?.testCases}
                                                    components={TableComponents}
                                                    fixedHeaderContent={() => (
                                                        <TableHead>


                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                            </TableCell>
                                                        </TableHead>
                                                    )}


                                                    itemContent={(index, itemData) => (

                                                        <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="py-1">{index + 1}.</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                    <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                        </TableRow>

                                                    )}
                                                />
                                            )
                                        }

                                    </Box>
                                </Box>
                                :
                                <Box className={`w-full flex flex-col gap-1 ${(!currentUseCaseData?.testCases || currentUseCaseData?.testCases?.length === 0) ? "h-auto" : "min-h-52 max-h-52"}`}>
                                    {/* table */}
                                    <Box className="flex-1 flex flex-col">
                                        {(!currentUseCaseData?.testCases || currentUseCaseData?.testCases?.length === 0) ?
                                            <ValueComponent text={"None"} />
                                            :
                                            (
                                                <TableVirtuoso
                                                    style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                    data={currentUseCaseData?.testCases}
                                                    components={TableComponents}
                                                    fixedHeaderContent={() => (
                                                        <TableHead>


                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                            </TableCell>

                                                            <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                            </TableCell>
                                                        </TableHead>
                                                    )}


                                                    itemContent={(index, itemData) => (

                                                        <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="py-1">{index + 1}.</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                    <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                        </TableRow>

                                                    )}
                                                />
                                            )
                                        }

                                    </Box>
                                </Box>
                            }
                        </SectionContainer>
                    </Box>


                    {/* right */}
                    {true && <Box className={`w-[32%] h-full flex flex-col  px-4   box-border relative  gap-6 overflow-y-auto `}>


                        {/* properties container */}
                        {!currentFieldConfiguration ?
                            <Box className={`h-full flex flex-col gap-6 `} >

                                {false && (!enableEdit ?
                                    <Box className="flex flex-col gap-0">
                                        <HeadingComponent heading="Linked UserStory" />
                                        <Typography fontSize={font.size.fs_4} className="" > {"US_001 - Login functionality" || "None"} </Typography>
                                    </Box>
                                    : <KeyValuePairComponent
                                        heading={"Status"}
                                        value={
                                            <StyledSelect
                                                size="small"
                                                variant="outlined"
                                                name="status"
                                                fullWidth
                                                disabled={!userData?.status}
                                                value={userData?.status || ''}
                                                onChange={handleChange}
                                            >
                                                {
                                                    statusList?.map((status, index) => (
                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                    ))
                                                }
                                            </StyledSelect>
                                        }
                                    />)}

                            </Box>
                            :
                            <Box className="absolute top-0 left-0 w-full h-full  " >
                                {handleGetFieldConfigurationComponent(currentFieldConfiguration)}
                            </Box>}

                    </Box>}
                </Box>
            </Box>


            {/*  popup for current edit section */}
            <div style={{ pointerEvents: 'none' }}>
                <Popover
                    id="edit-section"
                    open={Boolean(editAnchorEl)}
                    anchorEl={editAnchorEl}
                    hideBackdrop
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "bottom", horizontal: "right" }}

                    slotProps={{
                        paper: {
                            sx: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none'
                            }
                        }
                    }}
                >
                    <div style={{ pointerEvents: 'auto' }}>
                        <Box className="flex-1 flex items-center gap-1 py-0">

                            <ToggleButton
                                size="small"
                                value="check"
                                selected={false}
                                disabled={!enableEdit || !handleCheckValues()}
                                onClick={() => handleContinueStory(userData)}

                            >
                                <Save />
                            </ToggleButton>


                            <ToggleButton
                                size="small"
                                value="check"
                                onClick={handleCloseSectionEdit}

                            >
                                <Close />
                            </ToggleButton>

                        </Box>
                    </div>
                </Popover>
            </div>

            {/* popup for current AI entity */}
            <Popover
                id="AI-Entity"
                open={Boolean(aiAnchorEl)}
                anchorEl={aiAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleCloseAIPopup}
            >
                <MenuItem
                    selected={currentAIEntityData?.entity === AI_Entity.userStory}
                    onClick={() => handleToggleAIWindow(Entity_Label.userStory, AI_Entity.userStory)}
                    sx={{
                        color: currentAIEntityData?.entity === AI_Entity.userStory ? theme.palette.primary.main : "",
                        backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.userStory?.toLowerCase() ? theme.palette.secondary.main : ""
                    }}
                >
                    {/* <ListItemIcon>
                        <Description fontSize="small" />
                    </ListItemIcon> */}
                    {/* <ListItemText>{Entity_Label.userStory}</ListItemText> */}
                    {Entity_Label.userStory}
                </MenuItem>

                <MenuItem
                    selected={currentAIEntityData?.entity === AI_Entity.testCase}
                    onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}
                    sx={{
                        color: currentAIEntityData?.entity === AI_Entity.testCase ? theme.palette.primary.main : "",
                        backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.testCase?.toLowerCase() ? theme.palette.secondary.main : ""
                    }}
                >
                    {/* <ListItemIcon>
                        <Description fontSize="small" />
                    </ListItemIcon> */}
                    {/* <ListItemText>{Entity_Label.testCase}</ListItemText> */}
                    {Entity_Label.testCase}
                </MenuItem>

            </Popover>
        </Box>
    )

}

function KeyValuePairComponent({ heading, value }) {
    return (
        <Box className="w-full flex flex-col gap-1">
            <Box className="flex ">
                <HeadingComponent heading={heading} />
            </Box>
            <Box className="flex items-center flex-1 ">
                {value}
            </Box>
        </Box>
    )
}


function KeyValueHeadingComponent({ heading }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className="opacity-60">{heading}</Typography>
    )
}

function KeyValueValueComponent({ value }) {
    return (
        <Typography fontSize={font.size.fs_4}>{value}</Typography>
    )
}

function HeadingComponent({ heading, hideColon, ...props }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className={`${props.className} `} {...props}>{heading} {hideColon ? "" : ":"}</Typography>
    )
}

function ValueComponent({ text, ...props }) {
    return (
        <Typography fontSize={font.size.fs_5} className={`${props.className} `} {...props}> {text ? text : "None"} </Typography>
    )
}



function CheckBoxContainerComponent({ children }) {
    return (
        <Box className="flex justify-center gap-2 ">
            {children}
        </Box>
    )
}

function KeyValueComponent({ heading, value }) {
    return (
        <Box className="flex items-center justify-between gap-4 ">
            <Typography variant="body2" className="flex-1 ">{heading}</Typography>
            <Typography className=" flex  ">{value}</Typography>
        </Box>
    );
}

function FieldTypeComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "165px",
            children: []
        },
        {
            data: "Field Type",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "120px",
            children: []
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full gap-2 flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Type</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 px-1" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "165px", maxWidth: "165px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <StyledSelect
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="fieldType"
                                value={"Textfield"}
                            // value={data?.fieldType}
                            // onChange={(e) => handleChange(e, index)}
                            >
                                {
                                    ["Textfield", "Dropdown", "Checkbox", "Radio", "Textarea", "File", "Link", "Image",]?.map((status, index) => (
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))

                                }
                            </StyledSelect>

                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldLengthComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Field Length",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "130px",
            children: [
                {
                    data: "Min",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "100px"

                },
                {
                    data: "Max",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "100px",

                }
            ]
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Length</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col gap-0 ">
                                            {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                            <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 h-full flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-0 px-1 ">
                                <StyledTextField
                                    // label="Min Length"
                                    type="number"
                                    name="minLength"
                                // defaultValue={data?.fieldValidation?.minLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    // label="Max Length"
                                    type="number"
                                    name="maxLength"
                                // defaultValue={data?.fieldValidation?.maxLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldValidationComponent({ handleClose }) {
    const theme = useTheme();

    const [validationAnchorEl, setValidationAnchorEl] = useState(null);

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Validations",
            padding: "none",
            px: "1rem",
            align: "center",
            background: theme.palette.header.main,
            width: "130px",
            children: []
        },
    ];

    const handleClickValidationPopup = (event,) => {
        if (validationAnchorEl) {
            setValidationAnchorEl(null);
        } else {
            setValidationAnchorEl(event.currentTarget);
        }
    }


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Validation</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-2 px-4">


                                <Button fullWidth onClick={handleClickValidationPopup}>
                                    <Add />
                                </Button>

                                <Popover
                                    id="validation-popover"
                                    open={Boolean(validationAnchorEl)}
                                    anchorEl={validationAnchorEl}
                                    onClose={handleClickValidationPopup}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{

                                    }}
                                >
                                    <Box className="flex-1 flex flex-col gap-4 px-4">
                                        <Box className="flex items-center justify-between gap-4 py-2 border-b">
                                            <Typography>Field Validation</Typography>
                                            <IconButton onClick={handleClickValidationPopup}>
                                                <Close />
                                            </IconButton>
                                        </Box>

                                        <Box className="flex-1 flex flex-col gap-4 p-2">
                                            <KeyValueComponent
                                                heading={`Uppercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValueComponent
                                                heading={`Lowercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Whitespace`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Special Character`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Required`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValueComponent
                                                heading={`Numeric`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />


                                        </Box>
                                    </Box>
                                </Popover>

                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldElementIdComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Element Id",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "130px",
            children: [
                {
                    data: "Xpath",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "200px"
                },
                {
                    data: "CSS",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "200px"
                },
                {
                    data: "ID",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "200px"
                }
            ]
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Element Id</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col h-full items-center  gap-0 ">
                                            {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                            <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", }}>
                            <Box className="flex items-center gap-0">
                                <StyledTextField
                                    fullWidth
                                    // label="xpath"
                                    name="xpath"
                                // defaultValue={data?.elementId?.xpath}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    fullWidth
                                    // label="cssSelector"
                                    name="cssSelector"
                                // defaultValue={data?.elementId?.cssSelector}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    fullWidth
                                    // label="id"
                                    name="id"
                                // defaultValue={data?.elementId?.id}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function SectionContainer({ children, ...props }) {
    return (
        <Box className={`w-full flex flex-col gap-1 px-1 border border-transparent cursor-pointer ${props.onClick && "hover:border hover:border-gray-200"}  rounded-lg ${props.className} `} {...props}>
            {children}
        </Box>
    );
}
