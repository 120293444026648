import { Add, ArrowBack, Attachment, AutoAwesome, AutoAwesomeOutlined, Close, ContentCut, CopyAllOutlined, Create, Delete, Description, DescriptionOutlined, DragHandle, Edit, ExpandMore, KeyboardArrowDown, MoreHoriz, Save, Settings, Upload } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, ClickAwayListener, Divider, FormControl, FormControlLabel, IconButton, ImageList, ImageListItem, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { blue, grey } from "@mui/material/colors";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { AI_Entity, Entity_Label, FolderType, Severity } from "../../constant/ApplicationVariables";
import { getStoryList_API, getStoryStatusList_API, updateStory_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import styled from "@emotion/styled";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { convertEditorDataToReact } from "../../utils/StringOperations";
import { TableVirtuoso } from "react-virtuoso";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LabelIcon from '@mui/icons-material/Label';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import LinkIcon from '@mui/icons-material/Link';

const StyledSelect = styled(Select)(({ theme }) => ({
    height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
    },
}));

const QuillContainer = styled("div")(({ theme }) => ({
    ".ql-container": {
        borderRadius: " 0 0 0.6rem 0.6rem",
    },
    ".ql-toolbar": {
        borderRadius: "  0.6rem 0.6rem 0 0 "
    },
    ".ql-editor": {
        height: "200px"
    },
}));


const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

class EditSection {
    static customId = "customId";
    static title = "title";
    static description = "description";
    static acceptanceCriteria = "acceptanceCriteria";
    static attachments = "attachments";
    static linked = "linked";
    static linkedTestCase = "linkedTestCase";
    static status = "status";
    static assignedTo = "assignedTo";
    static priority = "priority";
    static category = "category";
    static moscow = "moscow";
    static storyType = "storyType";
    static userFields = "userFields";
    static userPoints = "userPoints";
    static plannedInRelease = "plannedInRelease";
    static plannedInSprint = "plannedInSprint";
    static confidenciality = "confidenciality";
    static underSOXCategory = "underSOXCategory";
    static environment = "environment";
    static verificationOwner = "verificationOwner";
    static verificationMethod = "verificationMethod";
    static dependency = "dependency";
    static businessValue = "businessValue";
    static userPersona = "userPersona";
    static testingCriteria = "testingCriteria";
    static businessUnit = "businessUnit";
    static vendor = "vendor";
    static externalPoints = "externalPoints";
    static subProducts = "subProducts";
    static dueDate = "dueDate";
    static members = "members";
    static checklist = "checklist";
    static label = "label";

}


export default function EditStoryComponent({ open, closeDialog, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,
        currentFolderData,
        activeProject,
        handleSnackbar,
        setOpenLinkTagDrawer,
        setUserStoryList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(true);
    const [enableEdit, setEnableEdit] = useState(null);

    const [userStoryData, setUserStoryData] = useState(null);

    const [accetanceCriteriaText, setAccetanceCriteriaText] = useState("");
    const [statusList, setStatusList] = useState([]);
    const [editorValue, setEditorValue] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [sections, setSections] = useState({
        [EditSection.attachments]: false,
        [EditSection.members]: false,
        [EditSection.checklist]: false,
        [EditSection.label]: false,
        [EditSection.dueDate]: false,
        [EditSection.userPersona]: false
    });

    const [expandedAccordion, setExpandedAccordion] = useState("details");

    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [aiAnchorEl, setAiAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);


    const fileInputRef = useRef(null);


    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(panel)
        return;

        let list = [...expandedAccordion];

        if (list.includes(panel)) {
            const index = list.indexOf(panel);
            list.splice(index, 1);
        } else {
            list = [...list, panel]
        }

        setExpandedAccordion(list);
    };


    const handleAddAcceptanceCriteria = (e) => {
        let text = e.target.value ? e.target.value : accetanceCriteriaText;
        if (!text) {
            return
        }

        if (userStoryData?.acceptanceCriteria) {
            setUserStoryData({ ...userStoryData, acceptanceCriteria: [...userStoryData?.acceptanceCriteria, text] });
        } else {
            setUserStoryData({ ...userStoryData, acceptanceCriteria: [text] });
        }

        setAccetanceCriteriaText("");
    }

    const handleRemoveAcceptanceCriteria = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }


    const handleChange = (e) => {
        setUserStoryData({ ...userStoryData, [e.target.name]: e.target.value });
    }

    const handleChangeFiles = (e) => {
        let files = e.target.files;
        setSelectedFiles([...files]);

        console.log(files, files && files[0] && URL.createObjectURL(files[0]), "files")
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userStoryData?.customId) flag = false;
        if (!userStoryData?.title) flag = false;
        if (!userStoryData?.description) flag = false;

        return flag;
    }


    const handleOpenLinkTag = (data) => {
        setOpenLinkTagDrawer(true)
    }


    const handleOpenAIPopup = (e) => {
        e && e.stopPropagation();
        setAiAnchorEl(e.currentTarget);
    }

    const handleCloseAIPopup = () => {
        setAiAnchorEl(null);
    }

    const handleToggleAIWindow = (entityLabel, entityType) => {

        if (isItemChatWindow && currentAIEntityData?.label === entityLabel && currentAIEntityData?.entity === entityType) {
            setIsItemChatWindow(false)
            setCurrentAIEntityData(null);
            // setCurrentUserStoryData(null);
        } else {
            // setCurrentUserStoryData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
            setCurrentAIEntityData({ label: entityLabel, entity: entityType })
        }

        handleCloseAIPopup();
    }

    const handleCurrentListItem = (stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = userStoryData?.acceptanceCriteria
        list[stepIndex] = stepText;
        setUserStoryData({ ...userStoryData, acceptanceCriteria: list });
    }

    const handleRemoveListItem = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }

    const handleDragStart = (index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = [...userStoryData?.acceptanceCriteria];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserStoryData({ ...userStoryData, acceptanceCriteria: newSteps });
    };

    const handleAddRowBelow = (index) => {
        let steps = userStoryData?.acceptanceCriteria ? [...userStoryData?.acceptanceCriteria] : [];

        if (steps?.length > 0 && steps[index] === "") {
            return;
        }
        if (steps?.length > 0) {
            steps.splice(index + 1, 0, "");
        } else {
            steps.push("");
        }

        setUserStoryData({ ...userStoryData, acceptanceCriteria: steps });
    }


    const handleEnableSectionEdit = (e, sectionName) => {
        setEnableEdit(sectionName);
        setEditAnchorEl(e.currentTarget);
    }

    const handleCloseSectionEdit = () => {
        setEnableEdit(null);
        setEditAnchorEl(null);
    }

    const handleToggleMenuPopup = (e) => {

        e && e.stopPropagation();

        if (!menuAnchorEl) {
            setMenuAnchorEl(e.currentTarget);
        } else {
            setMenuAnchorEl(null);
        }
    }

    const handleToggleSection = (sectionName) => {
        setSections({ ...sections, [sectionName]: !sections[sectionName] })
    }


    const handleContinueStory = () => {
        let body = {
            "customId": currentUserStoryData?.customId,
            // "newCustomId": userStoryData?.customId,
            // "title": userStoryData?.title,
            // "description": userStoryData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userStoryData?.assignedToEmail,
            // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
            // "status": userStoryData?.status,
            // "moduleName": currentFolderData?.data?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.data?.name,
            "moduleName": currentUserStoryData?.moduleName,
            "projectName": activeProject?.name,
        }

        if (userStoryData?.customId !== currentUserStoryData?.customId) {
            body.newCustomId = userStoryData?.customId
        }
        if (userStoryData?.title !== currentUserStoryData?.title) {
            body.title = userStoryData?.title
        }
        if (JSON.stringify(userStoryData?.description) !== JSON.stringify(currentUserStoryData?.description)) {
            body.description = userStoryData?.description
        }
        if (userStoryData?.priority !== currentUserStoryData?.priority) {
            body.priority = userStoryData?.priority
        }
        if (userStoryData?.assignedToEmail !== currentUserStoryData?.assignedToEmail) {
            body.assignedToEmail = userStoryData?.assignedToEmail
        }
        if (JSON.stringify(userStoryData?.acceptanceCriteria) !== JSON.stringify(currentUserStoryData?.acceptanceCriteria)) {
            body.acceptanceCriteria = userStoryData?.acceptanceCriteria
        }
        if (userStoryData?.status !== currentUserStoryData?.status) {
            body.status = userStoryData?.status
        }

        // handleContinue(body);

        handleUpdateStory(body);
    }

    const handleGetStatusList = async () => {
        try {
            // setGlobalLoader(true);

            const response = await getStoryStatusList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setStatusList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleUpdateStory = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
            }


            const response = await updateStory_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setEnableEdit(false);

                handleCloseSectionEdit();
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {
            setLoading(true);

            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    useEffect(() => {
        if (enableEdit) {
            handleGetStatusList();
        }
    }, [enableEdit]);


    useEffect(() => {
        setLoading(true);
        setUserStoryData(currentUserStoryData);
        setEditorValue(currentUserStoryData?.description);

        setTimeout(() => {
            setLoading(false);
        }, 700)
    }, [currentUserStoryData]);



    console.log(userStoryData, "userStoryData", userStoryData?.name, userStoryData?.status);
    console.log(editorValue, "textvalue")
    console.log(selectedFiles, "selectedFiles")
    console.log(sections, "sections")

    if (loading) {
        return (
            <Box className="flex flex-col items-center justify-center gap-2 w-full h-full z-20 absolute left-0 top-0 overflow-hidden "
                // bgcolor={theme.palette.primary.layout_col}
                borderRadius={theme.shape.borderRadius}
                bgcolor={theme.palette.common.white}
            >
                <CircularProgress />
                <Typography fontSize={font.size.fs_4}>Loading...</Typography>
            </Box>
        )
    }

    if (true) {
        return (
            <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 overflow-hidden shadow-lg"
                // bgcolor={theme.palette.primary.layout_col}
                borderRadius={theme.shape.borderRadius}
                bgcolor={theme.palette.common.white}
            >

                {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                    <CircularProgress />
                </Box>}

                <Box className={`flex flex-col  px-4 py-4 gap-2 w-full h-full relative rounded-lg overflow-hidden`} >

                    {/* top */}
                    <Box className="w-full flex items-center justify-between gap-4 ">
                        {/* left */}
                        <Box className="flex-1 flex items-center gap-2 w-full  pr-4 ">

                            <IconButton
                                size="small"
                                value="check"
                                // selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                                onClick={closeDialog}
                            >
                                <ArrowBack />
                            </IconButton>


                            <Box className="w-full flex flex-col ">
                                {/* custom Id */}
                                <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.customId)}>
                                    {enableEdit && enableEdit === EditSection.customId &&
                                        <HeadingComponent heading="Custom ID" hideColon={enableEdit && enableEdit === EditSection.customId} />}

                                    {enableEdit && enableEdit === EditSection.customId ?
                                        <Box className="w-full flex flex-col gap-0">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                name="customId"
                                                // label="Custom ID"
                                                required
                                                fullWidth
                                                // focused={userStoryData?.customId}
                                                // autoFocus
                                                value={userStoryData?.customId}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        :
                                        <Box className="flex items-center gap-1">
                                            <Description color="secondary" sx={{ fontSize: font.size.fs_4 }} />
                                            <ValueComponent color={theme.palette.secondary.dark} text={currentUserStoryData?.customId} />
                                        </Box>
                                    }
                                </SectionContainer>


                                {/* Title */}
                                <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.title)}>
                                    {enableEdit && enableEdit === EditSection.title &&
                                        <HeadingComponent heading="Title" hideColon={enableEdit && enableEdit === EditSection.title ? true : false} />}

                                    {enableEdit && enableEdit === EditSection.title ?
                                        <Box className="w-full flex flex-col gap-0">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                name="title"
                                                required
                                                // label="Title"
                                                // autoFocus
                                                fullWidth
                                                multiline
                                                minRows={1}
                                                maxRows={2}
                                                // focused={userStoryData?.title}
                                                value={userStoryData?.title}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-0">
                                            <Typography fontSize={font.size.fs_3_5} fontWeight={500} className="" sx={{color : theme.palette.text.primary}}>{currentUserStoryData?.title}</Typography>
                                        </Box>
                                    }
                                </SectionContainer>
                            </Box>
                        </Box>

                        {/* right */}
                        <Box className=" w-[32%] flex items-center justify-end gap-2">

                            {/* <ToggleButton
                                value="check"
                                selected={false}
                                sx={{ color: isItemChatWindow && theme.palette.secondary.dark, background: isItemChatWindow && theme.palette.secondary.light }}
                                // onClick={handleToggleAIWindow}
                                onClick={handleOpenAIPopup}
                            >
                                <AutoAwesome />
                            </ToggleButton> */}

                            {false && <LightTooltip title="Generate Use Case" >
                                <IconButton color="secondary" onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)}>
                                    {currentAIEntityData?.entity === AI_Entity.useCase ? <Description /> : <DescriptionOutlined />}
                                </IconButton>
                            </LightTooltip>}

                            {false && <LightTooltip color="secondary" title="Generate Test Case">
                                <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}>
                                    {currentAIEntityData?.entity === AI_Entity.testCase ? <Description /> : <DescriptionOutlined />}
                                </IconButton>
                            </LightTooltip>}

                            <LightTooltip title="Settings">
                                <IconButton>
                                    <Settings />
                                </IconButton>
                            </LightTooltip>

                            {false && <Button
                                size="medium"
                                variant={isItemChatWindow ? "contained" : "outlined"}
                                disableElevation
                                onClick={handleOpenAIPopup}
                                startIcon={<AutoAwesome />}
                            // endIcon={<KeyboardArrowDown />}
                            >
                                {isItemChatWindow && <Description sx={{ fontSize: font.size.fs_4 }} />}
                                {!isItemChatWindow ? "Generate" : currentAIEntityData?.label}
                            </Button>}


                            {false && <ToggleButton
                                size="small"
                                value="check"
                                // disabled={currentFolderData?.data?.type !== FolderType.module}
                                selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            >
                                <Edit />
                            </ToggleButton>}

                            {false && <ToggleButton
                                size="small"
                                value="check"
                                selected={false}
                                disabled={!enableEdit || !handleCheckValues()}
                                onClick={() => handleContinueStory(userStoryData)}
                            >
                                <Save />
                            </ToggleButton>}

                            {/* <IconButton
                                disabled={currentFolderData?.data?.type !== FolderType.module || !handleCheckValues()}
                                onClick={() => handleContinueStory(userStoryData)}
                            >
                                <Save />
                            </IconButton> */}

                            {/* <ToggleButton
                                size="small"
                                value="check"
                                selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                                onClick={closeDialog}
                            >
                                <Close />
                            </ToggleButton> */}
                        </Box>
                    </Box>


                    {/* bottom */}
                    <Box className="w-full  flex gap-4 overflow-hidden ">
                        {/* left */}
                        <Box className={`flex-1 flex flex-col   gap-2  pr-4 overflow-y-auto border-r border-gray-300`}>


                            {/* menu */}
                            <Box className="w-full flex items-center mb-4 gap-4">
                                <Button size="small" variant="outlined" startIcon={<Attachment sx={{ transform: "rotate(90deg)" }} />} disabled={sections[EditSection.attachments]} onClick={() => handleToggleSection(EditSection.attachments)}>Attachment</Button>
                                <Button size="small" variant="outlined" startIcon={<PersonAddAltIcon />} disabled={sections[EditSection.members]} onClick={() => handleToggleSection(EditSection.members)}>Members</Button>
                                <Button size="small" variant="outlined" startIcon={<LabelIcon />} disabled={sections[EditSection.label]} onClick={() => handleToggleSection(EditSection.label)}>Labels</Button>
                                <Button size="small" variant="outlined" startIcon={<ChecklistIcon />} disabled={sections[EditSection.checklist]} onClick={() => handleToggleSection(EditSection.checklist)}>Checklist</Button>
                                <IconButton sx={{ borderRadius: theme.shape.borderRadius }} onClick={handleToggleMenuPopup}>
                                    <MoreHoriz />
                                </IconButton>
                            </Box>


                            {/* main container */}
                            <Box className="flex flex-col gap-4">
                                {/* <TextField
                                size="small"
                                variant="outlined"
                                name="description"
                                // autoFocus
                                required
                                label="Description"
                                fullWidth
                                multiline
                                minRows={6}
                                maxRows={6}
                                // focused={userStoryData?.description}
                                value={userStoryData?.description}
                                onChange={handleChange}
                            /> */}


                                {/* Description */}
                                <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.description)}>
                                    <HeadingComponent heading="Description" hideColon={enableEdit && enableEdit === EditSection.description ? true : false} />
                                    {enableEdit && enableEdit === EditSection.description ?
                                        <Box className="flex flex-col gap-0">
                                            <QuillContainer>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={userStoryData?.description}
                                                    onChange={(value) => setUserStoryData({ ...userStoryData, description: value })}
                                                    placeholder="Description"
                                                    modules={{
                                                        toolbar: [
                                                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                            ['bold', 'italic', 'underline', 'strike'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            // [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                            // ['link', 'image', 'video'],
                                                            // ['blockquote', 'code-block'],
                                                            // [{ 'align': [] }],
                                                            // [{ 'color': [] }, { 'background': [] }],
                                                            // ['clean'],
                                                            // [CustomButton]
                                                            // [{ 'label': 'Add', 'icon': Add, 'onClick': handleAddAcceptanceCriteria }],

                                                        ],
                                                    }}
                                                />
                                            </QuillContainer>
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-0">
                                            {/* <Box>{convertEditorDataToReact(currentUserStoryData?.description)}</Box> */}
                                            <ValueComponent text={convertEditorDataToReact(currentUserStoryData?.description)} />
                                        </Box>
                                    }
                                </SectionContainer>


                                {/* Acceptance Criteria */}
                                <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.acceptanceCriteria)}>
                                    <HeadingComponent heading="Acceptance Criteria" hideColon={enableEdit && enableEdit === EditSection.acceptanceCriteria ? true : false} />
                                    {enableEdit && enableEdit === EditSection.acceptanceCriteria ?
                                        <Box className={`w-full flex flex-col gap-1 ${userStoryData?.acceptanceCriteria?.length > 0 ? "h-48" : ""}`}>
                                            {userStoryData?.acceptanceCriteria?.length > 0 ?
                                                userStoryData?.acceptanceCriteria?.map((currentStep, index) => (
                                                    <Box
                                                        key={index}
                                                        className="flex items-center gap-1  relative"
                                                    >
                                                        <LightTooltip title="Add Step below" >
                                                            <IconButton
                                                                onClick={() => handleAddRowBelow(index)}
                                                                sx={{ position: "relative", top: "6px" }}
                                                            >
                                                                <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                            </IconButton>
                                                        </LightTooltip>

                                                        <Typography fontSize={font.size.fs_4} className="">{index + 1}.</Typography>

                                                        <Box
                                                            className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                            draggable
                                                            onDragStart={(e) => handleDragStart(index, e)}
                                                            onDragOver={(e) => handleDragOver(e)}
                                                            onDrop={(e) => handleDrop(index, e)}
                                                        >


                                                            <IconButton>
                                                                <DragHandle />
                                                            </IconButton>

                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Criteria"
                                                                value={currentStep}
                                                                onChange={(e) => handleCurrentListItem(index, e.target.value)}
                                                                sx={{
                                                                    px: 0,
                                                                    "& .MuiOutlinedInput-root": {
                                                                        fontSize: font.size.fs_5,
                                                                        "& fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                            // borderColor: theme.palette.common.black
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <IconButton color="error" onClick={() => handleRemoveListItem(index)}><Delete /></IconButton>
                                                        </Box>
                                                    </Box>
                                                ))

                                                :
                                                <Box className="flex items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                                    <Typography>Add</Typography>
                                                    <IconButton onClick={() => handleAddRowBelow(0)}>
                                                        <Add />
                                                    </IconButton>
                                                </Box>

                                            }

                                        </Box>
                                        :
                                        <Box className="w-full flex flex-col gap-1">

                                            {userStoryData?.acceptanceCriteria?.length > 0 ? userStoryData?.acceptanceCriteria?.map((criteria, index) => (
                                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" >
                                                    <Box className="w-2 h-2 rounded-full bg-gray-700"></Box>
                                                    {/* <Typography fontSize={font.size.fs_4} className="flex-1">{criteria}</Typography> */}
                                                    <ValueComponent text={criteria} />
                                                </Box>
                                            ))
                                                :

                                                <ValueComponent text={"None"} />
                                            }

                                        </Box>
                                    }
                                </SectionContainer>

                                {/* checklist */}
                                {sections[EditSection.checklist] && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.checklist)}>
                                    <HeadingComponent heading="Checklist" hideColon={enableEdit && enableEdit === EditSection.checklist ? true : false} />
                                    {enableEdit && enableEdit === EditSection.checklist ?
                                        <Box className={`w-full flex flex-col gap-1 ${userStoryData?.checklist?.length > 0 ? "h-48" : ""}`}>
                                            {userStoryData?.checklist?.length > 0 ?
                                                userStoryData?.checklist?.map((currentStep, index) => (
                                                    <Box
                                                        key={index}
                                                        className="flex items-center gap-1  relative"
                                                    >
                                                        <LightTooltip title="Add Step below" >
                                                            <IconButton
                                                                onClick={() => handleAddRowBelow(index)}
                                                                sx={{ position: "relative", top: "6px" }}
                                                            >
                                                                <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                            </IconButton>
                                                        </LightTooltip>

                                                        <Typography fontSize={font.size.fs_4} className="">{index + 1}.</Typography>

                                                        <Box
                                                            className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                            draggable
                                                            onDragStart={(e) => handleDragStart(index, e)}
                                                            onDragOver={(e) => handleDragOver(e)}
                                                            onDrop={(e) => handleDrop(index, e)}
                                                        >


                                                            <IconButton>
                                                                <DragHandle />
                                                            </IconButton>

                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Criteria"
                                                                value={currentStep}
                                                                onChange={(e) => handleCurrentListItem(index, e.target.value)}
                                                                sx={{
                                                                    px: 0,
                                                                    "& .MuiOutlinedInput-root": {
                                                                        fontSize: font.size.fs_5,
                                                                        "& fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                            // borderColor: theme.palette.common.black
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <IconButton color="error" onClick={() => handleRemoveListItem(index)}><Delete /></IconButton>
                                                        </Box>
                                                    </Box>
                                                ))

                                                :
                                                <Box className="flex items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                                    <Typography>Add</Typography>
                                                    <IconButton onClick={() => handleAddRowBelow(0)}>
                                                        <Add />
                                                    </IconButton>
                                                </Box>

                                            }

                                        </Box>
                                        :
                                        <Box className="w-full flex flex-col gap-1">

                                            {userStoryData?.checklist?.length > 0 ? userStoryData?.checklist?.map((criteria, index) => (
                                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" >
                                                    <Box className="w-2 h-2 rounded-full bg-gray-700"></Box>
                                                    {/* <Typography fontSize={font.size.fs_4} className="flex-1">{criteria}</Typography> */}
                                                    <ValueComponent text={criteria} />
                                                </Box>
                                            ))
                                                :

                                                <ValueComponent text={"None"} />
                                            }

                                        </Box>
                                    }
                                </SectionContainer>}

                                {/* Attachment */}
                                {sections[EditSection.attachments] && <SectionContainer >
                                    <Box className="flex  items-center justify-between gap-0">
                                        <HeadingComponent heading="Attachment" hideColon={enableEdit && enableEdit === EditSection.attachments ? true : false} />

                                        <IconButton size="small" onClick={() => fileInputRef.current.click()} >
                                            <Attachment sx={{ transform: "rotate(90deg)" }} />
                                        </IconButton>

                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={handleChangeFiles}
                                            style={{ display: "none" }}
                                        />

                                    </Box>


                                    {enableEdit && enableEdit === EditSection.attachments
                                        ?
                                        <Box className="w-full flex flex-col justify-between gap-1">
                                            {/* uploaded attachments list */}
                                            {selectedFiles?.length > 0 &&
                                                <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                    {selectedFiles?.map((file, index) => (
                                                        <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                            <img src={URL.createObjectURL(file)} alt={file.name} className="object-fill w-full h-full overflow-hidden" />

                                                            <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                <Close />
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>}
                                        </Box>
                                        :
                                        <Box className="w-full flex flex-col justify-between gap-1">

                                            {/* preview of the selected attachement */}
                                            {selectedFiles?.length === 0 ?
                                                <Box className="flex items-center gap-4">
                                                    <ValueComponent text={"None"} />
                                                </Box>
                                                :
                                                <Box className="flex items-center gap-4">

                                                    {selectedFiles?.length > 0 &&
                                                        <Box className="flex-1 flex items-center flex-wrap gap-4 border-2 border-dashed border-gray-400 p-2 rounded-lg">
                                                            {selectedFiles?.map((file, index) => (
                                                                <Box key={index} className="w-12 h-12 rounded-md shadow relative">
                                                                    <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                                    <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: -5, right: -5 }}>
                                                                        <Close sx={{ fontSize: font.size.fs_6 }} />
                                                                    </IconButton>
                                                                </Box>
                                                            ))}
                                                        </Box>}

                                                    <IconButton>
                                                        <Upload />
                                                    </IconButton>

                                                </Box>}

                                            {/* uploaded attachments list */}
                                            {selectedFiles?.length > 0 &&
                                                <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                    {selectedFiles?.map((file, index) => (
                                                        <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                            <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                            <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                <Close />
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>}

                                        </Box>

                                    }
                                </SectionContainer>}

                                {/* Linked Use Case list */}
                                <SectionContainer>
                                    <Box className="flex items-center justify-between">
                                        <HeadingComponent heading="Linked Use Case" className={"flex-1"} />
                                        <LightTooltip title="Generate using AI" >
                                            <IconButton onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)}>
                                                {currentAIEntityData?.entity === AI_Entity.useCase ? <AutoAwesome /> : <AutoAwesomeOutlined />}
                                            </IconButton>
                                        </LightTooltip>
                                        <LightTooltip title="Link " >
                                            <IconButton onClick={() => handleOpenLinkTag(currentUserStoryData)}>
                                                <LinkIcon />
                                            </IconButton>
                                        </LightTooltip>
                                    </Box>

                                    {enableEdit && enableEdit === EditSection.linked ?
                                        <Box className={`w-full  flex flex-col gap-1 ${currentUserStoryData?.useCases?.length === 0 ? "h-auto" : " min-h-52 max-h-52"}`}>

                                            {/* table */}
                                            <Box className="flex-1 flex flex-col">
                                                {currentUserStoryData?.useCases?.length === 0 ?
                                                    <ValueComponent text={"None"} />
                                                    :
                                                    (
                                                        <TableVirtuoso
                                                            style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                            data={currentUserStoryData?.useCases}
                                                            components={TableComponents}
                                                            fixedHeaderContent={() => (
                                                                <TableHead>


                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                                    </TableCell>
                                                                </TableHead>
                                                            )}


                                                            itemContent={(index, itemData) => (

                                                                <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="py-1">{index + 1}.</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                        <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                            <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                        </LightTooltip>
                                                                    </TableCell>

                                                                </TableRow>

                                                            )}
                                                        />
                                                    )
                                                }

                                            </Box>
                                        </Box>
                                        :
                                        <Box className={`w-full  flex flex-col gap-1 ${currentUserStoryData?.useCases?.length === 0 ? "h-auto" : "min-h-52 max-h-52"}`}>

                                            {/* table */}
                                            <Box className="flex-1 flex flex-col">
                                                {currentUserStoryData?.useCases?.length === 0 ?
                                                    <ValueComponent text={"None"} />
                                                    :
                                                    (
                                                        <TableVirtuoso
                                                            style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                            data={currentUserStoryData?.useCases}
                                                            components={TableComponents}
                                                            fixedHeaderContent={() => (
                                                                <TableHead>


                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                                    </TableCell>
                                                                </TableHead>
                                                            )}


                                                            itemContent={(index, itemData) => (

                                                                <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="py-1">{index + 1}.</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                        <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                            <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                        </LightTooltip>
                                                                    </TableCell>

                                                                </TableRow>

                                                            )}
                                                        />
                                                    )
                                                }

                                            </Box>
                                        </Box>

                                    }
                                </SectionContainer>

                                {/* linked Test Case List */}
                                <SectionContainer>
                                    <Box className="flex items-center justify-between">
                                        <HeadingComponent heading="Linked Test Case" className={"flex-1"} />


                                        <LightTooltip title="Generate using AI">
                                            <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}>
                                                {currentAIEntityData?.entity === AI_Entity.testCase ? <AutoAwesome /> : <AutoAwesomeOutlined />}
                                            </IconButton>
                                        </LightTooltip>
                                        <LightTooltip title="Link ">
                                            <IconButton onClick={() => handleOpenLinkTag(currentUserStoryData)}>
                                                <LinkIcon />
                                            </IconButton>
                                        </LightTooltip>
                                    </Box>

                                    {enableEdit && enableEdit === EditSection.linkedTestCase ?
                                        <Box className={`w-full  flex flex-col gap-1 ${!currentUserStoryData?.testCase || currentUserStoryData?.testCases?.length === 0 ? "h-auto" : " min-h-52 max-h-52"}`}>

                                            {/* table */}
                                            <Box className="flex-1 flex flex-col">
                                                {!currentUserStoryData?.testCase || currentUserStoryData?.testCases?.length === 0 ?
                                                    <ValueComponent text={"None"} />
                                                    :
                                                    (
                                                        <TableVirtuoso
                                                            style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                            data={currentUserStoryData?.testCases}
                                                            components={TableComponents}
                                                            fixedHeaderContent={() => (
                                                                <TableHead>


                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                                    </TableCell>
                                                                </TableHead>
                                                            )}


                                                            itemContent={(index, itemData) => (

                                                                <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="py-1">{index + 1}.</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                        <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                            <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                        </LightTooltip>
                                                                    </TableCell>

                                                                </TableRow>

                                                            )}
                                                        />
                                                    )
                                                }

                                            </Box>
                                        </Box>
                                        :
                                        <Box className={`w-full  flex flex-col gap-1 ${!currentUserStoryData?.testCase || currentUserStoryData?.testCases?.length === 0 ? "h-auto" : "min-h-52 max-h-52"}`}>

                                            {/* table */}
                                            <Box className="flex-1 flex flex-col">
                                                {!currentUserStoryData?.testCase || currentUserStoryData?.testCases?.length === 0 ?
                                                    <ValueComponent text={"None"} />
                                                    :
                                                    (
                                                        <TableVirtuoso
                                                            style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                            data={currentUserStoryData?.testCases}
                                                            components={TableComponents}
                                                            fixedHeaderContent={() => (
                                                                <TableHead>


                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> S.No.</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Custom ID</Typography>
                                                                    </TableCell>

                                                                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> Title</Typography>
                                                                    </TableCell>
                                                                </TableHead>
                                                            )}


                                                            itemContent={(index, itemData) => (

                                                                <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="py-1">{index + 1}.</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                        <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                                    </TableCell>

                                                                    <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                                                                        <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                            <Typography className="px-2 line-clamp-1 py-1"> {itemData?.title}</Typography>
                                                                        </LightTooltip>
                                                                    </TableCell>

                                                                </TableRow>

                                                            )}
                                                        />
                                                    )
                                                }

                                            </Box>
                                        </Box>

                                    }
                                </SectionContainer>

                            </Box>
                        </Box>


                        {/* right */}
                        <Box className={`w-[32%] h-full flex flex-col py-1 box-border gap-4 overflow-y-auto`}>

                            {/* main container */}
                            <Box className={`flex flex-col gap-2 `}>

                                {/* details */}
                                <CustomAccordionComponent
                                    expanded={expandedAccordion === 'details'}
                                    onChange={handleChangeAccordion('details')}
                                >
                                    <CustomAccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <HeadingComponent heading="Details" />
                                    </CustomAccordionComponentSummary>
                                    <CustomAccordionComponentDetails>
                                        <AccordionDetailsContainer>
                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)}>
                                                {enableEdit && enableEdit === EditSection.assignedTo ?
                                                    <KeyValuePairComponent
                                                        heading={"Owner"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                            // disabled={!userStoryData?.assignedTo}
                                                            // value={userStoryData?.assignedTo || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Owner" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.assignedTo || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)}>
                                                {enableEdit && enableEdit === EditSection.assignedTo ?
                                                    <KeyValuePairComponent
                                                        heading={"Creation Date"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                            // disabled={!userStoryData?.assignedTo}
                                                            // value={userStoryData?.assignedTo || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Creation Date" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.assignedTo || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)}>
                                                {enableEdit && enableEdit === EditSection.assignedTo ?
                                                    <KeyValuePairComponent
                                                        heading={"Reporter"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                            // disabled={!userStoryData?.assignedTo}
                                                            // value={userStoryData?.assignedTo || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Assigned To" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.assignedTo || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.priority)}>
                                                {enableEdit && enableEdit === EditSection.priority ?
                                                    <KeyValuePairComponent
                                                        heading={"Priority"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="priority"
                                                                fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            // value={userStoryData?.status || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["HIGH", "MEDIUM", "LOW",]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Priority" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.status)}>
                                                {enableEdit && enableEdit === EditSection.status ?
                                                    <KeyValuePairComponent
                                                        heading={"Story Stage"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                                disabled={!userStoryData?.status}
                                                                value={userStoryData?.status || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    statusList?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="w-full flex flex-col gap-0">
                                                        <HeadingComponent heading="Story Stage" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.status || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            {sections[EditSection.label] && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.label)}>
                                                {enableEdit && enableEdit === EditSection.label ?
                                                    <KeyValuePairComponent
                                                        heading={"Label"}
                                                        value={
                                                            <Box className="flex items-center gap-2">
                                                                {
                                                                    ["green", "red", "yellow", "blue"]?.map((label, index) => <LabelIcon sx={{ color: label, }} />)
                                                                }
                                                            </Box>
                                                        }
                                                    />
                                                    :
                                                    <Box className="w-full flex flex-col gap-0">
                                                        <HeadingComponent heading="Label" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.status || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInRelease)}>
                                                {enableEdit && enableEdit === EditSection.plannedInRelease ?
                                                    <KeyValuePairComponent
                                                        heading={"Planned in Release"}
                                                        value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Planned in Release" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInSprint)}>
                                                {enableEdit && enableEdit === EditSection.plannedInSprint ?
                                                    <KeyValuePairComponent heading={"Planned in Sprint"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Planned in Sprint" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}


                                        </AccordionDetailsContainer>
                                    </CustomAccordionComponentDetails>
                                </CustomAccordionComponent>

                                {/* estimation/ story points */}
                                <CustomAccordionComponent
                                    expanded={expandedAccordion === ('estimation-storyPoints')}
                                    onChange={handleChangeAccordion('estimation-storyPoints')}
                                >
                                    <CustomAccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <HeadingComponent heading="Estimation/Story Points" />
                                    </CustomAccordionComponentSummary>
                                    <CustomAccordionComponentDetails>

                                        <AccordionDetailsContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPoints)}>
                                                {enableEdit && enableEdit === EditSection.userPoints ?
                                                    <KeyValuePairComponent heading={"Planned Value"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Planned Value" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)}>
                                                {enableEdit && enableEdit === EditSection.confidenciality ?
                                                    <KeyValuePairComponent heading={"Actual Value"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Actual Value" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>
                                        </AccordionDetailsContainer>
                                    </CustomAccordionComponentDetails>
                                </CustomAccordionComponent>

                                {/* other fields */}
                                <CustomAccordionComponent
                                    expanded={expandedAccordion === ('otherFields')}
                                    onChange={handleChangeAccordion('otherFields')}
                                >
                                    <CustomAccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <HeadingComponent heading="Other Fields" />
                                    </CustomAccordionComponentSummary>
                                    <CustomAccordionComponentDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.storyType)}>
                                                {enableEdit && enableEdit === EditSection.storyType ?
                                                    <KeyValuePairComponent heading={"Story Type"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} /> :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Story Type" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.storyType || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.moscow)}>
                                                {enableEdit && enableEdit === EditSection.moscow ?
                                                    <KeyValuePairComponent
                                                        heading={"MOSCOW"}
                                                        value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="priority"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Must have", "Should have", "Could have", "Won't have"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="MOSCOW" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.moscow || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.businessValue)}>
                                                {enableEdit && enableEdit === EditSection.businessValue ?
                                                    <KeyValuePairComponent heading={"Bussiness Value"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Bussiness Value" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPoints)}>
                                                {enableEdit && enableEdit === EditSection.userPoints ?
                                                    <KeyValuePairComponent heading={"User Points"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="User Points" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)}>
                                                {enableEdit && enableEdit === EditSection.confidenciality ?
                                                    <KeyValuePairComponent heading={"Confidenciality"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Confidenciality" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.verificationMethod)}>
                                                {enableEdit && enableEdit === EditSection.verificationMethod ?
                                                    <KeyValuePairComponent heading={"Verification Methods"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Testing", "Inspection", "Demo", "Analysis"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Verification Methods" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPersona)}>
                                                {enableEdit && enableEdit === EditSection.userPersona ?
                                                    <KeyValuePairComponent heading={"User Persona"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="User Persona" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}



                                        </AccordionDetailsContainer>
                                    </CustomAccordionComponentDetails>
                                </CustomAccordionComponent>

                                {/* Tag to  */}
                                <CustomAccordionComponent
                                    expanded={expandedAccordion === ('tagTo')}
                                    onChange={handleChangeAccordion('tagTo')}
                                >
                                    <AccordionSummary expandIcon={<ExpandMore />}    >
                                        <HeadingComponent heading="Tag To" />
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.businessUnit)}>
                                                {enableEdit && enableEdit === EditSection.businessUnit ?
                                                    <KeyValuePairComponent heading={"Bussiness Unit"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Bussiness Unit" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.vendor)}>
                                                {enableEdit && enableEdit === EditSection.vendor ?
                                                    <KeyValuePairComponent heading={"Vendor"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Vendor" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.externalPoints)}>
                                                {enableEdit && enableEdit === EditSection.externalPoints ?
                                                    <KeyValuePairComponent heading={"External Party"} value={<KeyValueValueComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="External Party" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>
                                        </AccordionDetailsContainer>
                                    </AccordionDetails>
                                </CustomAccordionComponent>

                            </Box>

                        </Box>
                    </Box>
                </Box>



                {/*  popup for current edit section */}
                <div style={{ pointerEvents: 'none' }}>
                    <Popover
                        id="edit-section"
                        open={Boolean(editAnchorEl)}
                        anchorEl={editAnchorEl}
                        hideBackdrop
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}

                        slotProps={{
                            paper: {
                                sx: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                }
                            }
                        }}
                    >
                        <div style={{ pointerEvents: 'auto' }}>
                            <Box className="flex-1 flex items-center gap-1 py-1">

                                <ToggleButton
                                    size="small"
                                    value="check"
                                    selected={false}
                                    disabled={!enableEdit || !handleCheckValues()}
                                    onClick={() => handleContinueStory(userStoryData)}

                                >
                                    <Save />
                                </ToggleButton>


                                <ToggleButton
                                    size="small"
                                    value="check"
                                    onClick={handleCloseSectionEdit}

                                >
                                    <Close />
                                </ToggleButton>

                            </Box>
                        </div>
                    </Popover>
                </div>

                {/* popup for current AI entity */}
                <Popover
                    id="AI-Entity"
                    open={Boolean(aiAnchorEl)}
                    anchorEl={aiAnchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleCloseAIPopup}
                >
                    <MenuItem
                        selected={currentAIEntityData?.entity === AI_Entity.useCase}
                        onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)}
                        sx={{
                            color: currentAIEntityData?.entity === AI_Entity.useCase ? theme.palette.primary.main : "",
                            backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.useCase?.toLowerCase() ? theme.palette.secondary.main : ""
                        }}
                    >
                        {/* <ListItemIcon>
                            <Description fontSize="small" />
                        </ListItemIcon> */}
                        {/* <ListItemText>{Entity_Label.useCase}</ListItemText> */}
                        {Entity_Label.useCase}
                    </MenuItem>

                    <MenuItem
                        selected={currentAIEntityData?.entity === AI_Entity.testCase}
                        onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}
                        sx={{
                            color: currentAIEntityData?.entity === AI_Entity.testCase ? theme.palette.primary.main : "",
                            backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.testCase?.toLowerCase() ? theme.palette.secondary.main : ""
                        }}
                    >
                        {/* <ListItemIcon>
                            <Description fontSize="small" />
                        </ListItemIcon> */}
                        {/* <ListItemText>{Entity_Label.testCase}</ListItemText> */}
                        {Entity_Label.testCase}
                    </MenuItem>

                </Popover>

                {/* menu items */}
                <Popover
                    id="AI-Entity"
                    open={Boolean(menuAnchorEl)}
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleToggleMenuPopup}
                >
                    <Box>
                        <MenuItem onClick={() => handleToggleSection(EditSection.dueDate)}>Due Date</MenuItem>
                        <MenuItem onClick={() => handleToggleSection(EditSection.userPersona)}>User Persona</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { }}>Move</MenuItem>
                        <MenuItem onClick={() => { }}>Copy</MenuItem>
                        <MenuItem onClick={() => { }}>Delete</MenuItem>
                    </Box>
                </Popover>


                {/* {editAnchorEl && <Box
                    style={{
                        position: "absolute",
                        // top: 0,
                        // left: 0,
                        // top: editAnchorEl.getBoundingClientRect().top ,
                        // left: editAnchorEl.getBoundingClientRect().right 
                        top: editAnchorEl.getBoundingClientRect().top + window.scrollY,
                        left: editAnchorEl.getBoundingClientRect().right + window.scrollX
                    }}
                >
                    element
                </Box>} */}
            </Box>
        )
    }

    return (
        <Box className="flex flex-col px-4 pt-4 w-full h-full z-20 absolute left-0 top-0 rounded-lg"
            // bgcolor={theme.palette.primary.layout_col}
            bgcolor={theme.palette.common.white}
        >
            <Box className="flex items-center  pb-4 gap-4">
                <Typography fontSize={font.size.fs_3} className="flex-1">Edit </Typography>

                <Box className="flex items-center gap-4">
                    <Button variant="contained" color="inherit" size="small" onClick={closeDialog} >Close</Button>
                    <Button variant="contained" disabled={currentFolderData?.data?.type !== FolderType.module || !handleCheckValues()} size="small" onClick={() => handleContinueStory(userStoryData)} >Save</Button>
                </Box>
            </Box>


            <Box className="flex  gap-4 py-4 flex-1 overflow-y-auto">
                <Box className="flex flex-col flex-1 gap-4 pb-4">
                    <TextField size="small" variant="outlined" name="customId" required label="Custom ID" focused={userStoryData?.customId} autoFocus value={userStoryData?.customId} onChange={handleChange} />
                    <TextField size="small" variant="outlined" name="title" required label="Title" autoFocus fullWidth focused={userStoryData?.title} value={userStoryData?.title} onChange={handleChange} />

                    <TextField size="small" variant="outlined" name="description" autoFocus required label="Description" fullWidth multiline minRows={6} maxRows={6}
                        focused={userStoryData?.description} value={userStoryData?.description} onChange={handleChange} />
                    <Box className="flex flex-col gap-2">

                        <Box className="flex items-center gap-4">
                            <TextField size="small" label="Enter acceptance criteria" fullWidth value={accetanceCriteriaText} onChange={(e) => setAccetanceCriteriaText(e.target.value)} />
                            <Button size="small" variant="outlined" disabled={!accetanceCriteriaText} startIcon={<Add />} onClick={handleAddAcceptanceCriteria} >Add</Button>
                        </Box>

                        <Box className="flex flex-col gap-2">
                            {userStoryData?.acceptanceCriteria?.map((criteria, index) => (
                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" bgcolor={theme.palette.common.white}>
                                    <Typography fontSize={font.size.fs_5} className="flex-1">{criteria}</Typography>
                                    <IconButton size="small" onClick={() => handleRemoveAcceptanceCriteria(index)}><Close /></IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {/* <Box className="flex justify-end gap-2">
                    <Button size="small" variant="outlined" startIcon={<Close />} onClick={closeDialog} sx={{ borderRadius: "28px" }}>Cancel</Button>
                    <Button size="small" variant="contained" startIcon={<Add />} onClick={handleContinue} sx={{ borderRadius: "28px" }}>Continue</Button>
                </Box> */}



                </Box>

                <Box className="w-1/2 flex flex-col gap-4">
                    <Box className="w-full flex gap-4">
                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="Category" fullWidth defaultValue={userStoryData?.category} />
                            <TextField size="small" name="assignedToEmail" label="Assigned To" fullWidth value={userStoryData?.assignedToEmail} onChange={handleChange} />
                            <TextField size="small" label="Story Points" fullWidth defaultValue={userStoryData?.storyPoints} />
                            <TextField size="small" label="External Points" fullWidth defaultValue={userStoryData?.externalPoints} />
                        </Box>

                        <Box className="flex flex-col gap-4 w-full">
                            <TextField size="small" label="MOSCOW" fullWidth defaultValue={userStoryData?.moscow} />
                            <FormControl sx={{}} size="small">
                                <InputLabel id="demo-select-small-label">Status</InputLabel>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    name="status"
                                    label="Status"
                                    fullWidth
                                    disabled={!userStoryData?.status}
                                    value={userStoryData?.status || ''}
                                    onChange={handleChange}>
                                    {
                                        statusList?.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <TextField size="small" label="Verification Owner" fullWidth defaultValue={userStoryData?.verificationOwner} />
                            <TextField size="small" label="Sub Product" fullWidth defaultValue={userStoryData?.subProduct} />
                        </Box>
                    </Box>

                    <Box className="w-full flex flex-col px-3 py-2 gap-2 border border-gray-400 rounded-lg">
                        <Typography className="opacity-80">Verification Method</Typography>

                        <Box className="flex px-2 gap-2 ">
                            <FormControlLabel control={<Checkbox checked={userStoryData?.verificationMethod?.includes("Inspection")} />} label="Inspection" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox checked={userStoryData?.verificationMethod?.includes("Demo")} />} label="Demo" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox checked={userStoryData?.verificationMethod?.includes("Analysis")} />} label="Analysis" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                            <FormControlLabel control={<Checkbox checked={userStoryData?.verificationMethod?.includes("Testing")} />} label="Testing" className="pr-2 select-none rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out" />
                        </Box>
                    </Box>

                </Box>
            </Box>

            {/* <Box className="border-t border-gray-300 flex items-center justify-center gap-4 py-8">
                <Button variant="contained" color="inherit" size="small" startIcon={<Close />} onClick={closeDialog} >Close</Button>
                <Button variant="contained" size="small" startIcon={<Add />} onClick={() => handleContinueStory(userStoryData)} >Continue</Button>
            </Box> */}

        </Box>
    )
}

function KeyValuePairComponent({ heading, value }) {
    return (
        <Box className="w-full flex flex-col gap-1">
            <Box className="flex ">
                <HeadingComponent heading={heading} />
            </Box>
            <Box className="flex items-center flex-1 ">
                {value}
            </Box>
        </Box>
    )
}


function KeyValueHeadingComponent({ heading }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className="opacity-60">{heading}</Typography>
    )
}

function KeyValueValueComponent({ value }) {
    return (
        <Typography fontSize={font.size.fs_4}>{value}</Typography>
    )
}

function HeadingComponent({ heading, hideColon, ...props }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className={`${props.className} `} {...props}>{heading} {hideColon ? "" : ":"}</Typography>
    )
}

function ValueComponent({ text, ...props }) {
    return (
        <Typography fontSize={font.size.fs_5} className={`${props.className} `} {...props}> {text ? text : "None"} </Typography>
    )
}


function SectionContainer({ children, ...props }) {
    return (
        <Box className={`w-full flex flex-col gap-2 px-1 border border-transparent cursor-pointer ${props.onClick && "hover:border hover:border-gray-200"}  rounded-lg ${props.className} `} {...props}>
            {children}
        </Box>
    );
}


function AccordionDetailsContainer({ children, ...props }) {
    return (
        <Box className="flex flex-col gap-2 max-h-72 overflow-y-auto">
            {children}
        </Box>
    )
}