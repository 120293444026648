import { AccountCircle, AccountCircleOutlined, ArrowRightAlt, AttachFileOutlined, AutoAwesome, Close, CopyAll, FolderSpecialTwoTone, FolderTwoTone, KeyboardArrowDown, KeyboardArrowRight, Refresh, ReplayOutlined, RestartAltOutlined, Save, SaveAlt, SendOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, Icon, IconButton, LinearProgress, Popover, Skeleton, TextField, Typography, useTheme } from "@mui/material"
import { ApplicationContext, useApplicationContext } from "../../context/ApplicationContext";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { blue, grey, purple } from "@mui/material/colors";
import { font } from "../../styles/font";
import { CustomLoadingButton, LightTooltip } from "../styled/CustomComponent";
import { useEffect, useRef, useState } from "react";
import { AI_Entity, AI_Response_SentBy, Entity_Label, FolderType, ModulePath, Severity } from "../../constant/ApplicationVariables";
import { generateResponse_API, getConversationHistoryList_API, saveGeneratedResponse_API } from "../../api/controller/AIChatController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { getFolderStructureList_API } from "../../api/controller/RequirementController";
import { getStoryList_API } from "../../api/controller/StoryController";
import dayjs from "dayjs";
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { getUseCaseList_API } from "../../api/controller/UseCaseController";
import AutoSizer from "react-virtualized-auto-sizer";
import { Tree } from "react-arborist";



export default function ChatWindow() {
    const {
        toggleChatWindow, setToggleChatWindow,
        activeProject, setActiveProject,
        currentFolderData, setCurrentFolderData,
        handleSnackbar,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData,

        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
        getAILabel
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;

    let string = `As a user, I want to sign up with required information such as name, email, password, and phone number so that the system creates a new user profile.`

    const [isLoading, setIsLoading] = useState(false);
    const [isContentGenerationLoading, setIsContentGenerationLoading] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [responseList, setResponseList] = useState([]);
    const conversationResponseContainerRef = useRef(null);

    console.log(currentAIEntityData, "currentAIEntityDataa")

    // const getAILabel = () => {
    //     if (currentAIEntityData) {
    //         switch (currentFolderData?.data?.type) {
    //             case FolderType.project: return `AI assistance for: ${currentAIEntityData?.label}`;
    //             case FolderType.module: return `AI assistance for: ${currentAIEntityData?.label}`;
    //             default: return "using AI";
    //         }
    //     }
    // }

    const handleGetContext1 = () => {
        let name = "";
        let type = "";

        if (currentAIEntityData) {
            if (isItemChatWindow && currentAIEntityData) {
                if (currentFolderData?.data?.type === FolderType.project) {
                    type = pathName === ModulePath.requirements ? AI_Entity.userStory : AI_Entity.useCase;
                    name = pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title;
                }
                if (currentFolderData?.data?.type === FolderType.module) {
                    type = pathName === ModulePath.requirements ? AI_Entity.userStory : AI_Entity.useCase;
                    name = pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title;
                }

            } else {
                if (currentFolderData?.data?.type === FolderType.project) {
                    type = FolderType.project;
                    name = currentFolderData?.data?.name;
                }
                if (currentFolderData?.data?.type === FolderType.module) {
                    type = FolderType.module;
                    name = currentFolderData?.data?.name;
                }
            }
        }
        // `Module: ${currentFolderData?.data?.name}`

        return (
            <Box className="flex items-center gap-1 ">
                <Typography fontSize={font.size.fs_5} color={theme.palette.secondary.dark} className="capitalize">@{type}:</Typography>
                <Typography fontSize={font.size.fs_5}  >{name}</Typography>
            </Box>
        )
    }

    const handleGetContext = () => {
        let name = "";
        let type = "";

        if (currentAIEntityData) {
            if (isItemChatWindow) {
                if (currentFolderData?.data?.type === FolderType.project) {
                    type = pathName === ModulePath.requirements ? Entity_Label.userStory : Entity_Label.useCase;
                    name = pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title;
                }
                if (currentFolderData?.data?.type === FolderType.module) {
                    type = pathName === ModulePath.requirements ? Entity_Label.userStory : Entity_Label.useCase;
                    name = pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title;
                }

            } else {
                if (currentFolderData?.data?.type === FolderType.project) {
                    type = FolderType.project;
                    name = currentFolderData?.data?.name;
                }
                if (currentFolderData?.data?.type === FolderType.module) {
                    type = FolderType.module;
                    name = currentFolderData?.data?.name;
                }
            }
        }
        // `Module: ${currentFolderData?.data?.name}`

        return (
            <Box className="flex items-center gap-1 ">
                <Typography fontSize={font.size.fs_5} color={true ? theme.palette.primary.contrastText : theme.palette.secondary.dark} className="capitalize">@{type}:</Typography>
                <Typography fontSize={font.size.fs_5}  color={theme.palette.primary.contrastText}>{name}</Typography>
            </Box>
        )
    }

    const handleRefreshConversation = () => {
        handleGetConversationList(setIsLoading);
    }

    const handleClose = () => {
        setToggleChatWindow(false);

        handleCloseItemFromChatWindow();

        // if (isItemChatWindow) {
        //     setIsItemChatWindow(false);
        //     setCurrentUserStoryData(null);
        //     setCurrentUseCaseData(null);
        // }
    }

    // To close the file item context from AI window
    const handleCloseItemFromChatWindow = () => {

        if (isItemChatWindow) {
            setIsItemChatWindow(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleGenerate();
        }
    }

    const handleScrollToBottom = () => {
        console.log(conversationResponseContainerRef, "scroll conversationResponseContainerRef")
        if (conversationResponseContainerRef.current) {
            conversationResponseContainerRef.current.scroll({
                top: conversationResponseContainerRef.current.scrollHeight,
                // top: 0,
                behavior: 'smooth',
            });

            console.log(conversationResponseContainerRef.current.scrollHeight, "scroll")
        }
    }

    const handleGenerate = () => {
        setTimeout(() => {
            handleScrollToBottom();
        }, 400);
        handleGenerateResponse();
    }


    const handleGetConversationList = async (setLoader) => {
        try {
            setLoader(true);

            let body;

            // when AI Window is opened on particular item scope
            if (isItemChatWindow && (currentUserStoryData || currentUseCaseData)) {
                if (currentFolderData?.data?.type === FolderType.project) {
                    body = {
                        "projectName": activeProject?.name,
                        "entity": currentAIEntityData?.entity,
                        "moduleName": pathName === ModulePath.requirements ? currentUserStoryData?.moduleName : null,
                        "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                        "usecaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null
                    }
                }

                if (currentFolderData?.data?.type === FolderType.module) {
                    body = {
                        "projectName": activeProject?.name,
                        "entity": currentAIEntityData?.entity,
                        "moduleName": pathName === ModulePath.requirements ? currentUserStoryData?.moduleName : currentFolderData?.data?.name,
                        "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                        "usecaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null
                    }
                }

            } else {

                if (currentFolderData?.data?.type === FolderType.project) {
                    body = {
                        "projectName": currentFolderData?.data?.name,
                        "entity": currentAIEntityData?.entity,
                        // "entity": pathName === ModulePath.requirements ? AI_Entity.module : AI_Entity.useCase,
                        "moduleName": null,
                        "userStoryCustomId": null,
                        "usecaseCustomId": null
                    }
                }

                if (currentFolderData?.data?.type === FolderType.module) {
                    body = {
                        "projectName": currentFolderData?.parent?.data?.name,
                        "entity": currentAIEntityData?.entity,
                        // "entity": pathName === ModulePath.requirements ? AI_Entity.userStory : AI_Entity.useCase,
                        "moduleName": currentFolderData?.data?.name,
                        "userStoryCustomId": null,
                        "usecaseCustomId": null
                    }
                }
            }

            // if(currentFolderData?.data?.type === FolderType.module) {
            //     body = {
            //         "projectName": currentFolderData?.parent?.data?.name,
            //         "entity": AI_Entity.userStory,
            //         "moduleName": currentFolderData?.data?.name,
            //         "userStoryCustomId": null,
            //         "usecaseCustomId": null
            //     }
            // }

            if (!body) return


            const response = await getConversationHistoryList_API(body);
            console.log(response, "response handleGetConversationList")

            if (response) {
                errorHandler(response, setLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                let list = [...response.data];
                // setResponseList(list?.reverse());
                setResponseList(list);

                setTimeout(() => {
                    conversationResponseContainerRef.current && handleScrollToBottom();
                }, 800);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }

    const handleGenerateResponse = async () => {
        try {
            setIsContentGenerationLoading(true);

            let body;

            // when AI Window is opened on particular item scope
            if (isItemChatWindow & (currentUserStoryData || currentUseCaseData)) {
                if (currentFolderData?.data?.type === FolderType.project) {
                    body = {
                        "projectName": activeProject?.name,
                        "entity": currentAIEntityData?.entity,
                        "moduleName": pathName === ModulePath.requirements ? currentUserStoryData?.moduleName : null,
                        "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                        "usecaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null,

                        "prompt": userInput,
                    }
                }

                if (currentFolderData?.data?.type === FolderType.module) {
                    body = {
                        "projectName": activeProject?.name,
                        "entity": currentAIEntityData?.entity,
                        "moduleName": pathName === ModulePath.requirements ? currentUserStoryData?.moduleName : null,
                        "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                        "usecaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null,

                        "prompt": userInput,
                    }
                }

            } else {
                if (currentFolderData?.data?.type === FolderType.project) {
                    body = {
                        "projectName": currentFolderData?.data?.name,
                        "entity": currentAIEntityData?.entity,
                        // "entity": pathName === ModulePath.requirements ? AI_Entity.module : AI_Entity.useCase,
                        "moduleName": null,
                        "userStoryCustomId": null,
                        "usecaseCustomId": null,

                        "prompt": userInput,
                    }
                }

                if (currentFolderData?.data?.type === FolderType.module) {
                    body = {
                        "projectName": currentFolderData?.parent?.data?.name,
                        "entity": currentAIEntityData?.entity,
                        // "entity": pathName === ModulePath.requirements ? AI_Entity.userStory : AI_Entity.useCase,
                        "moduleName": currentFolderData?.data?.name,
                        "userStoryCustomId": null,
                        "usecaseCustomId": null,

                        "prompt": userInput,
                    }
                }
            }


            const response = await generateResponse_API(body);
            console.log(response, "response handleGenerateResponse")

            if (response) {
                errorHandler(response, setIsContentGenerationLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                setUserInput("");
                handleGetConversationList(setIsContentGenerationLoading);
            }

            setTimeout(() => {
                setIsContentGenerationLoading(false)
            }, 1000);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsContentGenerationLoading(false);
            }, 1000);
        }
    }

    // useEffect(() => {
    //     if (conversationResponseContainerRef.current && responseList?.length > 0) {
    //         conversationResponseContainerRef.current.scroll({
    //             // top: conversationResponseContainerRef.current.scrollHeight,
    //             top: 0,
    //             behavior: 'smooth',
    //         });
    //     }
    // }, [responseList]);


    // To get the conversation list
    useEffect(() => {
        handleGetConversationList(setIsLoading);
    }, [currentFolderData?.id, pathName, isItemChatWindow, currentAIEntityData]);

    // Close the item file context from AI chat window
    useEffect(() => {
        if (isItemChatWindow && (pathName === ModulePath.requirements ? currentUserStoryData?.moduleName !== currentFolderData?.data?.name : currentUseCaseData?.moduleName !== currentFolderData?.data?.name)) {
            handleCloseItemFromChatWindow();
        }
    }, [currentFolderData?.id, isItemChatWindow]);


    // Scroll to bottom
    useEffect(() => {
        if (conversationResponseContainerRef && responseList?.length > 0) {
            setTimeout(() => {
                handleScrollToBottom();
            }, 1100);
        }

    }, [conversationResponseContainerRef, responseList?.length]);

    // useEffect(() => {
    //     if (isItemChatWindow && (currentUserStoryData || currentUseCaseData)) {
    //         setIsItemChatWindow(false);
    //     }
    // }, [pathName]);

    return (
        <Box className={`w-full h-full flex flex-col pb-0 rounded-lg`} bgcolor={theme.palette.common.white}>

            {/* header */}
            <Box className="flex items-center gap-1 justify-between px-4 py-2 "   >

                <Box className="flex-1 flex flex-col items-start gap-1">
                    <Chip size="small" variant="" label={getAILabel()} color="secondary" sx={{ fontSize: font.size.fs_6, height: "18px", borderRadius: theme.shape.borderRadius }} />
                    {/* <Box
                        className={`flex items-center rounded-md px-1 ${isItemChatWindow ? " shadow-sm" : ""}`}
                        sx={{
                            border: `1px solid ${isItemChatWindow ? "transparent" : theme.palette.primary.main}`,
                            background: isItemChatWindow ? theme.palette.primary.main : "",
                            color: isItemChatWindow ? theme.palette.primary.contrastText : ""
                        }}
                    >
                        {handleGetContext()}

                        {isItemChatWindow && <IconButton sx={{ color: theme.palette.primary.contrastText, }} onClick={handleClose}>
                            <Close sx={{ fontSize: font.size.fs_5 }} />
                        </IconButton>}
                    </Box> */}
                </Box>


                <LightTooltip title={`Refresh`}>
                    <IconButton onClick={handleRefreshConversation}>
                        <Refresh />
                    </IconButton>
                </LightTooltip>
                <LightTooltip title={`Close`}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </LightTooltip>
            </Box>

            {/*  response container */}
            <Box className=" flex-1 max-h-[71.5%] flex flex-col  box-border relative" >

                {
                    responseList?.length === 0 && isContentGenerationLoading && !isLoading ? (
                        <Box className="grow flex flex-col-reverse gap-4 px-4" >
                            <Box className="w-fit flex items-center px-4 py-2 rounded-lg " bgcolor={theme.palette.common.white}>
                                <CircularProgress color="dark" size={16} />
                            </Box>
                        </Box>
                    )
                        :

                        isLoading ? (
                            <Box className="backdrop-blur-sm w-full h-full flex flex-col gap-4 px-4 py-4">
                                {
                                    Array.from({ length: 2 }).map((_, index) => <Skeleton variant="rounded" animation="pulse" width={"100%"} height={"400px"} />)
                                }
                            </Box>
                        )
                            :
                            responseList?.length === 0 ? (
                                <Box className="flex-1 flex flex-col items-center justify-center ">
                                    <Box className=" flex items-center justify-center gap-2 ">
                                        <AutoAwesomeTwoToneIcon sx={{ color: theme.palette.secondary.main }} />
                                        <Typography fontSize={font.size.fs_3}>AI Assistant</Typography>
                                    </Box>
                                    <Typography fontSize={font.size.fs_5} className="opacity-60">Your {getAILabel()?.replace("AI assistance for:", "").trim()} assistant !</Typography>
                                </Box>
                            ) :
                                (
                                    <Box ref={conversationResponseContainerRef} className="grow flex flex-col gap-4 px-2 py-4 rounded-lg overflow-y-auto ">
                                        {
                                            responseList?.map((item, index, array) => {
                                                console.log(index === responseList?.length - 1, item, "isLastConversation top")
                                                return (
                                                    <MessagesContainer key={index} item={item} isLastConversation={index === responseList?.length - 1} />
                                                )
                                            })
                                        }

                                        {/* when content in generating */}
                                        {isContentGenerationLoading && <Box className="w-fit flex items-center px-4 py-2 rounded-lg" bgcolor={theme.palette.common.white}>
                                            <CircularProgress color="dark" size={16} />
                                        </Box>}
                                    </Box>
                                )
                }

            </Box>

            {/* content generation loader */}
            {/* <Box className="px-0" sx={{ width: '100%' }}>
                <LinearProgress sx={{ display: isContentGenerationLoading ? "block" : "none" }} />
            </Box> */}


            {/* message container */}
            <Box className="px-2 py-2 flex flex-col gap-2 w-full " bgcolor={theme.palette.common.white}>

                {/* context */}
                <Box
                    className={`flex items-center rounded-md px-1 w-fit ${true ? " shadow-sm" : ""}`}
                    sx={{
                        border: `1px solid ${true ? "transparent" : theme.palette.primary.main}`,
                        background: true ? theme.palette.primary.main : "",
                        color: true ? theme.palette.primary.contrastText : ""
                    }}
                >
                    {handleGetContext()}

                    {isItemChatWindow && <IconButton sx={{ color: theme.palette.primary.contrastText, }} onClick={handleClose}>
                        <Close sx={{ fontSize: font.size.fs_5 }} />
                    </IconButton>}
                </Box>


                <Box className="flex items-center w-full " >

                    <TextField
                        size="small"
                        placeholder={`Prompt for generating ${getAILabel()?.replace("AI assistance for:", "").trim()}`}
                        disabled={isContentGenerationLoading}
                        autoFocus
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={4}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        // slotProps={{
                        //     input: {
                        //         sx: {
                        //             borderRadius: theme.shape.borderRadius
                        //         }
                        //     }
                        // }}
                        sx={{
                            bgcolor: theme.palette.primary.layout_col,
                            p: 0,
                            borderRadius: theme.shape.borderRadius // Added borderRadius here
                        }}
                    />

                    <Box className="h-full flex flex-col px-2">
                        <IconButton size="small" disabled={!userInput || isContentGenerationLoading} onClick={handleGenerate}  >
                            <SendOutlined />
                        </IconButton>
                        <IconButton size="small" disabled={true}  >
                            <AttachFileOutlined />
                        </IconButton>
                    </Box>
                </Box>
            </Box>


        </Box>
    )
}



function MessagesContainer({ item, isLastConversation }) {
    const theme = useTheme();

    return (
        <Box className={`flex flex-col w-full gap-2  `}>

            <Box className={`flex flex-col w-full gap-0 rounded-lg `} >

                <Box className={`flex  w-full gap-2 `}>
                    {item?.sentBy === AI_Response_SentBy.user
                        ? <UserMessageContainer data={item} isLastConversation={isLastConversation} />
                        : <ResponseMessageContainer data={item} isLastConversation={isLastConversation} />
                    }
                </Box>

            </Box>

        </Box>
    )
}

// It is generated response container
function ResponseMessageContainer({ data, isLastConversation }) {
    const { setGlobalLoader, setFolderStructureList, handleSnackbar, currentFolderData, setCurrentFolderData, isUpdateFolderStructure, setIsUpdateFolderStructure, setUserStoryList, activeProject, setUseCaseList, isItemChatWindow } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    const [selectedResponseList, setSelectedResponseList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [responseSaveAnchorEl, setResponseSaveAnchorEl] = useState(null);

    const handleSelectAll = (e) => {
        setSelectedResponseList(
            e.target.checked ?
                data?.chatMessage?.some((item) => item?.id === null) ? [] : data?.chatMessage
                : []);
    }

    const handleToggleSelection = (e, data) => {
        if (e.target.checked && data?.id) {
            setSelectedResponseList([...selectedResponseList, data]);
        } else {
            setSelectedResponseList(selectedResponseList?.filter((item) => item?.id !== data?.id));
        }
    }

    const handleOpenSavePopup = (e) => {
        setResponseSaveAnchorEl(e.currentTarget);
    }

    const handleCloseSavePopup = () => {
        setResponseSaveAnchorEl(null);
    }

    const handleSaveGeneratedResponse = async () => {
        try {
            setIsLoading(true);

            let body = {
                "responseId": data?.responseId,
                "conversationId": data?.conversationId,
                "ids": [...selectedResponseList?.map((item) => item?.id)]
            }

            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                // When AI Window is opened on particular item scope
                if (isItemChatWindow) {
                    if (pathName === ModulePath.requirements) {
                        // no need to fetch usecase list here

                        navigate(ModulePath.useCase);
                    }
                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                } else {
                    // When AI Window is opened on project/module scope

                    if (pathName === ModulePath.requirements) {
                        if (currentFolderData?.data?.type === FolderType.project) {
                            // setIsUpdateFolderStructure(true);
                            setTimeout(() => {
                                setSelectedResponseList([]);
                            }, 1000);
                        }
                        if (currentFolderData?.data?.type === FolderType.module) {
                            handleGetUserStoryList();
                        }
                    }

                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                }

                setIsUpdateFolderStructure(true);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {
            // setGlobalLoader(true);

            let body = {
                "moduleName": currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
                setSelectedResponseList([]);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {


            let body = {
                "moduleName": currentFolderData?.data?.type === FolderType.project ? null : currentFolderData?.data?.name,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {

        }
    }

    useEffect(() => {
        if (data?.chatMessage && isLastConversation) {
            setSelectedResponseList([...data?.chatMessage]);
        }
        if (data?.chatMessage && !isLastConversation) {
            setSelectedResponseList([]);
        }
    }, [data?.chatMessage, isLastConversation]);

    console.log(isLastConversation, "isLastConversation", data?.chatMessage);

    console.log(selectedResponseList, "selectedResponseList");
    // console.log(JSON.parse(data?.chatMessageStr), "data in responsemessagecontainer")

    return (
        <Box className={`flex flex-col w-full `} >

            <Box className={`flex flex-col px-2 py-2 w-fit max-w-full gap-2 rounded-lg`} bgcolor={grey[100]}>

                {data?.chatMessage && Array.isArray(data?.chatMessage) ?
                    <Box className={`flex flex-col w-full gap-2 px-2 py-2 rounded-lg `} >

                        <Box className={`flex  items-center justify-start w-full gap-2 py-0`}>
                            <Checkbox
                                size="small"
                                checked={data?.chatMessage && selectedResponseList?.length === data?.chatMessage?.length}
                                indeterminate={selectedResponseList?.length > 0 && data?.chatMessage && (selectedResponseList?.length < data?.chatMessage?.length)}
                                onChange={handleSelectAll}
                            />

                            <Avatar sx={{ width: "1.4rem", height: "1.4rem" }}>
                                <Typography fontSize={font.size.fs_5}>{selectedResponseList?.length}</Typography>
                            </Avatar>

                            <LightTooltip title="Regenerate">
                                <IconButton disabled color="primary" sx={{ ml: "auto" }}>
                                    <ReplayOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title="Save">
                                <IconButton
                                    disabled={selectedResponseList?.length === 0}
                                    size="small"
                                    variant="outlined"
                                    startIcon={<Save />}

                                    onClick={() => handleSaveGeneratedResponse()}
                                    // onClick={handleOpenSavePopup} // save location dialog
                                    sx={{ color: theme.palette.primary.main }}
                                >
                                    {isLoading ? <CircularProgress color="secondary" size={16} /> : <Save />}
                                </IconButton>
                            </LightTooltip>

                        </Box>


                        {/* list of the responses */}
                        <Box className={`flex flex-col w-full gap-2  `}>
                            {
                                data?.chatMessage && data?.chatMessage?.length > 0 && data?.chatMessage?.map((responseItem, index) => (
                                    <Box key={index} className={`flex  w-full gap-0 relative`} >
                                        <Box className="flex h-fit">
                                            <Checkbox size="small" checked={selectedResponseList?.includes(responseItem)} onChange={(e) => handleToggleSelection(e, responseItem)} />
                                        </Box>

                                        <Box className="flex-1 flex flex-col  border  gap-1 rounded-lg shadow-md " sx={{ background: theme.palette.common.white }}>

                                            <ResponseComponent response={responseItem} entity={data?.entity} />

                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    :
                    <Box className="flex items-center ">
                        <Typography fontSize={font.size.fs_4} fontStyle="italic" className="opacity-60">Unable to generate response</Typography>
                    </Box>
                }

                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_6} className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>


            </Box>


            {/* save popup */}
            {responseSaveAnchorEl && <ResponseSaveComponent responseSaveAnchorEl={responseSaveAnchorEl} handleCloseSavePopup={handleCloseSavePopup} />}

        </Box>
    )
}


function UserMessageContainer({ data }) {
    const theme = useTheme();

    return (
        <Box className={`flex flex-col items-end w-full gap-2 `} >

            <Box className={`flex flex-col items-end gap-1 px-4 py-2 rounded-lg backdrop-blur-lg shadow-md`} sx={{ background: theme.palette.secondary.main }}>
                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_5} color={theme.palette.secondary.contrastText}>{data?.chatMessageStr}</Typography>
                </Box>

                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_6}  color={theme.palette.secondary.contrastText} className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
            </Box>

            {/* <Avatar className="shadow-md" sx={{ width: "2rem", height: "2rem", background: theme.palette.primary.main }}>
                {data?.sentBy === AI_Response_SentBy.user ?
                    <AccountCircleOutlined sx={{ color: theme.palette.common.white }} />
                    : <AutoAwesomeOutlinedIcon sx={{ color: theme.palette.common.white }} />
                }
            </Avatar> */}
        </Box>
    )
}


// use to show generated module UI
function ResponseComponent({ response, entity }) {
    const {
        currentFolderData,
        isItemChatWindow, setIsItemChatWindow,
        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
    } = useApplicationContext();

    if (entity === AI_Entity.useCase) {
        return <ResponseUseCaseComponent response={response} />
    }

    if (entity === AI_Entity.userStory) {
        return <ResponseUserStoryComponent response={response} />
    }

    if (entity === AI_Entity.module) {
        return <ResponseModuleComponent response={response} />
    }

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Description: {response?.description}</Typography>
                {currentFolderData?.data?.type === FolderType.module && <Box className="flex flex-col gap-1">
                    <Typography fontSize={font.size.fs_5} className="">Acceptance Criteria:</Typography>
                    {
                        response?.acceptanceCriteria && response?.acceptanceCriteria?.map((critera, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full bg-gray-600"></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1">{critera}</Typography>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseModuleComponent({ response }) {
    const { currentFolderData } = useApplicationContext();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Description: {response?.description}</Typography>
            </Box>
        </Box>
    )
}

function ResponseUserStoryComponent({ response }) {
    const { currentFolderData } = useApplicationContext();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Description: {response?.description}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Acceptance Criteria:</Typography>
                {response?.acceptanceCriteria?.length > 0 && <Box className="flex flex-col gap-1">
                    {
                        response?.acceptanceCriteria?.map((step, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full bg-gray-600"></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1">{step}</Typography>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseUseCaseComponent({ response }) {
    const { currentFolderData } = useApplicationContext();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Steps:</Typography>
                {response?.useCaseSteps?.length > 0 && <Box className="flex flex-col gap-1">
                    {
                        response?.useCaseSteps?.map((step, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full bg-gray-600"></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1">{step}</Typography>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseSaveComponent({ responseSaveAnchorEl, handleCloseSavePopup }) {
    const { folderStructureList, setFolderStructureList, } = useApplicationContext();

    const [loading, setLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);

    return (
        <Popover
            open={Boolean(responseSaveAnchorEl)}
            anchorEl={responseSaveAnchorEl}
            onClose={handleCloseSavePopup}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        // sx={{
        //     left: '-28dvw !important', // Add this line
        // }}


        >
            <Box className="flex flex-col gap-0  h-[28rem] w-[28rem]">
                {/* header */}
                <Box className="flex flex-col gap-2 px-4 py-2 border-b">
                    <Typography fontSize={font.size.fs_4}>Save Location</Typography>
                </Box>

                {/* body */}
                <Box className="flex-1 flex flex-col gap-2 px-4 py-2" onContextMenu={(e) => e.preventDefault()}>
                    {loading ?
                        <Box className="flex flex-col gap-2 justify-center items-center h-full w-full">
                            <CircularProgress size={28} />
                            <Typography fontSize={font.size.fs_4}>Loading...</Typography>
                        </Box>
                        : <AutoSizer>
                            {({ height, width }) => {
                                console.log(height, width, "heightwidth")
                                return (

                                    <Tree
                                        initialData={folderStructureList}
                                        openByDefault={true}
                                        width={width}
                                        height={height}
                                        indent={24}
                                        rowHeight={38}
                                    // overscanCount={1}
                                    // paddingTop={30}
                                    // paddingBottom={10}
                                    // padding={25 /* sets both */}
                                    >
                                        {
                                            ({ node, style, dragHandle }) => {
                                                return (
                                                    <Node node={node} style={style} dragHandle={dragHandle} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                                                )
                                            }
                                        }
                                    </Tree>

                                )
                            }}
                        </AutoSizer>
                    }
                </Box>


                {/* footer */}
                <Box className="flex items-center justify-end gap-2 px-4 py-2 border-t">
                    <Button color="primary" variant="outlined" disabled={loading || !selectedFolder || selectedFolder?.data?.type !== FolderType.project} sx={{ mr: "auto" }}>Add Module</Button>
                    <Button color="inherit" variant="text" onClick={handleCloseSavePopup}>Cancel</Button>
                    <Button color="success" variant="contained" disabled={loading || !selectedFolder}>Save</Button>
                </Box>


            </Box>
        </Popover>
    )
}

function Node({ node, style, dragHandle, selectedFolder, setSelectedFolder }) {
    const {
        currentFolderData, setCurrentFolderData,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;



    const handleFolderClick = (folder) => {
        console.log(folder, "node1data");
        // folder.toggle();
        setSelectedFolder(folder);
    }

    const handleIconClick = (folder) => {
        folder.toggle();
        setSelectedFolder(folder);
    }

    const handleRightClick = (event, folder) => {
        event.preventDefault();
        console.log(folder, "folder rightclick");
        setSelectedFolder(folder);
    }

    useEffect(() => {
        if (!selectedFolder && currentFolderData) {
            setSelectedFolder(currentFolderData)
        }
    }, []);

    console.log(selectedFolder, "selectedFolder node1data")



    return (
        <Box
            className={`  flex items-center gap-1 py-1 px-2 cursor-pointer overflow-hidden`}
            borderRadius={theme.shape.borderRadius}
            // style={style}
            // ref={dragHandle}
            sx={{
                // color: (selectedFolder?.id === node?.id) && theme.palette.secondary.contrastText,
                // background: (selectedFolder?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                background: (selectedFolder?.id === node?.id) && theme.palette.primary.main,
                color: (selectedFolder?.id === node?.id) && theme.palette.primary.contrastText,
                pl: (node.level * 4)
            }}

        >
            <Box className="" onClick={() => handleIconClick(node)}>
                {!node.isLeaf &&
                    (node.isOpen ?
                        <KeyboardArrowDown /> : <KeyboardArrowRight />
                    )
                }
            </Box>

            <Box className=" flex-1 flex items-center gap-1  overflow-hidden" onClick={() => handleFolderClick(node)} sx={{ pl: 0 }} onContextMenu={(e) => handleRightClick(e, node)}>
                {node.data.type === FolderType.project && <FolderSpecialTwoTone sx={{}} />}
                {node.data.type === FolderType.module && <FolderTwoTone sx={{}} />}

                {/* <Box className="flex-1 flex items-center gap-1  box-border overflow-hidden border-2 border-gray-400 "> */}


                <LightTooltip
                    title={<Box className="flex flex-col gap-1">
                        <Typography fontSize={font.size.fs_5} className="flex-1 ">Title: {node.data.name}</Typography>
                        <Typography fontSize={font.size.fs_5} className="">Description: {node?.data?.description}</Typography>
                        {pathName === ModulePath.requirements && <Typography fontSize={font.size.fs_5} className="">User Story: {node?.data?.userStoryCount}</Typography>}
                        {pathName === ModulePath.useCase && <Typography fontSize={font.size.fs_5} className="">Use Case: {node?.data?.useCaseCount}</Typography>}
                    </Box>}
                    placement="right"
                >
                    <Box className="flex-1 flex items-center  gap-1  box-border  = overflow-hidden">
                        <Typography fontSize={font.size.fs_5} className=" flex-1 overflow-hidden text-ellipsis whitespace-nowrap box-border ">{node.data.name}</Typography>
                        <Typography fontSize={font.size.fs_5} className="w-fit">{`[${pathName === ModulePath.requirements ? node?.data?.userStoryCount : node?.data?.useCaseCount}]`}</Typography>
                    </Box>
                </LightTooltip>
                {/* </Box> */}
            </Box>


        </Box>
    );
}