import React from 'react'
import { useApplicationContext } from '../../context/ApplicationContext';
import { Box, IconButton } from '@mui/material';
import { AutoAwesome, AutoAwesomeOutlined, } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { LightTooltip } from '../styled/CustomComponent';

function ChatWindowToggleButton(props) {
    const { toggleChatWindow, setToggleChatWindow, } = useApplicationContext();
    const theme = useTheme();

    const handleToggleSidebar = () => {
        setToggleChatWindow(!toggleChatWindow);
    }
 
    return (
        <Box
            className={`  h-full w-10 flex items-center justify-center cursor-pointer overflow-hidden`}
            borderRadius={`50px 0 0 50px`}
            bgcolor={!toggleChatWindow && theme.palette.secondary.main}
            {...props}
        >
            <LightTooltip title={`${toggleChatWindow ? "Hide AI Assistant" : "Show AI Assistant"}`}>
                <IconButton sx={{ background: !toggleChatWindow && theme.palette.secondary.main, }} onClick={handleToggleSidebar}>
                    {toggleChatWindow ?
                        <AutoAwesome sx={{ color: theme.palette.secondary.main, }} />
                        :
                        <AutoAwesomeOutlined sx={{ color: theme.palette.secondary.contrastText, }} />
                    }
                </IconButton>
            </LightTooltip>
        </Box>
    )
}

export default ChatWindowToggleButton
